export const PUBLIC_HOME = '/';
export const PUBLIC_LESSONS = '/lessons';
export const PUBLIC_SUBJECT_BASE = '/subject';
export const PUBLIC_SUBJECT = `${PUBLIC_SUBJECT_BASE}/:subject`;
export const PUBLIC_SCOPE_AND_SEQUENCE = '/scopeandsequence';
export const PUBLIC_SCOPE_AND_SEQUENCE_CREATE = `${PUBLIC_SCOPE_AND_SEQUENCE}/create`;
export const PUBLIC_SCOPE_AND_SEQUENCE_CLASS = `${PUBLIC_SCOPE_AND_SEQUENCE}/class`;
export const PUBLIC_SCOPE_AND_SEQUENCE_SUCCESS = `${PUBLIC_SCOPE_AND_SEQUENCE}/success`;
export const PUBLIC_ABOUT = '/about';
export const PUBLIC_PRICING = '/pricing';
export const FAQS_ID = 'FAQs';
export const PUBLIC_PRICING_FAQS = `${PUBLIC_PRICING}#${FAQS_ID}`;
export const PUBLIC_TERMS = '/terms';
export const PUBLIC_PRIVACY = '/privacy';
export const PUBLIC_COOKIES = '/cookies';
export const PUBLIC_CONTACT_US = '/contact';
export const PUBLIC_CONTACT_US_OLD = '/contact-us';
export const PUBLIC_BLOG = '/blog';
export const PUBLIC_FORGOT_PASSWORD = '/forgot-password';
export const PUBLIC_RESET_PASSWORD = '/reset-password';
export const PUBLIC_SIGNIN = '/signin';
export const PUBLIC_SIGNIN_SSO = '/signin/sso';
export const PUBLIC_STUDENT_SIGNIN = '/studentsignin';
export const PUBLIC_STUDENTS = '/students';
export const PUBLIC_STUDENTS_BASE = '/s';
export const PUBLIC_LESSON_BASE = '/lesson';
export const PUBLIC_UNIT_BASE = '/u';
export const PUBLIC_TOPIC_BASE = '/t';
export const PUBLIC_ASSESSMENT_BASE = '/assessment';
export const PUBLIC_VIDEO_BASE = '/video';
export const PUBLIC_AUDIO_BASE = '/audio';
export const PUBLIC_INTERACTIVE_BASE = '/interactive';
export const PUBLIC_GUIDED_RESEARCH_BASE = '/guided-research';
export const PUBLIC_TOPIC = `${PUBLIC_TOPIC_BASE}/:topicId`;
export const PUBLIC_UNIT = `${PUBLIC_UNIT_BASE}/:slug`;
export const PUBLIC_UNIT_OLD = '/unit/:slug';
export const PUBLIC_LESSON = `${PUBLIC_LESSON_BASE}/:slug`;
export const PUBLIC_ASSESSMENT = `${PUBLIC_ASSESSMENT_BASE}/:slug`;
export const PUBLIC_GUIDED_RESEARCH = `${PUBLIC_GUIDED_RESEARCH_BASE}/:slug`;
export const PUBLIC_VIDEO = `${PUBLIC_VIDEO_BASE}/:slug`;
export const PUBLIC_AUDIO = `${PUBLIC_AUDIO_BASE}/:slug`;
export const PUBLIC_INTERACTIVE = `${PUBLIC_INTERACTIVE_BASE}/:slug`;
export const PUBLIC_JOIN = '/join';
export const PUBLIC_SEARCH = '/search';
export const PUBLIC_STIMULUS = '/stimulus';
export const PUBLIC_SESSION_TAKEN = '/session-taken';
export const PUBLIC_CONFIRM_EMAIL_UPDATE = '/confirm-email-update';
export const PUBLIC_NOT_FOUND = '/not-found';
export const PUBLIC_NOT_AUTHORISED = '/not-authorised';
export const PUBLIC_WORLD_TEACHERS_DAY_VIDEO = '/worldteachersday2021';
export const PUBLIC_GETTING_STARTED = '/gettingstarted';
export const PUBLIC_TEACHER_BENEFITS = '/features/teachers';
export const PUBLIC_SCHOOL_BENEFITS = '/features/schools';
export const PUBLIC_ACTIVITIES = 'activities/public';
export const PUBLIC_LESSON_ACTIVITIES = `${PUBLIC_LESSON}/${PUBLIC_ACTIVITIES}`;
export const PUBLIC_ASSESSMENT_ACTIVITIES = `${PUBLIC_ASSESSMENT}/${PUBLIC_ACTIVITIES}`;

export const BLOG_DIFFERENTIATION_MORE_INFO =
  '/blog/a-teachers-guide-to-differentiating-activities/';
export const BLOG_VISIBLE_THINKING = '/blog/visible-thinking';
export const BLOG_MULTI_CLASS = '/blog/multi-class';
export const BLOG_SCIENCE_SCOPE_AND_SEQUENCE_US =
  '/blog/science-scope-and-sequence';
export const BLOG_DIFFERENTIATION_MORE_INFO_US =
  '/blog/a-teachers-guide-to-differentiating-activities-3/';
export const BLOG_VISIBLE_THINKING_US =
  '/blog/a-teachers-guide-to-visible-thinking-activities';
export const BLOG_5E_MODEL_US =
  '/blog/a-teachers-guide-to-the-5e-pedagogical-model-2';
export const BLOG_K5_SCIENCE_ANCHOR_MAPS_US = '/blog/k-5-science-anchor-maps';
