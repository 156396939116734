//  /!\ DEVELOPER WARNING /!\
//  DON'T PUT ANYTHING EVEN SLIGHTLY SECRET HERE...
// THIS FILE IS INCLUDED IN WEBCLIENT BUILDS IN ITS ENTIRETY

//  /!\ ECMASCRIPT WARNING /!\
// this file won't be compiled down to an older ECMAScript version due to being outside of the src folder
// so be careful using newer syntax e.g. computed property keys { [variable]: 'description' } because this will break IE11

// Environment
export const AUSTRALIA_SYDNEY_TIMEZONE = 'Australia/Sydney';
export const SHORTEN_DOMAIN = 'http://inq.co';
export const AU_COUNTRY_CODE = 'AU';
export const CANADA_COUNTRY_CODE = 'CA';
export const CHINA_COUNTRY_CODE = 'CN';
export const US_COUNTRY_CODE = 'US';
export const COUNTRIES = [
  {
    name: 'China',
    code: CHINA_COUNTRY_CODE,
    states: [
      {
        name: 'Shanghai',
        code: 'Shanghai',
      },
    ],
  },
  {
    name: 'Australia',
    code: AU_COUNTRY_CODE,
    states: [
      {
        name: 'New South Wales',
        code: 'NSW',
      },
      {
        name: 'Queensland',
        code: 'QLD',
      },
      {
        name: 'South Australia',
        code: 'SA',
      },
      {
        name: 'Tasmania',
        code: 'TAS',
      },
      {
        name: 'Victoria',
        code: 'VIC',
      },
      {
        name: 'Western Australia',
        code: 'WA',
      },
      {
        name: 'Australian Capital Territory',
        code: 'ACT',
      },
      {
        name: 'Northern Territory',
        code: 'NT',
      },
    ],
  },
  {
    name: 'Canada',
    code: CANADA_COUNTRY_CODE,
    states: [
      {
        name: 'Alberta',
        code: 'AB',
      },
      {
        name: 'British Columbia',
        code: 'BC',
      },
      {
        name: 'Manitoba',
        code: 'MB',
      },
      {
        name: 'New Brunswick',
        code: 'NB',
      },
      {
        name: 'Newfoundland and Labrador',
        code: 'NL',
      },
      {
        name: 'Northwest Territories',
        code: 'NT',
      },
      {
        name: 'Nova Scotia',
        code: 'NS',
      },
      {
        name: 'Nunavut',
        code: 'NU',
      },
      {
        name: 'Ontario',
        code: 'ON',
      },
      {
        name: 'Prince Edward Island',
        code: 'PE',
      },
      {
        name: 'Québec',
        code: 'QC',
      },
      {
        name: 'Saskatchewan',
        code: 'SK',
      },
      {
        name: 'Yukon Territory',
        code: 'YT',
      },
    ],
  },
  {
    name: 'United States',
    code: US_COUNTRY_CODE,
    states: [
      {
        name: 'Alabama',
        code: 'AL',
      },
      {
        name: 'Alaska',
        code: 'AK',
      },
      {
        name: 'Arizona',
        code: 'AZ',
      },
      {
        name: 'Arkansas',
        code: 'AR',
      },
      {
        name: 'California',
        code: 'CA',
      },
      {
        name: 'Colorado',
        code: 'CO',
      },
      {
        name: 'Connecticut',
        code: 'CT',
      },
      {
        name: 'Delaware',
        code: 'DE',
      },
      {
        name: 'District of Columbia',
        code: 'DC',
      },
      {
        name: 'Florida',
        code: 'FL',
      },
      {
        name: 'Georgia',
        code: 'GA',
      },
      {
        name: 'Hawaii',
        code: 'HI',
      },
      {
        name: 'Idaho',
        code: 'ID',
      },
      {
        name: 'Illinois',
        code: 'IL',
      },
      {
        name: 'Indiana',
        code: 'IN',
      },
      {
        name: 'Iowa',
        code: 'IA',
      },
      {
        name: 'Kansas',
        code: 'KS',
      },
      {
        name: 'Kentucky',
        code: 'KY',
      },
      {
        name: 'Louisiana',
        code: 'LA',
      },
      {
        name: 'Maine',
        code: 'ME',
      },
      {
        name: 'Maryland',
        code: 'MD',
      },
      {
        name: 'Massachusetts',
        code: 'MA',
      },
      {
        name: 'Michigan',
        code: 'MI',
      },
      {
        name: 'Minnesota',
        code: 'MN',
      },
      {
        name: 'Mississippi',
        code: 'MS',
      },
      {
        name: 'Missouri',
        code: 'MO',
      },
      {
        name: 'Montana',
        code: 'MT',
      },
      {
        name: 'Nebraska',
        code: 'NE',
      },
      {
        name: 'Nevada',
        code: 'NV',
      },
      {
        name: 'New Hampshire',
        code: 'NH',
      },
      {
        name: 'New Jersey',
        code: 'NJ',
      },
      {
        name: 'New Mexico',
        code: 'NM',
      },
      {
        name: 'New York',
        code: 'NY',
      },
      {
        name: 'North Carolina',
        code: 'NC',
      },
      {
        name: 'North Dakota',
        code: 'ND',
      },
      {
        name: 'Ohio',
        code: 'OH',
      },
      {
        name: 'Oklahoma',
        code: 'OK',
      },
      {
        name: 'Oregon',
        code: 'OR',
      },
      {
        name: 'Pennsylvania',
        code: 'PA',
      },
      {
        name: 'Rhode Island',
        code: 'RI',
      },
      {
        name: 'South Carolina',
        code: 'SC',
      },
      {
        name: 'South Dakota',
        code: 'SD',
      },
      {
        name: 'Tennessee',
        code: 'TN',
      },
      {
        name: 'Texas',
        code: 'TX',
      },
      {
        name: 'Utah',
        code: 'UT',
      },
      {
        name: 'Vermont',
        code: 'VT',
      },
      {
        name: 'Virginia',
        code: 'VA',
      },
      {
        name: 'Washington',
        code: 'WA',
      },
      {
        name: 'West Virginia',
        code: 'WV',
      },
      {
        name: 'Wisconsin',
        code: 'WI',
      },
      {
        name: 'Wyoming',
        code: 'WY',
      },
    ],
  },
];

export const MOST_RECENTLY_VISITED_LESSONS_COUNT = 4;

//Internationalisation labels
export const UNIT_LABEL = 'unitLabel';
export const UNIT_PROGRAM_LABEL = 'unitProgramLabel';
export const FOCUS_LABEL = 'focusLabel';
export const KEY_INQUIRY_QUESTION_LABEL = 'keyInquiryQuestionLabel';
export const INTERNATIONALISED_YEAR_LABEL = 'year';
export const YEAR_0_LABEL = 'year_0';
export const YEAR_P_LABEL = 'year_p';
export const ALL_YEARS_LABEL = 'all_years';
export const OUTCOMES_LABEL = 'outcomeLabel';
export const ABOUT_LABEL = 'aboutLabel';
export const EFT = 'eft';
export const TIP_SUMMARY_LABEL = 'tipSummaryLabel';
export const TIP_CUSTOMISE_LABEL = 'tipCustomiseLabel';
export const PERFORMANCE_EXPECTATIONS_LABEL =
  'performanceExpectationsLabel_other';
export const ORGANISER_LABEL = 'organiser_label';
export const COLOUR_LABEL = 'colour_label';

/**
 * @typedef { typeof UNIT_LABEL
 * | typeof UNIT_PROGRAM_LABEL
 * | typeof FOCUS_LABEL
 * | typeof KEY_INQUIRY_QUESTION_LABEL
 * | typeof INTERNATIONALISED_YEAR_LABEL
 * | typeof YEAR_0_LABEL
 * | typeof YEAR_P_LABEL
 * | typeof ALL_YEARS_LABEL
 * | typeof OUTCOMES_LABEL
 * | typeof ABOUT_LABEL
 * | typeof EFT
 * | typeof TIP_SUMMARY_LABEL
 * | typeof TIP_CUSTOMISE_LABEL
 * | typeof PERFORMANCE_EXPECTATIONS_LABEL
 * | typeof ORGANISER_LABEL
 * | typeof COLOUR_LABEL
 * } InternationalisationLabels
 */
export const INTERNATIONALISATION_LABELS = [
  UNIT_LABEL,
  UNIT_PROGRAM_LABEL,
  FOCUS_LABEL,
  KEY_INQUIRY_QUESTION_LABEL,
  INTERNATIONALISED_YEAR_LABEL,
  YEAR_0_LABEL,
  YEAR_P_LABEL,
  ALL_YEARS_LABEL,
  OUTCOMES_LABEL,
  ABOUT_LABEL,
  EFT,
  TIP_SUMMARY_LABEL,
  TIP_CUSTOMISE_LABEL,
  PERFORMANCE_EXPECTATIONS_LABEL,
  ORGANISER_LABEL,
  COLOUR_LABEL,
];
export const PINCODE_CHARS = [1, 2, 3, 4, 5, 6, 7, 8, 9];

// Headings
export const CREATE_LESSON_SUBJECT_HEADING = 'Which subject?';

// Annotations
export const UNIT_ANNOTATION = 'unit';
export const LESSON_ANNOTATION = 'lesson';
export const ASSESSMENT_ANNOTATION = 'assessment';
export const ANNOTATION_CONTENT_TYPE = [
  UNIT_ANNOTATION,
  LESSON_ANNOTATION,
  ASSESSMENT_ANNOTATION,
];

/**
 * @typedef { typeof UNIT_ANNOTATION
 * | typeof LESSON_ANNOTATION
 * | typeof ASSESSMENT_ANNOTATION
 * } AnnotationContentType
 */

// Notifications
export const NOTIFICATION_READ = 'read';
export const NOTIFICATION_NEW = 'new';
export const NOTIFICATION_INTERACTED = 'interacted';
export const NOTIFICATION_STATUS = [
  NOTIFICATION_READ,
  NOTIFICATION_NEW,
  NOTIFICATION_INTERACTED,
];

/**
 * @typedef { typeof NOTIFICATION_READ
 * | typeof NOTIFICATION_NEW
 * | typeof NOTIFICATION_INTERACTED
 * } NotificationStatus
 */

// Feature Units
export const FEATURE_UNIT_IDS_AU = [
  25,
  280,
  354,
  322,
  325,
  147,
  113,
  366,
  17,
  31,
  49,
  365,
];
export const PUBLIC_ACCESS_USERID = 0;
export const FEATURE_UNIT_IDS_US = [17, 41, 63, 9, 49, 27, 20];

export const CS_IN_SCHOOLS_AUTHOR_ID = 185784;

// Bell Notifications Group Categories
export const GROUP_NOTIFICATION_ACCOUNT = 'group-notification-account';

// Notification Messages
export const CREATE_SCHOOL_ACCOUNT_MESSAGE_GRACE_PERIOD = 0; // This controls when create school account message can be shown, number of days since account creation
export const BILLING_NOTIFICATION_AREA = 'billing-notification';
export const SIGN_IN_NOTIFICATION_AREA = 'sign-in-notification';
export const CREATE_SCHOOL_NOTIFICATION = 'create-school-notification';
export const WHOLE_SCHOOL_DISCOUNT_NOTIFICATION = 'whole-school-notification';
export const BILLING_NOTIFICATION_PREFIX = 'billing';

export const BILLING_CREATE_SCHOOL_DISCOUNT_MESSAGE = `${BILLING_NOTIFICATION_PREFIX}-create-school-discount-message`;
export const BILLING_CREATE_SCHOOL_FREE_PERIOD_MESSAGE = `${BILLING_NOTIFICATION_PREFIX}-create-school-free-period-message`;
export const BILLING_WHOLE_SCHOOL_DISCOUNT_MESSAGE = `${BILLING_NOTIFICATION_PREFIX}-whole-school-discount-message`;
export const BILLING_WHOLE_SCHOOL_FREE_PERIOD_MESSAGE = `${BILLING_NOTIFICATION_PREFIX}-whole-school-free-period-message`;
export const BILLING_ACCOUNT_ON_HOLD_MESSAGE = `${BILLING_NOTIFICATION_PREFIX}-account-on-hold-message`;

//TODO: These notifications will be removed
export const SIGN_IN_CREATE_SCHOOL_DISCOUNT_MESSAGE =
  'sign-in-create-school-discount-message';
export const SIGN_IN_CREATE_SCHOOL_FREE_PERIOD_MESSAGE =
  'sign-in-create-school-free-period-message';
export const SIGN_IN_WHOLE_SCHOOL_DISCOUNT_MESSAGE =
  'sign-in-whole-school-discount-message';
export const SIGN_IN_WHOLE_SCHOOL_FREE_PERIOD_MESSAGE =
  'sign-in-whole-school-free-period-message';
/**
 * @typedef { typeof BILLING_CREATE_SCHOOL_DISCOUNT_MESSAGE
 * | typeof BILLING_CREATE_SCHOOL_FREE_PERIOD_MESSAGE
 * | typeof BILLING_WHOLE_SCHOOL_DISCOUNT_MESSAGE
 * | typeof BILLING_WHOLE_SCHOOL_FREE_PERIOD_MESSAGE
 * | typeof BILLING_ACCOUNT_ON_HOLD_MESSAGE
 * | typeof SIGN_IN_CREATE_SCHOOL_DISCOUNT_MESSAGE
 * | typeof SIGN_IN_CREATE_SCHOOL_FREE_PERIOD_MESSAGE
 * | typeof SIGN_IN_WHOLE_SCHOOL_DISCOUNT_MESSAGE
 * | typeof SIGN_IN_WHOLE_SCHOOL_FREE_PERIOD_MESSAGE
 * } NotificationId
 */
export const NOTIFICATION_IDS = [
  BILLING_CREATE_SCHOOL_DISCOUNT_MESSAGE,
  BILLING_CREATE_SCHOOL_FREE_PERIOD_MESSAGE,
  BILLING_WHOLE_SCHOOL_DISCOUNT_MESSAGE,
  BILLING_WHOLE_SCHOOL_FREE_PERIOD_MESSAGE,
  BILLING_ACCOUNT_ON_HOLD_MESSAGE,
  SIGN_IN_CREATE_SCHOOL_DISCOUNT_MESSAGE,
  SIGN_IN_CREATE_SCHOOL_FREE_PERIOD_MESSAGE,
  SIGN_IN_WHOLE_SCHOOL_DISCOUNT_MESSAGE,
  SIGN_IN_WHOLE_SCHOOL_FREE_PERIOD_MESSAGE,
];

export const SIGN_IN_SCHOOL_ACCOUNT_FEATURE_MESSAGE =
  'sign-in-school-account-feature-message';
export const SIGN_IN_PLANNER_FEATURE_MESSAGE =
  'sign-in-planner-feature-message';
export const SIGN_IN_PLANNER_ALL_TERMS_MESSAGE =
  'sign-in-planner-all-terms-message';
export const SIGN_IN_UNIT_PROGRAM_FEATURE_MESSAGE =
  'sign-in-unit-program-feature-message';
export const SIGN_IN_CLASS_TO_DO_FEATURE_MESSAGE =
  'sign-in-class-to-do-feature-message';
export const SIGN_IN_DIFFERENTIATION_FEATURE_MESSAGE =
  'sign-in-differentiation-feature-message';
export const SIGN_IN_FEATURE_OVERLAY_NOT_READY_MESSAGE =
  'sign-in-feature-overlay-not-ready-message';
export const SIGN_IN_MULTI_CLASS_FEATURE_MESSAGE =
  'sign-in-multi-class-feature-message';
export const UPDATE_CLASS_PREFERENCES = 'update-class-preferences';
export const NOTIFICATION_TYPE_SCHOOL_INVITATION_INVITEE =
  'school-invitation-invitee';

export const NOTIFICATION_TYPE_SCHOOL_INVITATION_ACCEPTED_INVITEE =
  'school-invitation-accepted-invitee';

export const NOTIFICATION_TYPE_SCHOOL_INVITATION_ACCEPTED_INVITER =
  'school-invitation-accepted-inviter';

export const NOTIFICATION_TYPE_SHARED_PLANNER_NEW_USER =
  'shared-planner-new-user';

export const NOTIFICATION_TYPE_NEW_UNIT_PUBLISHED_TO_PLANNER =
  'new-unit-published-added-to-planner';

export const NOTIFICATION_TYPE_CUSTOMISED_SCOPE_SEQUENCE =
  'customised-scope-sequence';

//Share class Content Type Notifications
export const NOTIFICATION_SHARE_CLASS_ARCHIVE = 'share-classes-archive';
export const NOTIFICATION_SHARE_CLASS_INVITE = 'share-classes-invite';
export const NOTIFICATION_SHARE_CLASS_UNARCHIVE = 'share-classes-unarchive';
export const NOTIFICATION_SHARE_CLASS_TRANSFER_OWNERSHIP =
  'share-classes-transfer-ownership';
export const NOTIFICATION_SHARE_CLASS_LEAVE_CLASS = 'share-classes-leave-class';
export const NOTIFICATION_SHARE_CLASS_REMOVE_USER = 'share-classes-remove-user';
export const NOTIFICATION_UPDATE_SCHOOL_DETAILS = 'school-update-details';

export const NOTIFICATION_CLASS_ARCHIVE_GROUP = 'class-archive';
export const OVERLAY_COOL_DOWN_TIME = 24; /* hours */
export const OVERLAY_COOL_DOWN_TIME_DEV = 1; /* minute */

/**
 * @typedef { typeof SIGN_IN_SCHOOL_ACCOUNT_FEATURE_MESSAGE
 * | typeof SIGN_IN_PLANNER_FEATURE_MESSAGE
 * | typeof SIGN_IN_UNIT_PROGRAM_FEATURE_MESSAGE
 * | typeof SIGN_IN_CLASS_TO_DO_FEATURE_MESSAGE
 * | typeof SIGN_IN_DIFFERENTIATION_FEATURE_MESSAGE
 * | typeof SIGN_IN_MULTI_CLASS_FEATURE_MESSAGE
 * | typeof SIGN_IN_FEATURE_OVERLAY_NOT_READY_MESSAGE
 * } FeatureNotificationId
 */

/**
 * @typedef { typeof SIGN_IN_SCHOOL_ACCOUNT_FEATURE_MESSAGE
 * | typeof SIGN_IN_PLANNER_FEATURE_MESSAGE
 * | typeof SIGN_IN_UNIT_PROGRAM_FEATURE_MESSAGE
 * | typeof SIGN_IN_CLASS_TO_DO_FEATURE_MESSAGE
 * | typeof SIGN_IN_DIFFERENTIATION_FEATURE_MESSAGE
 * | typeof SIGN_IN_MULTI_CLASS_FEATURE_MESSAGE
 * | typeof SIGN_IN_FEATURE_OVERLAY_NOT_READY_MESSAGE
 * | typeof SIGN_IN_PLANNER_ALL_TERMS_MESSAGE
 * | typeof UPDATE_CLASS_PREFERENCES
 * | typeof NOTIFICATION_TYPE_SCHOOL_INVITATION_INVITEE
 * | typeof NOTIFICATION_TYPE_SCHOOL_INVITATION_ACCEPTED_INVITEE
 * | typeof NOTIFICATION_TYPE_SCHOOL_INVITATION_ACCEPTED_INVITER
 * | typeof NOTIFICATION_TYPE_SHARED_PLANNER_NEW_USER
 * | typeof NOTIFICATION_TYPE_NEW_UNIT_PUBLISHED_TO_PLANNER
 * | typeof NOTIFICATION_TYPE_CUSTOMISED_SCOPE_SEQUENCE
 * | typeof SIGN_IN_ACCOUNT_ON_HOLD_MESSAGE
 * | typeof BILLING_ACCOUNT_ON_HOLD_MESSAGE
 * | typeof BILLING_WHOLE_SCHOOL_FREE_PERIOD_MESSAGE
 * | typeof BILLING_CREATE_SCHOOL_FREE_PERIOD_MESSAGE
 * | typeofBILLING_CREATE_SCHOOL_DISCOUNT_MESSAGE
 * } NotificationContentTypeId
 */

export const FEATURE_NOTIFICATION_IDS = [
  SIGN_IN_SCHOOL_ACCOUNT_FEATURE_MESSAGE,
  SIGN_IN_PLANNER_FEATURE_MESSAGE,
  SIGN_IN_UNIT_PROGRAM_FEATURE_MESSAGE,
  SIGN_IN_CLASS_TO_DO_FEATURE_MESSAGE,
  SIGN_IN_DIFFERENTIATION_FEATURE_MESSAGE,
  SIGN_IN_FEATURE_OVERLAY_NOT_READY_MESSAGE,
  SIGN_IN_MULTI_CLASS_FEATURE_MESSAGE,
];

export const NOTIFICATION_CONTENT_TYPE_IDS = [
  SIGN_IN_SCHOOL_ACCOUNT_FEATURE_MESSAGE,
  SIGN_IN_PLANNER_FEATURE_MESSAGE,
  SIGN_IN_UNIT_PROGRAM_FEATURE_MESSAGE,
  SIGN_IN_CLASS_TO_DO_FEATURE_MESSAGE,
  SIGN_IN_DIFFERENTIATION_FEATURE_MESSAGE,
  SIGN_IN_MULTI_CLASS_FEATURE_MESSAGE,
  SIGN_IN_FEATURE_OVERLAY_NOT_READY_MESSAGE,
  SIGN_IN_PLANNER_ALL_TERMS_MESSAGE,
  UPDATE_CLASS_PREFERENCES,
];

export const FEATURE_NOTIFICATION = 'feature-notification';
export const ACCOUNT_NOTIFICATION = 'account-notification';
export const GENERAL_NOTIFICATION = 'general-notification';
export const ALERT_NOTIFICATION = 'alert-notification';
export const STANDALONE_NOTIFICATION = 'stand-alone-notification';
export const SURVEY_NOTIFICATION = 'survey-notification';
export const CLASS_NOTIFICATION = 'class-notification';

/**
 * @typedef { typeof FEATURE_NOTIFICATION
 * | typeof GENERAL_NOTIFICATION
 * | typeof STANDALONE_NOTIFICATION
 * | typeof ALERT_NOTIFICATION
 * | typeof ACCOUNT_NOTIFICATION
 * | typeof SURVEY_NOTIFICATION
 * | typeof CLASS_NOTIFICATION
 * } NotificationCategory
 */

export const NOTIFICATION_CATEGORIES = [
  FEATURE_NOTIFICATION,
  STANDALONE_NOTIFICATION,
  GENERAL_NOTIFICATION,
  ACCOUNT_NOTIFICATION,
  SURVEY_NOTIFICATION,
  ALERT_NOTIFICATION,
  CLASS_NOTIFICATION,
];

export const ACTIVITIES_PAGE_TYPE_TEACHER = 'teacher';
export const ACTIVITIES_PAGE_TYPE_STUDENT = 'student';

/**
 * @typedef { typeof ACTIVITIES_PAGE_TYPE_TEACHER
 * | typeof ACTIVITIES_PAGE_TYPE_STUDENT
 * } ActivitiesPageType
 */

/**
 * @type {ActivitiesPageType[]}
 */
export const ACTIVITIES_PAGE_TYPE = [
  ACTIVITIES_PAGE_TYPE_TEACHER,
  ACTIVITIES_PAGE_TYPE_STUDENT,
];

// Activities settings
export const DRAFT_CHANGES_GROUPING_TOLERANCE_IN_SECONDS = 300;
export const PUBLISHED_ACTIVITIES_PDF_DOWNLOAD_EXPIRE_TIME_IN_SECONDS = 86400; // 24 hours

/**
 * @typedef { typeof PUBLISH_TASK_STATUS_PENDING_UPLOAD
 * | typeof PUBLISH_TASK_STATUS_UPLOAD_RECEIVED
 * | typeof PUBLISH_TASK_STATUS_PDF_GENERATED
 * | typeof PUBLISH_TASK_STATUS_COMPLETED
 * } PublishTaskStatus
 */
export const PUBLISH_TASK_STATUS_PENDING_UPLOAD = 'pending upload';
export const PUBLISH_TASK_STATUS_UPLOAD_RECEIVED = 'upload received';
export const PUBLISH_TASK_STATUS_PDF_GENERATED = 'pdf generated';
export const PUBLISH_TASK_STATUS_COMPLETED = 'completed';

/**
 * @type {PublishTaskStatus[]}
 */
export const PUBLISH_TASK_STATUS = [
  PUBLISH_TASK_STATUS_PENDING_UPLOAD,
  PUBLISH_TASK_STATUS_UPLOAD_RECEIVED,
  PUBLISH_TASK_STATUS_PDF_GENERATED,
  PUBLISH_TASK_STATUS_COMPLETED,
];

/**
 * @typedef { typeof ACTIVITIES_CONTENT_STATUS_DRAFT
 * | typeof ACTIVITIES_CONTENT_STATUS_PUBLISHED
 * } ActivitiesContentStatus
 */
export const ACTIVITIES_CONTENT_STATUS_DRAFT = 'draft';
export const ACTIVITIES_CONTENT_STATUS_PUBLISHED = 'published';

/**
 * @type {ActivitiesContentStatus[]}
 */
export const ACTIVITIES_CONTENT_STATUS = [
  ACTIVITIES_CONTENT_STATUS_DRAFT,
  ACTIVITIES_CONTENT_STATUS_PUBLISHED,
];

/**
 * @typedef { typeof ACTIVITIES_FONT_K
 * | typeof ACTIVITIES_FONT_1_2
 * | typeof ACTIVITIES_FONT_3_4
 * | typeof ACTIVITIES_FONT_5_6
 * } ActivitiesFontSize
 */
export const ACTIVITIES_FONT_K = 30;
export const ACTIVITIES_FONT_1_2 = 28;
export const ACTIVITIES_FONT_3_4 = 22;
export const ACTIVITIES_FONT_5_6 = 20;

/**
 * @type {ActivitiesFontSize[]}
 */
export const ACTIVITIES_FONT_SIZE = [
  ACTIVITIES_FONT_K,
  ACTIVITIES_FONT_1_2,
  ACTIVITIES_FONT_3_4,
  ACTIVITIES_FONT_5_6,
];

// Content Object type
export const CONTENT_OBJECT_TYPE_LESSON = 'lesson';
export const CONTENT_OBJECT_TYPE_ASSESSMENT = 'assessment';
export const CONTENT_OBJECT_TYPE_TOPIC = 'topic';
export const CONTENT_OBJECT_TYPE_UNIT = 'unit';
export const CONTENT_OBJECT_TYPE_STIMULUS = 'stimulus';

export const CONTENT_OBJECT_TYPES = [
  CONTENT_OBJECT_TYPE_LESSON,
  CONTENT_OBJECT_TYPE_ASSESSMENT,
  CONTENT_OBJECT_TYPE_TOPIC,
  CONTENT_OBJECT_TYPE_UNIT,
  CONTENT_OBJECT_TYPE_STIMULUS,
];
/**
 * @typedef {typeof CONTENT_OBJECT_TYPE_LESSON
 * | typeof CONTENT_OBJECT_TYPE_ASSESSMENT
 * | typeof CONTENT_OBJECT_TYPE_TOPIC
 * | typeof CONTENT_OBJECT_TYPE_UNIT
 * | typeof CONTENT_OBJECT_TYPE_STIMULUS
 * } ContentObjectType
 */

export const CONTENT_STATIC_TEACHING_LEARNING_OBJECT_TYPES = [
  CONTENT_OBJECT_TYPE_LESSON,
  CONTENT_OBJECT_TYPE_ASSESSMENT,
];
/**
 * @typedef {typeof CONTENT_OBJECT_TYPE_LESSON
 * | typeof CONTENT_OBJECT_TYPE_ASSESSMENT
 * } ContentStaticTeachingLearningObjectType
 */

export const CONTENT_COMPLETION_OBJECT_TYPES = [
  CONTENT_OBJECT_TYPE_LESSON,
  CONTENT_OBJECT_TYPE_ASSESSMENT,
];
/**
 * @typedef {typeof CONTENT_OBJECT_TYPE_LESSON
 * | typeof CONTENT_OBJECT_TYPE_ASSESSMENT
 * } ContentCompletionObjectType
 */

export const SUPPORTING_MATERIAL_TYPE_LINK = 'link';
export const SUPPORTING_MATERIAL_TYPE_BOOK = 'book';

/**
 * @typedef {typeof SUPPORTING_MATERIAL_TYPE_LINK
 * | typeof SUPPORTING_MATERIAL_TYPE_BOOK
 * } SupportingMaterialType
 */

/** @type {Array<SupportingMaterialType>} */

export const SUPPORTING_MATERIAL_TYPES = [
  SUPPORTING_MATERIAL_TYPE_LINK,
  SUPPORTING_MATERIAL_TYPE_BOOK,
];

export const UNIT_TERM_1 = 1; //'Term 1';
export const UNIT_TERM_2 = 2; //'Term 2';
export const UNIT_TERM_3 = 3; //'Term 3';
export const UNIT_TERM_4 = 4; //'Term 4';
/**
 * @typedef {typeof UNIT_TERM_1
 * | typeof UNIT_TERM_2
 *  | typeof UNIT_TERM_3
 *  | typeof UNIT_TERM_4
 * } UnitTerms
 */
/** @type {Array<UnitTerms>} */

export const UNIT_TERMS = [UNIT_TERM_1, UNIT_TERM_2, UNIT_TERM_3, UNIT_TERM_4];

export const UNIT_RELEASE_MESSAGE_MAX_LENGTH = 300;
export const UNIT_STATUS_PUBLISHED = 'published';
export const UNIT_STATUS_DRAFT = 'draft';
export const UNIT_STATUS_COMING_SOON = 'coming soon';

/**
 * @typedef {typeof UNIT_STATUS_DRAFT
 * | typeof UNIT_STATUS_COMING_SOON
 * | typeof UNIT_STATUS_PUBLISHED
 * } UnitStatus
 */

/** @type {Array<UnitStatus>} */

export const UNIT_STATUSES = [
  UNIT_STATUS_DRAFT,
  UNIT_STATUS_COMING_SOON,
  UNIT_STATUS_PUBLISHED,
];

export const TOPIC_STATUS_PUBLISHED = 'published';
export const TOPIC_STATUS_FUTURE = 'future';
export const TOPIC_STATUS_DRAFT = 'draft';
/**
 * @typedef {typeof TOPIC_STATUS_PUBLISHED
 * | typeof TOPIC_STATUS_FUTURE
 * | typeof TOPIC_STATUS_DRAFT
 * } TopicStatus
 */

/** @type {Array<TopicStatus>} */
export const TOPIC_STATUSES = [
  TOPIC_STATUS_PUBLISHED,
  TOPIC_STATUS_FUTURE,
  TOPIC_STATUS_DRAFT,
];

// Bookmarks
export const BOOKMARK_ASSESSMENT = 'assessment';
export const BOOKMARK_LESSON = 'lesson';
export const BOOKMARK_UNIT = 'unit';
export const BOOKMARK_STIMULUS = 'stimulus';
export const BOOKMARK_ALL = 'all';
/**
 * @typedef {typeof BOOKMARK_ASSESSMENT
 * | typeof BOOKMARK_LESSON
 * | typeof BOOKMARK_UNIT
 * | typeof BOOKMARK_STIMULUS
 * | typeof BOOKMARK_ALL
 * } BookmarkType
 */

/**
 * @type {Array<BookmarkType>}
 */
export const BOOKMARK_TYPES = [
  BOOKMARK_UNIT,
  BOOKMARK_LESSON,
  BOOKMARK_ASSESSMENT,
  BOOKMARK_STIMULUS,
  BOOKMARK_ALL,
];

export const VIDEO_SOURCE_YOUTUBE = 'youtube';
export const VIDEO_SOURCE_CLICKVIEW = 'clickview';
export const VIDEO_SOURCE_VIMEO = 'vimeo';
/**
 * @typedef {typeof VIDEO_SOURCE_YOUTUBE
 * | typeof VIDEO_SOURCE_CLICKVIEW
 * | typeof VIDEO_SOURCE_VIMEO
 * } VideoSource
 */

/**
 * @type {Array<VideoSource>}
 */
export const VIDEO_SOURCES = [
  VIDEO_SOURCE_VIMEO,
  VIDEO_SOURCE_CLICKVIEW,
  VIDEO_SOURCE_YOUTUBE,
];

// Content types
export const AUDIO = 'audio';
export const IMAGE = 'image';
export const VIDEO = 'video';
export const EBOOK_PDF = 'e-book';
export const EBOOK_INTERACTIVE = 'e-book-interactive';
export const LINK = 'link';
export const WEB_TOOL = 'web-tool';
export const GUIDED_RESEARCH = 'guided-research';
export const INQUISITIVE_PAGE = 'inquisitive-page';
export const DOCUMENT = 'document';
export const POWERPOINT = 'powerpoint';
export const INTERACTIVE = 'interactive';

export const DIFFERENTIATION_CORE = 1;
export const DIFFERENTIATION_DEEPER = 2;
export const DIFFERENTIATION_CHALLENGING = 3;
export const DIFFERENTIATION_SELF_ASSESSMENT = 4;
export const DIFFERENTIATION_ANCHOR_MAP = 5;
export const DIFFERENTIATION_UNIT_QUIZ = 6;
export const TLS_GUEST_VIEW_MAX_EXPLANATION = 1;

//the Es
export const TEACHING_LEARNING_SEQUENCE_THE_E_EXPLORE = 'Explore';
export const TEACHING_LEARNING_SEQUENCE_THE_E_EXPLAIN = 'Explain';
export const TEACHING_LEARNING_SEQUENCE_THE_E_ENGAGE = 'Engage';
export const TEACHING_LEARNING_SEQUENCE_THE_E_EVALUATE = 'Evaluate';
export const TEACHING_LEARNING_SEQUENCE_THE_E_ELABORATE = 'Elaborate';

/**
 * @typedef {typeof TEACHING_LEARNING_SEQUENCE_THE_E_EXPLORE
 * | typeof TEACHING_LEARNING_SEQUENCE_THE_E_EXPLAIN
 * | typeof TEACHING_LEARNING_SEQUENCE_THE_E_ENGAGE
 * | typeof TEACHING_LEARNING_SEQUENCE_THE_E_EVALUATE
 * | typeof TEACHING_LEARNING_SEQUENCE_THE_E_ELABORATE
 * } TeachingAndLearningSequenceTheETypes
 */
export const TEACHING_LEARNING_SEQUENCE_THE_E_TYPES = [
  TEACHING_LEARNING_SEQUENCE_THE_E_ENGAGE,
  TEACHING_LEARNING_SEQUENCE_THE_E_EXPLORE,
  TEACHING_LEARNING_SEQUENCE_THE_E_EXPLAIN,
  TEACHING_LEARNING_SEQUENCE_THE_E_ELABORATE,
  TEACHING_LEARNING_SEQUENCE_THE_E_EVALUATE,
];

//VisibleThinkingActivities
export const VISIBLE_THINKING_ACTIVITY_SEE_THINK_WONDER = 'see-think-wonder';
export const VISIBLE_THINKING_ACTIVITY_COMPASS_POINTS = 'compass-points';
export const VISIBLE_THINKING_ACTIVITY_THINK_PEEL_THE_FRUIT = 'peel-the-fruit';
export const VISIBLE_THINKING_ACTIVITY_QUESTION_ORGANISER =
  'question-organiser';
export const VISIBLE_THINKING_ACTIVITY_3_2_1_BRIDGE = '3-2-1-bridge';
export const VISIBLE_THINKING_ACTIVITY_SIX_THINKING_HATS = 'six-thinking-hats';
export const VISIBLE_THINKING_ACTIVITY_THINK_PUZZLE_EXPLORE =
  'think-puzzle-explore';
export const VISIBLE_THINKING_ACTIVITY_PLUS_MINUS_INTERESTING =
  'plus-minus-interesting';
export const VISIBLE_THINKING_ACTIVITY_THINK_PAIR_SHARE = 'think-pair-share';
export const VISIBLE_THINKING_ACTIVITY_VENN_DIAGRAM = 'venn-diagram';
export const VISIBLE_THINKING_ACTIVITY_I_USED_TO_THINK = 'i-used-to-think';
export const VISIBLE_THINKING_ACTIVITY_MIND_MAP = 'mind-map';
export const VISIBLE_THINKING_ACTIVITY_CONNECT_EXTEND_CHALLENGE =
  'connect-extend-challenge';
export const VISIBLE_THINKING_ACTIVITY_KWHL_CHART = 'kwhl-chart';
export const VISIBLE_THINKING_ACTIVITY_COLOUR_SYMBOL_IMAGE =
  'colour-symbol-image';
export const VISIBLE_THINKING_ACTIVITY_JIGSAW_STRATEGY = 'jigsaw-strategy';
export const VISIBLE_THINKING_ACTIVITY_3_2_1_ORGANISER = '3-2-1-organiser';
export const VISIBLE_THINKING_ACTIVITY_TUG_OF_WAR = 'tug-of-war';
export const VISIBLE_THINKING_ACTIVITY_CONCEPT_MAP = 'concept-map';
export const VISIBLE_THINKING_ACTIVITY_INFOGRAPHIC = 'infographic';
export const VISIBLE_THINKING_ACTIVITY_STEP_INSIDE = 'step-inside';
export const VISIBLE_THINKING_ACTIVITY_10_X_2_THINKING = '10-x-2-thinking';
export const VISIBLE_THINKING_ACTIVITY_ANCHOR_MAP = 'anchor-map';
export const VISIBLE_THINKING_ACTIVITY_CLINE = 'cline';

/**
 * @typedef {typeof VISIBLE_THINKING_ACTIVITY_SEE_THINK_WONDER
 * | typeof VISIBLE_THINKING_ACTIVITY_COMPASS_POINTS
 * | typeof VISIBLE_THINKING_ACTIVITY_THINK_PEEL_THE_FRUIT
 * | typeof VISIBLE_THINKING_ACTIVITY_QUESTION_ORGANISER
 * | typeof VISIBLE_THINKING_ACTIVITY_3_2_1_BRIDGE
 * | typeof VISIBLE_THINKING_ACTIVITY_SIX_THINKING_HATS
 * | typeof VISIBLE_THINKING_ACTIVITY_THINK_PUZZLE_EXPLORE
 * | typeof VISIBLE_THINKING_ACTIVITY_PLUS_MINUS_INTERESTING
 * | typeof VISIBLE_THINKING_ACTIVITY_THINK_PAIR_SHARE
 * | typeof VISIBLE_THINKING_ACTIVITY_VENN_DIAGRAM
 * | typeof VISIBLE_THINKING_ACTIVITY_I_USED_TO_THINK
 * | typeof VISIBLE_THINKING_ACTIVITY_MIND_MAP
 * | typeof VISIBLE_THINKING_ACTIVITY_CONNECT_EXTEND_CHALLENGE
 * | typeof VISIBLE_THINKING_ACTIVITY_KWHL_CHART
 * | typeof VISIBLE_THINKING_ACTIVITY_COLOUR_SYMBOL_IMAGE
 * | typeof VISIBLE_THINKING_ACTIVITY_JIGSAW_STRATEGY
 * | typeof VISIBLE_THINKING_ACTIVITY_3_2_1_ORGANISER
 * | typeof VISIBLE_THINKING_ACTIVITY_TUG_OF_WAR
 * | typeof VISIBLE_THINKING_ACTIVITY_CONCEPT_MAP
 * | typeof VISIBLE_THINKING_ACTIVITY_INFOGRAPHIC
 * | typeof VISIBLE_THINKING_ACTIVITY_STEP_INSIDE
 * | typeof VISIBLE_THINKING_ACTIVITY_10_X_2_THINKING
 * | typeof VISIBLE_THINKING_ACTIVITY_ANCHOR_MAP
 * | typeof VISIBLE_THINKING_ACTIVITY_CLINE
 * } VisibleThinkingActivities
 */

export const VISIBLE_THINKING_ACTIVITIES = [
  VISIBLE_THINKING_ACTIVITY_SEE_THINK_WONDER,
  VISIBLE_THINKING_ACTIVITY_COMPASS_POINTS,
  VISIBLE_THINKING_ACTIVITY_THINK_PEEL_THE_FRUIT,
  VISIBLE_THINKING_ACTIVITY_QUESTION_ORGANISER,
  VISIBLE_THINKING_ACTIVITY_3_2_1_BRIDGE,
  VISIBLE_THINKING_ACTIVITY_SIX_THINKING_HATS,
  VISIBLE_THINKING_ACTIVITY_THINK_PUZZLE_EXPLORE,
  VISIBLE_THINKING_ACTIVITY_PLUS_MINUS_INTERESTING,
  VISIBLE_THINKING_ACTIVITY_THINK_PAIR_SHARE,
  VISIBLE_THINKING_ACTIVITY_VENN_DIAGRAM,
  VISIBLE_THINKING_ACTIVITY_I_USED_TO_THINK,
  VISIBLE_THINKING_ACTIVITY_MIND_MAP,
  VISIBLE_THINKING_ACTIVITY_CONNECT_EXTEND_CHALLENGE,
  VISIBLE_THINKING_ACTIVITY_KWHL_CHART,
  VISIBLE_THINKING_ACTIVITY_COLOUR_SYMBOL_IMAGE,
  VISIBLE_THINKING_ACTIVITY_JIGSAW_STRATEGY,
  VISIBLE_THINKING_ACTIVITY_3_2_1_ORGANISER,
  VISIBLE_THINKING_ACTIVITY_TUG_OF_WAR,
  VISIBLE_THINKING_ACTIVITY_CONCEPT_MAP,
  VISIBLE_THINKING_ACTIVITY_INFOGRAPHIC,
  VISIBLE_THINKING_ACTIVITY_STEP_INSIDE,
  VISIBLE_THINKING_ACTIVITY_10_X_2_THINKING,
  VISIBLE_THINKING_ACTIVITY_ANCHOR_MAP,
  VISIBLE_THINKING_ACTIVITY_CLINE,
];

export const HIGH_IMPACT_TEACHING_STRATEGY_SETTING_GOALS = 'setting-goals';
export const HIGH_IMPACT_TEACHING_STRATEGY_STRUCTURING_LESSONS =
  'structuring-lessons';
export const HIGH_IMPACT_TEACHING_STRATEGY_EXPLORE_TEACHING =
  'explore-teaching';
export const HIGH_IMPACT_TEACHING_STRATEGY_WORKED_EXAMPLES = 'worked-examples';
export const HIGH_IMPACT_TEACHING_STRATEGY_COLLABORATION = 'collaboration';
export const HIGH_IMPACT_TEACHING_STRATEGY_MULTIPLE_EXPOSURES =
  'multiple-exposures';
export const HIGH_IMPACT_TEACHING_STRATEGY_QUESTIONING = 'questioning';
export const HIGH_IMPACT_TEACHING_STRATEGY_FEEDBACK = 'feedback';
export const HIGH_IMPACT_TEACHING_STRATEGY_METACOGNITION = 'metacognition';
export const HIGH_IMPACT_TEACHING_STRATEGY_DIFFERENTIATION = 'differentiation';

/**
 * @typedef {typeof HIGH_IMPACT_TEACHING_STRATEGY_SETTING_GOALS
 * | typeof HIGH_IMPACT_TEACHING_STRATEGY_STRUCTURING_LESSONS
 * | typeof HIGH_IMPACT_TEACHING_STRATEGY_EXPLORE_TEACHING
 * | typeof HIGH_IMPACT_TEACHING_STRATEGY_WORKED_EXAMPLES
 * | typeof HIGH_IMPACT_TEACHING_STRATEGY_COLLABORATION
 * | typeof HIGH_IMPACT_TEACHING_STRATEGY_MULTIPLE_EXPOSURES
 * | typeof HIGH_IMPACT_TEACHING_STRATEGY_QUESTIONING
 * | typeof HIGH_IMPACT_TEACHING_STRATEGY_FEEDBACK
 * | typeof HIGH_IMPACT_TEACHING_STRATEGY_METACOGNITION
 * | typeof HIGH_IMPACT_TEACHING_STRATEGY_DIFFERENTIATION
 * } HighImpactTeachingStrategies
 * */

export const HIGH_IMPACT_TEACHING_STRATEGIES = [
  HIGH_IMPACT_TEACHING_STRATEGY_SETTING_GOALS,
  HIGH_IMPACT_TEACHING_STRATEGY_STRUCTURING_LESSONS,
  HIGH_IMPACT_TEACHING_STRATEGY_EXPLORE_TEACHING,
  HIGH_IMPACT_TEACHING_STRATEGY_WORKED_EXAMPLES,
  HIGH_IMPACT_TEACHING_STRATEGY_COLLABORATION,
  HIGH_IMPACT_TEACHING_STRATEGY_MULTIPLE_EXPOSURES,
  HIGH_IMPACT_TEACHING_STRATEGY_QUESTIONING,
  HIGH_IMPACT_TEACHING_STRATEGY_FEEDBACK,
  HIGH_IMPACT_TEACHING_STRATEGY_METACOGNITION,
  HIGH_IMPACT_TEACHING_STRATEGY_DIFFERENTIATION,
];

export const LEARNING_SEQUENCE_CATEGORY_ACTIVITY_DESCRIPTION =
  'activity-description';
export const LEARNING_SEQUENCE_CATEGORY_EXPLANATION = 'explanation';
export const LEARNING_SEQUENCE_CATEGORY_CORE = 'core'; //1
export const LEARNING_SEQUENCE_CATEGORY_DEEPER = 'deeper'; //2
export const LEARNING_SEQUENCE_CATEGORY_CHALLENGING = 'challenging'; //3
export const LEARNING_SEQUENCE_CATEGORY_LEVEL_OF_ATTAINMENT =
  'level-of-attainment';
export const LEARNING_SEQUENCE_CATEGORY_ANSWER = 'answer';
export const LEARNING_SEQUENCE_CATEGORY_INTENTION = 'intention';
export const LEARNING_SEQUENCE_CATEGORY_NOTE = 'note';
export const LEARNING_SEQUENCE_CATEGORY_INTRODUCTION = 'introduction';
export const LEARNING_SEQUENCE_CATEGORY_COUNTING_ROUTINE = 'counting-routine';
export const LEARNING_SEQUENCE_CATEGORY_MATERIALS_NEEDED = 'materials-needed';
export const LEARNING_SEQUENCE_CATEGORY_METACOGNITION = 'metacongnition';
export const LEARNING_SEQUENCE_CATEGORY_EXTENSION = 'extension';
export const LEARNING_SEQUENCE_CATEGORY_RECOMMENDATIONS_FOR_SUPPORT =
  'recommendations-for-support';
export const LEARNING_SEQUENCE_CATEGORY_EXTRA_CONNECTIONS = 'extra-connections';
export const LEARNING_SEQUENCE_CATEGORY_ASSESSMENT = 'assessment';
export const LEARNING_SEQUENCE_CATEGORY_TEACHER_KNOWLEDGE = 'teacher-knowledge';
export const LEARNING_SEQUENCE_CATEGORY_LEARNING_TARGETS = 'learning-targets';
export const LEARNING_SEQUENCE_CATEGORY_SUGGESTED_TEACHER_TALK =
  'suggested-teacher-talk';
export const LEARNING_SEQUENCE_CATEGORY_PRACTICAL_TIPS = 'practical-tips';
export const LEARNING_SEQUENCE_CATEGORY_ASSESSMENT_FOR_LEARNING_TOOL =
  'assessment-for-learning-tool';
export const LEARNING_SEQUENCE_CATEGORY_HOOK = 'hook';
export const LEARNING_SEQUENCE_CATEGORY_LINKS_LITERACY_MATHEMATICS =
  'links-literacy-mathematics';
export const LEARNING_SEQUENCE_CATEGORY_SUCCESS_CRITERIA = 'success-criteria';

export const LEARNING_SEQUENCE_ITEM_TYPE_QUESTION = 'question';
export const LEARNING_SEQUENCE_ITEM_TYPE_INTRODUCTION = 'lesson-introduction';
export const LEARNING_SEQUENCE_ITEM_TYPE_CONCLUSION = 'lesson-conclusion';
export const LESSON_OVERVIEW = 'lesson-overview';

/**
 * @typedef {typeof LEARNING_SEQUENCE_ITEM_TYPE_QUESTION
 * | typeof LEARNING_SEQUENCE_ITEM_TYPE_INTRODUCTION
 * | typeof LEARNING_SEQUENCE_ITEM_TYPE_CONCLUSION
 * } LearningSequenceItemTypes
 */

export const LEARNING_SEQUENCE_ITEM_TYPES = [
  LEARNING_SEQUENCE_ITEM_TYPE_QUESTION,
  LEARNING_SEQUENCE_ITEM_TYPE_INTRODUCTION,
  LEARNING_SEQUENCE_ITEM_TYPE_CONCLUSION,
];

/**
 * @typedef {typeof LEARNING_SEQUENCE_CATEGORY_EXPLANATION
 * | typeof LEARNING_SEQUENCE_CATEGORY_ANSWER
 * | typeof LEARNING_SEQUENCE_CATEGORY_LEVEL_OF_ATTAINMENT
 * | typeof LEARNING_SEQUENCE_CATEGORY_INTENTION
 * | typeof LEARNING_SEQUENCE_CATEGORY_NOTE
 * | typeof LEARNING_SEQUENCE_CATEGORY_INTRODUCTION
 * | typeof LEARNING_SEQUENCE_CATEGORY_COUNTING_ROUTINE
 * | typeof LEARNING_SEQUENCE_CATEGORY_MATERIALS_NEEDED
 * | typeof LEARNING_SEQUENCE_CATEGORY_METACOGNITION
 * | typeof LEARNING_SEQUENCE_CATEGORY_EXTENSION
 * | typeof LEARNING_SEQUENCE_CATEGORY_RECOMMENDATIONS_FOR_SUPPORT
 * | typeof LEARNING_SEQUENCE_CATEGORY_ASSESSMENT
 * | typeof LEARNING_SEQUENCE_CATEGORY_TEACHER_KNOWLEDGE
 * | typeof LEARNING_SEQUENCE_CATEGORY_LEARNING_TARGETS
 * | typeof LEARNING_SEQUENCE_CATEGORY_SUCCESS_CRITERIA
 * | typeof LEARNING_SEQUENCE_CATEGORY_SUGGESTED_TEACHER_TALK
 * | typeof LEARNING_SEQUENCE_CATEGORY_PRACTICAL_TIPS
 * | typeof LEARNING_SEQUENCE_CATEGORY_ASSESSMENT_FOR_LEARNING_TOOL
 * | typeof LEARNING_SEQUENCE_CATEGORY_HOOK
 * | typeof LEARNING_SEQUENCE_CATEGORY_LINKS_LITERACY_MATHEMATICS
 * | typeof LEARNING_SEQUENCE_CATEGORY_EXTRA_CONNECTIONS
 * | typeof LEARNING_SEQUENCE_CATEGORY_ACTIVITY_DESCRIPTION
 * } LearningSequenceItemCategories
 */

/**
 * @type {Array<LearningSequenceItemCategories>}
 */
export const LEARNING_SEQUENCE_ITEM_CATEGORIES = [
  LEARNING_SEQUENCE_CATEGORY_ANSWER,
  LEARNING_SEQUENCE_CATEGORY_EXPLANATION,
  LEARNING_SEQUENCE_CATEGORY_LEVEL_OF_ATTAINMENT,
  LEARNING_SEQUENCE_CATEGORY_INTENTION,
  LEARNING_SEQUENCE_CATEGORY_NOTE,
  LEARNING_SEQUENCE_CATEGORY_INTRODUCTION,
  LEARNING_SEQUENCE_CATEGORY_COUNTING_ROUTINE,
  LEARNING_SEQUENCE_CATEGORY_MATERIALS_NEEDED,
  LEARNING_SEQUENCE_CATEGORY_METACOGNITION,
  LEARNING_SEQUENCE_CATEGORY_EXTENSION,
  LEARNING_SEQUENCE_CATEGORY_RECOMMENDATIONS_FOR_SUPPORT,
  LEARNING_SEQUENCE_CATEGORY_ASSESSMENT,
  LEARNING_SEQUENCE_CATEGORY_TEACHER_KNOWLEDGE,
  LEARNING_SEQUENCE_CATEGORY_LEARNING_TARGETS,
  LEARNING_SEQUENCE_CATEGORY_SUCCESS_CRITERIA,
  LEARNING_SEQUENCE_CATEGORY_SUGGESTED_TEACHER_TALK,
  LEARNING_SEQUENCE_CATEGORY_PRACTICAL_TIPS,
  LEARNING_SEQUENCE_CATEGORY_ASSESSMENT_FOR_LEARNING_TOOL,
  LEARNING_SEQUENCE_CATEGORY_HOOK,
  LEARNING_SEQUENCE_CATEGORY_LINKS_LITERACY_MATHEMATICS,
  LEARNING_SEQUENCE_CATEGORY_EXTRA_CONNECTIONS,
  LEARNING_SEQUENCE_CATEGORY_ACTIVITY_DESCRIPTION,
];

export const LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPE_GROUP = 'group';
export const LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPE_STUDENT = 'student';
export const LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPE_CLASS = 'class';

/**
 *@typedef {typeof LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPE_GROUP
 * | typeof LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPE_STUDENT
 * | typeof LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPE_CLASS
 * } LessonMaterialGroupTypes
 */

/**
 * @typedef  {Array<LessonMaterialGroupTypes>}
 */
export const LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPES = [
  LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPE_GROUP,
  LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPE_STUDENT,
  LEARNING_SEQUENCE_LESSON_MATERIAL_GROUP_TYPE_CLASS,
];

/**
 * @type {Array<{label: string; value: LearningSequenceIntroductionCategories}>}
 */
export const LEARNING_SEQUENCE_INTRODUCTION_CATEGORIES = [
  {
    label: 'Assessment for Learning Tool',
    value: LEARNING_SEQUENCE_CATEGORY_ASSESSMENT_FOR_LEARNING_TOOL,
  },
  {
    label: 'Counting Routine',
    value: LEARNING_SEQUENCE_CATEGORY_COUNTING_ROUTINE,
  },
  {
    label: 'Hook',
    value: LEARNING_SEQUENCE_CATEGORY_HOOK,
  },
  {
    label: 'Introduction',
    value: LEARNING_SEQUENCE_CATEGORY_INTRODUCTION,
  },
  {
    label: 'Learning Intention',
    value: LEARNING_SEQUENCE_CATEGORY_INTENTION,
  },
  {
    label: 'Learning Targets',
    value: LEARNING_SEQUENCE_CATEGORY_LEARNING_TARGETS,
  },
  {
    label: 'Lesson Materials',
    value: LEARNING_SEQUENCE_CATEGORY_MATERIALS_NEEDED,
  },
  {
    label: 'Note',
    value: LEARNING_SEQUENCE_CATEGORY_NOTE,
  },
  {
    label: 'Suggested Teacher Talk',
    value: LEARNING_SEQUENCE_CATEGORY_SUGGESTED_TEACHER_TALK,
  },
  {
    label: 'Teacher Reading',
    value: LEARNING_SEQUENCE_CATEGORY_TEACHER_KNOWLEDGE,
  },
  {
    label: 'Success Criteria',
    value: LEARNING_SEQUENCE_CATEGORY_SUCCESS_CRITERIA,
  },
];

/**
 * @type {Array<{label: string; value: LearningSequenceIntroductionCategoriesLb}>}
 */
export const LEARNING_SEQUENCE_INTRODUCTION_CATEGORIES_LB = [
  {
    label: 'Assessment for Learning Tool',
    value: LEARNING_SEQUENCE_CATEGORY_ASSESSMENT_FOR_LEARNING_TOOL,
  },
  {
    label: 'Counting Routine',
    value: LEARNING_SEQUENCE_CATEGORY_COUNTING_ROUTINE,
  },
  {
    label: 'Hook',
    value: LEARNING_SEQUENCE_CATEGORY_HOOK,
  },
  {
    label: 'Introduction',
    value: LEARNING_SEQUENCE_CATEGORY_INTRODUCTION,
  },
  {
    label: 'Learning Intention',
    value: LEARNING_SEQUENCE_CATEGORY_INTENTION,
  },
  {
    label: 'Learning Targets',
    value: LEARNING_SEQUENCE_CATEGORY_LEARNING_TARGETS,
  },
  {
    label: 'Note',
    value: LEARNING_SEQUENCE_CATEGORY_NOTE,
  },
  {
    label: 'Success Criteria',
    value: LEARNING_SEQUENCE_CATEGORY_SUCCESS_CRITERIA,
  },
  {
    label: 'Suggested Teacher Talk',
    value: LEARNING_SEQUENCE_CATEGORY_SUGGESTED_TEACHER_TALK,
  },
  {
    label: 'Teacher Reading',
    value: LEARNING_SEQUENCE_CATEGORY_TEACHER_KNOWLEDGE,
  },
];

/**
 * @type {Array<{label: string; value: LearningSequenceQuestionCategories}>}
 */
export const LEARNING_SEQUENCE_QUESTION_CATEGORIES = [
  {
    label: 'ELA and Math Connections',
    value: LEARNING_SEQUENCE_CATEGORY_EXTRA_CONNECTIONS,
  },
  {
    label: 'Note',
    value: LEARNING_SEQUENCE_CATEGORY_NOTE,
  },
  {
    label: 'Extension',
    value: LEARNING_SEQUENCE_CATEGORY_EXTENSION,
  },
  {
    label: 'Practical tips for hands-on activities',
    value: LEARNING_SEQUENCE_CATEGORY_PRACTICAL_TIPS,
  },
  {
    label: 'Recommendations for Support',
    value: LEARNING_SEQUENCE_CATEGORY_RECOMMENDATIONS_FOR_SUPPORT,
  },
  {
    label: 'Suggested Teacher Talk',
    value: LEARNING_SEQUENCE_CATEGORY_SUGGESTED_TEACHER_TALK,
  },
];

/**
 * @type {Array<{label: string; value: LearningSequenceQuestionCategoriesLb}>}
 */
export const LEARNING_SEQUENCE_QUESTION_CATEGORIES_AB = [
  {
    label: 'Activity Materials',
    value: LEARNING_SEQUENCE_CATEGORY_MATERIALS_NEEDED,
  },
  {
    label: 'ELA and Math Connections',
    value: LEARNING_SEQUENCE_CATEGORY_EXTRA_CONNECTIONS,
  },
  {
    label: 'Note',
    value: LEARNING_SEQUENCE_CATEGORY_NOTE,
  },
  {
    label: 'Extension',
    value: LEARNING_SEQUENCE_CATEGORY_EXTENSION,
  },
  {
    label: 'Practical tips for hands-on activities',
    value: LEARNING_SEQUENCE_CATEGORY_PRACTICAL_TIPS,
  },
  {
    label: 'Recommendations for Support',
    value: LEARNING_SEQUENCE_CATEGORY_RECOMMENDATIONS_FOR_SUPPORT,
  },
  {
    label: 'Suggested Teacher Talk',
    value: LEARNING_SEQUENCE_CATEGORY_SUGGESTED_TEACHER_TALK,
  },
];

/**
 * @type {Array<{label: string; value: LearningSequenceConclusionCategories}>}
 */
export const LEARNING_SEQUENCE_CONCLUSION_CATEGORIES = [
  {
    label: 'Assessment',
    value: LEARNING_SEQUENCE_CATEGORY_ASSESSMENT,
  },
  {
    label: 'Extension',
    value: LEARNING_SEQUENCE_CATEGORY_EXTENSION,
  },
  {
    label: 'Metacognition',
    value: LEARNING_SEQUENCE_CATEGORY_METACOGNITION,
  },
  {
    label: 'Note',
    value: LEARNING_SEQUENCE_CATEGORY_NOTE,
  },
  {
    label: 'Recommendations for Support',
    value: LEARNING_SEQUENCE_CATEGORY_RECOMMENDATIONS_FOR_SUPPORT,
  },
  {
    label: 'Links to Literacy and Mathematics',
    value: LEARNING_SEQUENCE_CATEGORY_LINKS_LITERACY_MATHEMATICS,
  },
];

/**
 * @type {Array<{label: string; value: LearningSequenceAdditionalCategories}>}
 */
export const LEARNING_SEQUENCE_ADDITIONAL_CATEGORIES = [
  {
    label: 'Answer',
    value: LEARNING_SEQUENCE_CATEGORY_ANSWER,
  },
  {
    label: 'Explanation',
    value: LEARNING_SEQUENCE_CATEGORY_EXPLANATION,
  },
  {
    label: 'Level of student attainment & observations of student performance',
    value: LEARNING_SEQUENCE_CATEGORY_LEVEL_OF_ATTAINMENT,
  },
  {
    label: 'Recommendations for Support',
    value: LEARNING_SEQUENCE_CATEGORY_RECOMMENDATIONS_FOR_SUPPORT,
  },
  {
    label: 'Metacognition',
    value: LEARNING_SEQUENCE_CATEGORY_METACOGNITION,
  },
];

// Document format
export const PDF_FORMAT = 'pdf';
export const WORD_FORMAT = 'word';
export const POWERPOINT_FORMAT = 'powerpoint';
export const UNSUPPORTED_FORMAT = 'not-supported';

/**
 * @typedef {typeof PDF_FORMAT
 * | typeof WORD_FORMAT
 * | typeof POWERPOINT_FORMAT
 * } DocumentFormat
 */

/**
 * @type {Array<DocumentFormat>}
 */
export const DOCUMENT_FORMATS = [PDF_FORMAT, WORD_FORMAT, POWERPOINT_FORMAT];

/**
 * @typedef {typeof PDF_FORMAT
 * } EbookFormat
 */

/**
 * @type {Array<EbookFormat>}
 */
export const EBOOK_FORMATS = [PDF_FORMAT];

export const ACCOUNT_PROFILE = 'account-profile';
export const ACCOUNT_BILLING = 'account-billing';
export const ACCOUNT_REFER = 'account-refer';
export const ACCOUNT_PEOPLE = 'account-people';

/**
 * @typedef {typeof AUDIO
 * | typeof IMAGE
 * | typeof VIDEO
 * | typeof EBOOK_PDF
 * | typeof EBOOK_INTERACTIVE
 * | typeof LINK
 * | typeof WEB_TOOL
 * | typeof GUIDED_RESEARCH
 * | typeof DOCUMENT
 * | typeof INTERACTIVE
 * } StimulusResourceType
 */

export const STIMULUS_RESOURCE_TYPES = [
  AUDIO,
  DOCUMENT,
  EBOOK_INTERACTIVE,
  EBOOK_PDF,
  GUIDED_RESEARCH,
  IMAGE,
  INTERACTIVE,
  LINK,
  VIDEO,
  WEB_TOOL,
];

export const CONTENT_TYPES_WITH_DOCUMENT = [DOCUMENT, EBOOK_PDF];

// keep in alphabetical order
export const CONTENT_TYPE_LABELS = [
  { value: AUDIO, label: 'Audio' },
  { value: DOCUMENT, label: 'Document' },
  { value: EBOOK_INTERACTIVE, label: 'eBook (Interactive)' },
  { value: EBOOK_PDF, label: 'eBook (PDF)' },
  { value: GUIDED_RESEARCH, label: 'Guided Research' },
  { value: IMAGE, label: 'Image' },
  { value: INTERACTIVE, label: 'Interactive' },
  { value: LINK, label: 'Link' },
  { value: VIDEO, label: 'Video' },
  { value: WEB_TOOL, label: 'Web Tool' },
];

export const STIMULUSMATERIAL = 'stimulusmaterial';
export const WORKSHEET = 'worksheet';
export const PLANNER = 'planner';
export const UNIT = 'unit';
export const ASSESSMENT = 'assessment';
export const LESSON = 'lesson';
export const STANDALONE = 'standalone';
export const LESSON_TEACHING_NOTES = 'lesson_teaching_note';

/**
 * @typedef {typeof LESSON
 * | typeof ASSESSMENT
 * } ContentType
 */

export const CONTENT_TYPES = [LESSON, ASSESSMENT];

/**
 * @typedef {typeof ASSESSMENT_ACTIVITIES
 * | typeof LESSON_ACTIVITIES
 * } ActivitiesContentType
 */
export const ASSESSMENT_ACTIVITIES = 'assessment-activities';
export const LESSON_ACTIVITIES = 'lesson-activities';

/**
 * @type {ActivitiesContentType[]}
 */
export const ACTIVITIES_CONTENT_TYPES = [
  ASSESSMENT_ACTIVITIES,
  LESSON_ACTIVITIES,
];

export const ALL_WORD = 'all';

// Subjects
export const SUBJECT_WORD = 'subject';
export const SUBJECTS_WORD = 'subjects';
export const ALL_SUBJECTS = ALL_WORD;
export const GEOGRAPHY = 'geography';
export const HISTORY = 'history';
export const MATHS = 'maths';
export const CIVICS_AND_CITIZENSHIP = 'civics-and-citizenship';
export const ECONOMICS_AND_BUSINESS = 'economics-and-business';
export const SCIENCE_AND_TECHNOLOGY = 'science-and-technology';
export const SCIENCE_AND_TECHNOLOGY_US = 'science';
export const ENGLISH = 'english';
export const INTEGRATED = 'integrated';

/**
 * @typedef {typeof GEOGRAPHY
 * | typeof HISTORY
 * | typeof CIVICS_AND_CITIZENSHIP
 * | typeof ECONOMICS_AND_BUSINESS
 * | typeof SCIENCE_AND_TECHNOLOGY
 * | typeof ENGLISH
 * | typeof MATHS
 * | typeof INTEGRATED
 * | typeof SCIENCE_AND_TECHNOLOGY_US
 * } Subject
 */

/**
 * @type {Subject[]}
 */
export const SUBJECTS = [
  GEOGRAPHY,
  HISTORY,
  CIVICS_AND_CITIZENSHIP,
  ECONOMICS_AND_BUSINESS,
  SCIENCE_AND_TECHNOLOGY,
  ENGLISH,
  MATHS,
  SCIENCE_AND_TECHNOLOGY_US,
  INTEGRATED,
];

export const US_SUBJECTS = [SCIENCE_AND_TECHNOLOGY_US];

export const NUMBER_OF_SUBJECTS_AU = 8;
export const NUMBER_OF_DISCIPLINES_US = 4;

export const INTEGRATED_DISPLAY = 'Integrated';
export const MINIMUM_PASSWORD_LENGTH = 14;

// Years
/**
 * @typedef { typeof PRE_FOUNDATION_VALUE
 * | typeof FOUNDATION_VALUE
 * | typeof YEAR_1
 * | typeof YEAR_2
 * | typeof YEAR_3
 * | typeof YEAR_4
 * | typeof YEAR_5
 * | typeof YEAR_6
 * | typeof ALL_YEARS
 * } Years
 */

export const YEARS_WORD = 'years';
export const PRE_FOUNDATION_VALUE = '-1';
export const FOUNDATION_VALUE = '0';
export const YEAR_1 = '1';
export const YEAR_2 = '2';
export const YEAR_3 = '3';
export const YEAR_4 = '4';
export const YEAR_5 = '5';
export const YEAR_6 = '6';
export const ALL_YEARS = ALL_WORD;

/**
 * @type {Array<Years>}
 */
export const YEARS_RANGE = [
  PRE_FOUNDATION_VALUE,
  FOUNDATION_VALUE,
  YEAR_1,
  YEAR_2,
  YEAR_3,
  YEAR_4,
  YEAR_5,
  YEAR_6,
];

/**
 * @type {Array<Array<Years>>}
 */
export const YEARS = [
  [PRE_FOUNDATION_VALUE],
  [FOUNDATION_VALUE],
  [YEAR_1, YEAR_2],
  [YEAR_3, YEAR_4],
  [YEAR_5, YEAR_6],
  [ALL_YEARS],
];
export const ECONOMICS_YEARS = [YEAR_5, YEAR_6];
export const CIVICS_YEARS = [YEAR_3, YEAR_4, YEAR_5, YEAR_6];

export const AUTHOR_NAME = 'Inquisitive';
export const AUTHOR_TEAM = 'Inquisitive Team';

export const FLOW_NAME_PURCHASE = 'purchase';
export const FLOW_NAME_TRIAL = 'trial';
export const JOIN_FLOW_NAME = [FLOW_NAME_PURCHASE, FLOW_NAME_TRIAL];
export const GST_TAX = 'GST';
export const SALES_TAX = 'Sales Tax';

export const PLATFORM_EVENT_SF_SEND_VERIFICATION_EMAIL =
  'Send_Verification_Email__e';
export const PLATFORM_EVENT_SCHOOL_RENEWAL_EMAILS =
  'Change_School_Renewal_Email__e';
export const PLATFORM_EVENT_CHECK_SCHOOL_RENEWAL_EMAILS =
  'Check_School_Renewal_Email__e';
export const PLATFORM_EVENT_SF_SEND_EMAIL_RESET_PASSWORD =
  'Send_Email_Reset_Password__e';
export const PLATFORM_EVENT_SF_CHANGE_USER_EMAIL = 'Change_User_Email__e';
export const PLATFORM_EVENT_SF_CHANGE_USER_DUPLICATE_EMAIL =
  'Change_User_Duplicate_Email__e';
export const PLATFORM_EVENT_SF_CHANGE_USER_NAME = 'Change_User_Name__e';
export const PLATFORM_EVENT_SF_SCHOOL_ADD_TEACHER_EMAILS =
  'Change_School_Add_Teacher_Email__e';
export const PLATFORM_EVENT_SF_CHECK_SCHOOL_ADD_TEACHER_EMAILS =
  'Check_School_Add_Teacher_Email__e';
export const PLATFORM_EVENT_SF_UPSERT_SCHOOL = 'Upsert_School__e';
export const PLATFORM_EVENT_SF_EXTEND_SUBSCRIPTION = 'Extend_Subscription__e';
export const PLATFORM_EVENT_SF_CONVERT_TO_SCHOOL =
  'Convert_to_School_Account__e';
export const PLATFORM_EVENT_SF_CHANGE_RENEWAL_COUPON =
  'Change_Renewal_Coupon__e';
//export const PLATFORM_EVENT_SF_SCHOOL_CONFIRM_RENEWAL =  'School_Confirm_Renewal__e';
export const PLATFORM_EVENT_SF_LEAVE_SCHOOL = 'Leave_School__e';
export const PLATFORM_EVENT_SF_SCHOOL_CONFIRM_RENEWAL =
  'School_Confirm_Renewal__e';
export const PLATFORM_EVENT_SF_CHANGE_AUTO_RENEWAL = 'Change_Auto_Renewal__e';
export const PLATFORM_EVENT_SF_ADD_WHITELIST_DOMAIN = 'Add_Whitelist_Domain__e';
export const PLATFORM_EVENT_SF_ADD_ADMIN_USER = 'Add_Admin_User__e';
export const PLATFORM_EVENT_SF_SEND_RENEWAL_EFT_INVOICE_EMAIL =
  'Send_Renewal_Eft_Invoice_Email__e';
export const PLATFORM_EVENT_SF_SEND_ADD_TEACHER_EFT_INVOICE_EMAIL =
  'Send_Add_Teacher_Eft_Invoice_Email__e';
export const PLATFORM_EVENT_SF_SWITCH_TO_DIRECT_DEPOSIT =
  'Switch_To_Direct_Deposit__e';
export const PLATFORM_EVENT_SF_CREATE_SCHOOL_SUBSCRIPTION_BY_DISTRICT =
  'Create_School_Subscription_By_District__e';
export const PLATFORM_EVENT_SF_EMAIL_BOUNCE_RESET = 'Email_Bounce_Reset__e';
export const PLATFORM_EVENT_SF_SCHOOL_SET_RENEWAL_PO_NUMBER =
  'School_Set_Renewal_PO_Number__e';
export const PLATFORM_EVENT_SF_MOVE_SCHOOL = 'Move_School__e';

export const EVENT_SF_USER_CREATE = 'event-sf-user-create';
export const EVENT_SF_USER_UPDATE = 'event-sf-user-update';
export const EVENT_SF_SCHOOL_UPDATE = 'event-sf-school-update';
export const EVENT_SF_SUBSCRIPTION_UPDATE = 'event-sf-subscription-update';
export const EVENT_SF_SUBSCRIPTION_RENEWED = 'event-sf-subscription-renewed';
export const EVENT_SF_MEMBER_UPDATE = 'event-sf-member-update';
export const EVENT_SF_WHITELIST_DOMAIN_INSERT =
  'event-sf-whitelist-domain-insert';
export const EVENT_SF_SUBSCRIPTION_EXTENSION =
  'event-sf-subscription-extension';
export const EVENT_SF_MEMBER_DELETE = 'event-sf-member-delete';
export const EVENT_SF_ACCOUNT_UPDATE = 'event-sf-account-update';
export const EVENT_SF_INVOICE_UPDATE = 'event-sf-manual-invoice-update';
export const EVENT_SF_USER_VERIFICATION_URL =
  'event-sf-user-join-verification-url';
export const EVENT_SF_USER_STUDENTS_METRIC = 'event-sf-user-students-metric';
export const EVENT_SF_REFERRED_CONTACT_CREATE =
  'event-sf-referred-contact-create';
export const EVENT_SF_SCHOOL_INVITATION_UPSERT =
  'event-sf-school-invitation-upsert';
export const EVENT_SF_CREATE_CONTACT = 'event-create-salesforce-contact';
export const EVENT_SF_UPDATE_CONTACT = 'event-update-salesforce-contact';

export const ACTION_SCOPE_SEQUENCE_NOTIFICATION = 'action-ss-notification';
export const ACTION_TRIGGER_POST_EMAIL_VERIFICATION =
  'action-trigger-post-email-verification';
export const ACTION_TRIGGER_NIL = 'action-trigger-nil';
// Background Jobs
export const JOB_DO_ACTION = 'job-do-action';
export const JOB_ELASTICSEARCH_INDEX = 'job-elasticsearch-index';
export const JOB_INVITE_EXISTING_USER_TO_SCHOOL_ADMIN =
  'job-invite-existing-user-to-school-admin';
export const JOB_AI_DICTIONARY_GENERATE = 'job-ai-dictionary-generate';
export const JOB_INVITE_TO_JOIN_SCHOOL = 'job-invite-to-join-school';
export const JOB_ADMIN_TO_JOIN_SCHOOL = 'job-admin-to-join-school';
export const JOB_SEND_VERIFICATION_REMINDER_EMAIL =
  'job-send-verification_reminder-email';
export const JOB_OVERDUE_MANUAL_INVOICE = 'job-overdue-manual-invoice';
export const JOB_PRERENDER_RECACHE = 'job-prerender-recache';
export const JOB_SHARED_PLANNER_UPDATE = 'job-planner-update';
export const JOB_RENEWAL_EXPIRING_ACCOUNT_REMINDER =
  'job-renewal-expiring-account-reminder';
export const JOB_REQUEST_TO_JOIN_SCHOOL = 'job-request-to-join-school';
export const JOB_SEND_EMAIL_UPDATE_CONFIRMATION =
  'job-send-email-update-confirmation';
export const JOB_SEND_EMAIL_ACCOUNT_REACTIVATION =
  'job-send-email-account-reactivated';
export const JOB_SEND_EMAIL_ACCOUNT_ACTIVATED =
  'job-send-email-account-activated';
export const JOB_SEND_EMAIL_ACCOUNT_EXPIRED = 'job-send-email-account-expired';
export const JOB_SEND_EMAIL_ACCOUNT_VERIFICATION =
  'job-send-email-account-verification';
export const JOB_SEND_EMAIL_CREDIT_EARNED = 'job-send-email-credit-earned';
export const JOB_SEND_EMAIL_REFERRAL = 'job-send-email-referral';
export const JOB_SEND_EMAIL_INVOICE = 'job-send-email-invoice';
export const JOB_SEND_EMAIL_INVOICE_DUE = 'job-send-email-invoice-due';
export const JOB_SEND_EMAIL_INVOICE_FOR_PAYMENT =
  'job-send-email-invoice-for-payment';
export const JOB_SEND_EMAIL_INVOICE_RECEIPT = 'job-send-email-invoice-receipt';
export const JOB_SEND_EMAIL_REMOVE_FROM_SCHOOL =
  'job-send-email-remove-from-school';
export const JOB_SEND_EMAIL_RESET_PASSWORD = 'job-send-email-reset-password';
export const JOB_SEND_EMAIL_SUPPORT_ADDING_EXISTING_EMAIL =
  'job-send-email-support-adding-existing-email';
export const JOB_SEND_EMAIL_SUPPORT_MANUAL_INVOICE_EXISTING_ACCOUNT =
  'job-send-email-support-manual-invoice-existing-account';
export const JOB_SEND_EMAIL_SUPPORT_SCHOOL_ACCOUNT_UPGRADE =
  'job-send-email-support-school-account-upgrade';
export const JOB_SEND_EMAIL_WELCOME_SSO_USER =
  'job-send-email-welcome-sso-user';
export const JOB_SEND_EMAIL_XERO_INVOICE_CREATED =
  'job-send-email-xero-invoice-created';
export const JOB_SEND_EMAIL_RENEWED_BY_CREDIT_CARD =
  'job-send-email-renewed-by-credit-card';
export const JOB_SEND_EMAIL_RENEWED_BY_DIRECT_DEPOSIT =
  'job-send-email-renewed-by-direct-deposit';
export const JOB_SEND_EMAIL_BOUNCED_EMAIL_NOTIFICATION =
  'job-send-email-bounced-email-notification';
export const JOB_SEND_EMAIL_AUTO_RENEWAL_OFF =
  'job-send-email-auto-renewal-off';
export const JOB_SEND_EMAIL_VERIFICATION_FEDERAL_GRANT =
  'job-send-email-verification-federal-grant';
export const JOB_SEND_EMAIL_CONFIRMED_RENEWAL =
  'job-send-email-confirmed-renewal';
export const JOB_SEND_EMAIL_SHARE_CLASS_INVITE =
  'job-send-email-share_class_invite';
export const JOB_S3_ASSETS_DELETE = 'job-s3-assets-delete';
export const JOB_NOTIFY_RENEWAL_UPDATE = 'job-notify-renewal-update';
export const JOB_UPDATE_STRIPE_CONTACT = 'job-update-stripe-contact';
export const JOB_UPDATE_STRIPE_EMAIL = 'job-update-stripe-email';
export const JOB_XERO_APPROVED_INVOICE_UPDATED =
  'job-xero-approved-invoice-updated';
export const JOB_XERO_INVOICE_UPDATED = 'job-xero-invoice-updated';
export const JOB_XERO_CONTACT_UPDATED = 'job-xero-contact-updated';
export const JOB_XERO_WEBHOOK_REQUEST_FORWARD =
  'job-xero-webhook-request-forward';
export const JOB_UPDATE_PLANNER_AFTER_COMING_SOON_UNIT_PUBLISHED =
  'job-update-planner-after-coming-soon-unit-published';
export const JOB_DONE = 'done';
export const JOB_IGNORED = 'ignored';
export const JOB_UPDATE_STIMULUS_ATTACHMENT = 'job-update-stimulus-attachment';
export const JOB_CURRICULUM_UPDATE = 'job-outcome-update';
export const JOB_YEARS_UPDATE = 'job-subject-years-update';
export const JOB_UNIT_STATUS_UPDATE = 'job-unit-status-update';
export const JOB_XERO_MARK_INVOICE_PAID = 'job-xero-mark-invoice-paid';
export const JOB_DOCX_PDF_CONVERSION_EMAIL = 'job-docx-pdf-conversion-email';
export const JOB_AI_DICTIONARY_SUMMARY = 'job-ai-dictionary-summary';

export const JOB_TYPES = [
  JOB_DO_ACTION,
  JOB_UNIT_STATUS_UPDATE,
  JOB_CURRICULUM_UPDATE,
  JOB_YEARS_UPDATE,
  JOB_UPDATE_STIMULUS_ATTACHMENT,
  JOB_SHARED_PLANNER_UPDATE,
  JOB_ELASTICSEARCH_INDEX,
  JOB_INVITE_EXISTING_USER_TO_SCHOOL_ADMIN,
  JOB_AI_DICTIONARY_GENERATE,
  JOB_INVITE_TO_JOIN_SCHOOL,
  JOB_SEND_VERIFICATION_REMINDER_EMAIL,
  JOB_OVERDUE_MANUAL_INVOICE,
  JOB_PRERENDER_RECACHE,
  JOB_RENEWAL_EXPIRING_ACCOUNT_REMINDER,
  JOB_REQUEST_TO_JOIN_SCHOOL,
  JOB_SEND_EMAIL_UPDATE_CONFIRMATION,
  JOB_SEND_EMAIL_ACCOUNT_REACTIVATION,
  JOB_SEND_EMAIL_ACCOUNT_ACTIVATED,
  JOB_SEND_EMAIL_ACCOUNT_EXPIRED,
  JOB_SEND_EMAIL_ACCOUNT_VERIFICATION,
  JOB_SEND_EMAIL_CREDIT_EARNED,
  JOB_SEND_EMAIL_REFERRAL,
  JOB_SEND_EMAIL_INVOICE,
  JOB_SEND_EMAIL_INVOICE_DUE,
  JOB_SEND_EMAIL_INVOICE_FOR_PAYMENT,
  JOB_SEND_EMAIL_INVOICE_RECEIPT,
  JOB_SEND_EMAIL_REMOVE_FROM_SCHOOL,
  JOB_SEND_EMAIL_RENEWED_BY_CREDIT_CARD,
  JOB_SEND_EMAIL_RENEWED_BY_DIRECT_DEPOSIT,
  JOB_SEND_EMAIL_RESET_PASSWORD,
  JOB_SEND_EMAIL_SUPPORT_ADDING_EXISTING_EMAIL,
  JOB_SEND_EMAIL_SUPPORT_MANUAL_INVOICE_EXISTING_ACCOUNT,
  JOB_SEND_EMAIL_SUPPORT_SCHOOL_ACCOUNT_UPGRADE,
  JOB_SEND_EMAIL_WELCOME_SSO_USER,
  JOB_SEND_EMAIL_XERO_INVOICE_CREATED,
  JOB_SEND_EMAIL_BOUNCED_EMAIL_NOTIFICATION,
  JOB_SEND_EMAIL_AUTO_RENEWAL_OFF,
  JOB_SEND_EMAIL_VERIFICATION_FEDERAL_GRANT,
  JOB_SEND_EMAIL_CONFIRMED_RENEWAL,
  JOB_SEND_EMAIL_SHARE_CLASS_INVITE,
  JOB_S3_ASSETS_DELETE,
  JOB_NOTIFY_RENEWAL_UPDATE,
  JOB_UPDATE_STRIPE_EMAIL,
  JOB_UPDATE_STRIPE_CONTACT,
  JOB_XERO_APPROVED_INVOICE_UPDATED,
  JOB_XERO_INVOICE_UPDATED,
  JOB_XERO_CONTACT_UPDATED,
  JOB_XERO_WEBHOOK_REQUEST_FORWARD,
  JOB_UPDATE_PLANNER_AFTER_COMING_SOON_UNIT_PUBLISHED,
  JOB_XERO_MARK_INVOICE_PAID,
  JOB_DOCX_PDF_CONVERSION_EMAIL,
  JOB_AI_DICTIONARY_SUMMARY,
];
export const AI_JOB_PENDING = 'pending';
export const AI_JOB_DONE = 'done';

export const AI_JOB_ASSISTANT = 'ai-job-assistant';
export const AI_JOB_TEACHING_NOTES = 'job-ai-teaching-notes';

export const AI_JOB_TYPES = [AI_JOB_ASSISTANT, AI_JOB_TEACHING_NOTES];

// Email Jobs
export const EMAIL_JOB_ACCOUNT_RENEWED = 'account renewed';
export const EMAIL_JOB_DIRECT_DEPOSIT_RENEWAL = 'direct deposit renewal';
export const EMAIL_JOB_DIRECT_DEPOSIT_DUE_REMINDER =
  'direct deposit due reminder';
export const EMAIL_JOB_DIRECT_DEPOSIT_OVERDUE = 'direct deposit overdue';
export const EMAIL_JOB_EXPIRED_ACCOUNT = 'expired account';
export const EMAIL_JOB_FAILED_PAYMENT = 'failed payment';
export const EMAIL_JOB_FAILED_PAYMENT_FOLLOW_UP = 'failed payment follow up';
export const EMAIL_JOB_RENEWAL_DETAILS_UPDATED = 'renewal details updated';
export const EMAIL_JOB_RENEWAL_REMINDER_CONFIRMED_SCHOOL =
  'renewal reminder confirmed school';
export const EMAIL_JOB_RENEWAL_REMINDER_INDIVIDUAL =
  'renewal reminder individual';
export const EMAIL_JOB_RENEWAL_REMINDER_UNCONFIRMED_SCHOOL =
  'renewal reminder unconfirmed school';
export const EMAIL_JOB_REQUEST_ACCOUNT_REACTIVATION =
  'request account reactivation';
export const EMAIL_JOB_RESET_PASSWORD = 'reset password';
export const EMAIL_JOB_ACCOUNT_REACTIVATED = 'account reactivated';
export const EMAIL_JOB_ACCOUNT_ACTIVATED = 'account activated';
export const EMAIL_JOB_SCHOOL_ACCOUNT_ADMIN_ACTIVATED =
  'school account admin activated';
export const EMAIL_JOB_REQUEST_TO_JOIN_SCHOOL = 'request to join school';
export const EMAIL_JOB_BOUNCED_EMAIL_ADMIN_NOTIFICATION =
  'bounced email notification';
export const EMAIL_JOB_REFERRAL_EMAIL = 'referral';
export const EMAIL_JOB_JOIN_VERIFICATION = 'join verification';
export const EMAIL_JOB_SHARE_CLASS = 'email job share class';
export const EMAIL_JOB_JOIN_VERIFICATION_FEDERAL_GRANT =
  'join verification federal grant';
export const EMAIL_JOB_VERIFICATION_REMINDER = 'verification reminder';
export const EMAIL_JOB_CUSTOMISED_SCOPE_AND_SEQUENCE_REMINDER =
  'customised scope and sequence reminder';
export const EMAIL_JOB_TEACHING_NOTES_READY = 'teaching notes ready';
export const EMAIL_JOB_AUTO_RENEWAL_OFF = 'auto renewal off';
export const EMAIL_JOB_VALIDATE_LINKS = 'validate links';
export const EMAIL_JOB_ON_BOARDING_ADMIN = 'on boarding admin';
export const EMAIL_JOB_ON_BOARDING_TEACHER = 'on boarding teacher';
export const EMAIL_JOB_ON_BOARDING_ADMIN_TEACHER = 'on boarding admin teacher';
export const EMAIL_JOB_ADMIN_LOGIN = 'admin login';
export const EMAIL_JOB_INVOICE = 'invoice';
export const EMAIL_JOB_INVOICE_RECEIPT = 'invoice receipt';
export const EMAIL_JOB_WELCOME_SSO_USER = 'welcome sso user';
export const EMAIL_JOB_UPDATE_EMAIL_REQUEST = 'update email request';
export const EMAIL_JOB_SEND_SCHOOL_INVITATION = 'send school invitation';
export const EMAIL_JOB_SEND_LESSON_PDF = 'send lesson pdf';
export const EMAIL_JOB_SEND_UNIT_PROGRAM_PDF = 'send unit program pdf';
export const EMAIL_JOB_CREDIT_EARNED = 'credit earned';
// Email job types
export const EMAIL_JOB_TYPES = [
  { job: EMAIL_JOB_ACCOUNT_RENEWED, reference: 'Email-0001' },
  { job: EMAIL_JOB_DIRECT_DEPOSIT_RENEWAL, reference: 'Email-0002' },
  { job: EMAIL_JOB_DIRECT_DEPOSIT_DUE_REMINDER, reference: 'Email-0003' },
  { job: EMAIL_JOB_DIRECT_DEPOSIT_OVERDUE, reference: 'Email-0004' },
  { job: EMAIL_JOB_EXPIRED_ACCOUNT, reference: 'Email-0005' },
  { job: EMAIL_JOB_FAILED_PAYMENT, reference: 'Email-0006' },
  { job: EMAIL_JOB_FAILED_PAYMENT_FOLLOW_UP, reference: 'Email-0007' },
  { job: EMAIL_JOB_RENEWAL_DETAILS_UPDATED, reference: 'Email-0008' },
  { job: EMAIL_JOB_RENEWAL_REMINDER_CONFIRMED_SCHOOL, reference: 'Email-0009' },
  { job: EMAIL_JOB_RENEWAL_REMINDER_INDIVIDUAL, reference: 'Email-0010' },
  {
    job: EMAIL_JOB_RENEWAL_REMINDER_UNCONFIRMED_SCHOOL,
    reference: 'Email-0011',
  },
  { job: EMAIL_JOB_REQUEST_ACCOUNT_REACTIVATION, reference: 'Email-0012' },
  { job: EMAIL_JOB_RESET_PASSWORD, reference: 'Email-0013' },
  { job: EMAIL_JOB_ACCOUNT_REACTIVATED, reference: 'Email-0014' },
  { job: EMAIL_JOB_ACCOUNT_ACTIVATED, reference: 'Email-0015' },
  { job: EMAIL_JOB_SCHOOL_ACCOUNT_ADMIN_ACTIVATED, reference: 'Email-0016' },
  { job: EMAIL_JOB_REQUEST_TO_JOIN_SCHOOL, reference: 'Email-0017' },
  { job: EMAIL_JOB_BOUNCED_EMAIL_ADMIN_NOTIFICATION, reference: 'Email-0018' },
  { job: EMAIL_JOB_REFERRAL_EMAIL, reference: 'Email-0019' },
  { job: EMAIL_JOB_JOIN_VERIFICATION, reference: 'Email-0021' },
  { job: EMAIL_JOB_VERIFICATION_REMINDER, reference: 'Email-0022' },
  { job: EMAIL_JOB_AUTO_RENEWAL_OFF, reference: 'Email-0023' },
  { job: EMAIL_JOB_VALIDATE_LINKS, reference: 'Email-0024' },
  { job: EMAIL_JOB_ON_BOARDING_ADMIN, reference: 'Email-0025' },
  { job: EMAIL_JOB_ON_BOARDING_TEACHER, reference: 'Email-0026' },
  { job: EMAIL_JOB_ON_BOARDING_ADMIN_TEACHER, reference: 'Email-0027' },
  { job: EMAIL_JOB_ADMIN_LOGIN, reference: 'Email-0028' },
  { job: EMAIL_JOB_INVOICE, reference: 'Email-0029' },
  { job: EMAIL_JOB_INVOICE_RECEIPT, reference: 'Email-0030' },
  { job: EMAIL_JOB_WELCOME_SSO_USER, reference: 'Email-0031' },
  { job: EMAIL_JOB_UPDATE_EMAIL_REQUEST, reference: 'Email-0032' },
  { job: EMAIL_JOB_SEND_SCHOOL_INVITATION, reference: 'Email-0033' },
  {
    job: EMAIL_JOB_JOIN_VERIFICATION_FEDERAL_GRANT,
    reference: 'Email-0034-Grant',
  },
  {
    job: EMAIL_JOB_CUSTOMISED_SCOPE_AND_SEQUENCE_REMINDER,
    reference: 'Email-0040',
  },
  { job: EMAIL_JOB_SHARE_CLASS, reference: 'Email-0050' },
  { job: EMAIL_JOB_SEND_LESSON_PDF, reference: 'Email-0035' },
  { job: EMAIL_JOB_CREDIT_EARNED, reference: 'Email-0036' },
  { job: EMAIL_JOB_SEND_UNIT_PROGRAM_PDF, reference: 'Email-0037' },
  { job: EMAIL_JOB_TEACHING_NOTES_READY, reference: 'Email-0038' },
];

// Disabled Email type
export const DISABLED_ADD_TEACHER_EMAIL_JOB = 'disable-add-teacher-email-job';
export const DISABLED_RENEWAL_EMAIL_JOB = 'disable-renewal-email-job';
export const DISABLED_EMAIL_JOB_TYPES = [
  DISABLED_ADD_TEACHER_EMAIL_JOB,
  DISABLED_RENEWAL_EMAIL_JOB,
];

// Backend Feature Flags
export const FEATURE_FLAG_PATH = '/feature-flag/';
export const FEATURE_FLAG_RENEWAL_REMINDER = 'renewal-reminder';

// Stripe
export const STRIPE_AUS_GST_TAX_ID_DEV = 'txr_1HPSxbDCojhLojQllXpMw1gj';
export const STRIPE_AUS_GST_TAX_ID = 'txr_1HZCU0DCojhLojQlp9jy3SJe';
export const STRIPE_BILLING_AUTO = 'charge_automatically';
export const STRIPE_BILLING_MANUAL = 'send_invoice';
export const STRIPE_BILLING_NO_INVOICE = 'no_invoice';
export const STRIPE_BILLING_REASON_SUBSCRIPTION_CREATED = 'subscription_create';
export const STRIPE_BILLING_REASON_SUBSCRIPTION_CYCLE = 'subscription_cycle';
export const STRIPE_BILLING_REASON_SUBSCRIPTION_UPDATE = 'subscription_update';
export const STRIPE_BILLING_REASON_MANUAL = 'manual';
export const STRIPE_INVOICE_STATUS_OPEN = 'open';
export const STRIPE_REFERRAL_COUPON_DESCRIPTION = '(with $9.09 off)';
export const STRIPE_WHOLE_SCHOOL_COUPON_DESCRIPTION = '(with 25.0% off)';
export const STRIPE_PERCENT_OFF_COUPON_DESCRIPTION = '% off)';
export const STRIPE_DAYS_DUE = 30;
export const STRIPE_SUBSCRIPTION_STATUS_ACTIVE = 'active';

// Stripe webhook events
export const STRIPE_WEBHOOK_EVENT_INVOICE_CREATED = 'invoice.created';
export const STRIPE_WEBHOOK_EVENT_INVOICE_FINALIZED = 'invoice.finalized';
export const STRIPE_WEBHOOK_EVENT_INVOICE_PAYMENT_SUCCEEDED =
  'invoice.payment_succeeded';
export const STRIPE_WEBHOOK_EVENT_INVOICE_PAYMENT_FAILED =
  'invoice.payment_failed';
export const STRIPE_WEBHOOK_EVENT_SUBSCRIPTION_DELETED =
  'customer.subscription.deleted';
export const STRIPE_WEBHOOK_EVENT_SUBSCRIPTION_UPDATED =
  'customer.subscription.updated';

// Stripe error codes
export const STRIPE_ERROR = 'STRIPEERROR';
export const STRIPE_ERROR_TYPE_CARD_ERROR = 'card_error';
export const STRIPE_ERROR_CODE_CARD_DECLINED = 'card_declined';

// amount of credit a customer gets from a successful referral (cents)
export const REFEREE_CREDIT_AMOUNT = 2000; // $20
export const REFERRAL_COUPON_AMOUNT_AU = 1818; // $20 - GST
export const REFERRAL_COUPON_AMOUNT_US = 2000; // $20 ex Sales Tax

// legacy whole school coupon code - use by very old invoice
export const WHOLE_SCHOOL_COUPON = 'WHOLE25OFF';
export const WHOLE_SCHOOL_DISCOUNT = 0.75;

// price rise control
export const AU_2024_PRICE_RISE_DATE = new Date('2024-10-03 14:00:00'); // UTC time

// whole school free access Control
export const WHOLE_SCHOOL_FREE_ACCESS_DATE = '2025-04-30 14:00:00'; // UTC time
export const WHOLE_SCHOOL_FREE_ACCESS_THRESHOLD_IN_MONTHS = 3; // number of months before renewal to get free access for all teachers
export const WHOLE_SCHOOL_FREE_ACCESS_THRESHOLD_IN_MONTHS_DEV = 3; // number of months before renewal to get free access for all teachers

// Renewal
export const RENEWAL_MANAGEMENT_AVAILABLE_THRESHOLD_IN_DAYS = 21; //21; // number of days before renewal management is available
export const RENEWAL_MANAGEMENT_AVAILABLE_THRESHOLD_IN_DAYS_DEV = 21; //366; // number of days before renewal management is available
export const RENEWAL_MANAGEMENT_MAX_QUANTITY_REDUCTION = 5; // max number of memberships reduction allowed once confirmed renewal

// Remind the user to create a new class for next year 2 month before and after a the end of term 4
export const NEW_CLASS_CREATION_REMINDER_TIME_SPAN_PROD = 2;
export const NEW_CLASS_CREATION_REMINDER_TIME_SPAN_DEV = 2;
export const NEW_CLASS_CREATION_REMINDER_TIME_FRAME_PROD = 'M'; // Months
export const NEW_CLASS_CREATION_REMINDER_TIME_FRAME_DEV = 'M'; //'d'; // Days

export const SHARE_CLASS_PERMISSION_OWNER = 'owner';
export const SHARE_CLASS_PERMISSION_FULL_ACCESS_TEACHER = 'teacher';

export const SHARE_CLASS_PERMISSIONS = [
  SHARE_CLASS_PERMISSION_OWNER,
  SHARE_CLASS_PERMISSION_FULL_ACCESS_TEACHER,
];

/**
 * @typedef {typeof SHARE_CLASS_PERMISSION_OWNER
 * | typeof SHARE_CLASS_PERMISSION_FULL_ACCESS_TEACHER
 * } ShareClassPermission
 */
export const REGION_AU = 'au';
export const REGION_US = 'us';
export const REGIONS = [REGION_AU];

// Resources
export const SUBSCRIPTION = 'Subscription';
export const SCHOOL = 'School';
// Public website
export const INQUISITIVE_SITE_NAME = 'www.inquisitive.com';
export const INQUISITIVE_SITE = 'https://www.inquisitive.com/';
export const NEXT_INQUISITIVE_SITE_NAME = 'next.inquisitive.com';
export const PREVIOUS_INQUISITIVE_SITE_NAME = 'previous.inquisitive.com';
export const SYDNEY_INQUISITIVE_SITE_NAME = 'sydney.inquisitive.com';

// Social Network Resources
export const INQUISITIVE_FACEBOOK = 'https://www.facebook.com/inquisitivehq/';
export const INQUISITIVE_FACEBOOK_US =
  'https://www.facebook.com/inquisitive.us/';
export const INQUISITIVE_INSTAGRAM =
  'https://www.instagram.com/inquisitive_hq/';
export const INQUISITIVE_INSTAGRAM_US =
  'https://www.instagram.com/inquisitive.us/';
export const INQUISITIVE_LINKEDIN =
  'https://www.linkedin.com/company/inquisitive/';
export const INQUISITIVE_TWITTER_HANDLE = 'inquisitive_hq';
export const INQUISITIVE_TWITTER =
  'https://twitter.com/' + INQUISITIVE_TWITTER_HANDLE + '/';

export const INQUISITIVE_TWITTER_CARD_TYPE = 'summary_large_image';

export const INQUISITIVE_YOUTUBE = 'http://www.youtube.com/c/Inquisitive_hq/';

// Support Email
export const BCC_AUTOMATED_EMAIL_AU = 'automated-email@inquisitive.com';
export const BCC_AUTOMATED_EMAIL_US = 'automated-us@inquisitive.com';
export const BCC_DEV_TEST_EMAIL = 'test@inquisitive.com';
export const SUPPORT_EMAIL_AU = 'support@inquisitive.com';
export const SUPPORT_EMAIL_US = 'support.us@inquisitive.com';
export const ACCOUNTS_EMAIL = 'accounts@inquisitive.com';
export const ACCOUNTS_EMAIL_US = 'accounts.us@inquisitive.com';

// Address
export const INQUISITIVE_ADDRESS = [
  'Building 8, 1110 Middle Head Road',
  'Mosman, 2088, New South Wales, Australia',
];

export const INQUISITIVE_ADDRESS_US = [
  '2202 18th Street NW',
  'Suite 440',
  'Washington DC 20009',
];

// Intercom IT Support
export const INTERCOM_IT_SUPPORT_URL_AU =
  'https://au.intercom.help/inquisitive-au/en/articles/2417-it-support-with-inquisitive';
export const INTERCOM_IT_SUPPORT_URL_US =
  'https://intercom.help/inquisitive-0452f0b96001/en/articles/8585876-it-support-inquisitive-us';

// Google Sign In Help Link
export const INTERCOM_GOOGLE_SIGNIN_HELP_LINK_AU =
  'https://au.intercom.help/inquisitive-au/en/articles/40814-enabling-inquisitive-in-google-workspace-for-education';
export const INTERCOM_GOOGLE_SIGNIN_HELP_LINK_US =
  'https://intercom.help/inquisitive-0452f0b96001/en/articles/10408988-enabling-inquisitive-in-google-workspace-for-education';

export const INTERCOM_STUDENT_ACCESS_AU =
  'https://au.intercom.help/inquisitive-au/en/collections/53992-student-access-to-inquisitive';
export const INTERCOM_STUDENT_ACCESS_US =
  'https://intercom.help/inquisitive-0452f0b96001/en/collections/11387315-student-access-to-inquisitive';

// Subscription States
// Mirroring those of Stripe: https://stripe.com/docs/api#subscription_object-status
export const TRIALING = 'trialing';
export const ACTIVE = 'active';
export const CANCELED = 'canceled';
export const PAST_DUE = 'past_due';
export const UNPAID = 'unpaid';

// Trial Subscription Duration
export const SCHOOL_TRIAL_DURATION_DAYS = 90;
export const INDIVIDUAL_TRIAL_DURATION_DAYS = 30;

//Regionalised Labels:
export const FOCUS_QUESTION_REGIONALISED_LABEL = 'focusQuestion';
export const PHENOMENA_REGIONALISED_LABEL = 'phenomena';
export const OUTCOMES_REGIONALISED_LABEL = 'outcomes';
export const PERFORMANCE_EXPECTATIONS_REGIONALISED_LABEL =
  'performanceExpectations';
export const DISCIPLINES_REGIONALISED_LABEL = 'disciplines';
export const CONNECTIONS_REGIONALISED_LABEL = 'connections';
export const CROSS_CURRICULUM_REGIONALISED_LABELS = 'crossCurriculumPriorities';

/**
 * @typedef {typeof FOCUS_QUESTION_REGIONALISED_LABEL
 * | typeof PHENOMENA_REGIONALISED_LABEL
 * | typeof OUTCOMES_REGIONALISED_LABEL
 * | typeof PERFORMANCE_EXPECTATIONS_REGIONALISED_LABEL
 * | typeof DISCIPLINES_REGIONALISED_LABEL
 * | typeof CONNECTIONS_REGIONALISED_LABEL
 * | typeof CROSS_CURRICULUM_REGIONALISED_LABELS
 * } RegionalisedLabels
 */

/**
 * @type {Array<RegionalisedLabels>}
 * */
export const REGIONAISED_LABELS = [
  FOCUS_QUESTION_REGIONALISED_LABEL,
  PHENOMENA_REGIONALISED_LABEL,
  OUTCOMES_REGIONALISED_LABEL,
  PERFORMANCE_EXPECTATIONS_REGIONALISED_LABEL,
  DISCIPLINES_REGIONALISED_LABEL,
  CONNECTIONS_REGIONALISED_LABEL,
  CROSS_CURRICULUM_REGIONALISED_LABELS,
];

/**
 * @typedef {'Mrs' | 'Miss' | 'Ms' | 'Mr' | 'Doctor' | 'Sir' | 'Mx' | 'Misc'} Title
 */
export const TITLES = [
  'Mrs',
  'Miss',
  'Ms',
  'Mr',
  'Doctor',
  'Sir',
  'Mx',
  'Misc',
];

// Join flow plans
export const JOIN_FLOW_PLAN_TEACHER = 'teacher';
export const JOIN_FLOW_PLAN_WHOLE_SCHOOL = 'whole-school';

/**
 * @typedef {typeof JOIN_FLOW_PLAN_TEACHER
 * | typeof JOIN_FLOW_PLAN_WHOLE_SCHOOL
 * } JoinFlowPlan
 */

/**
 * @type {Array<JoinFlowPlan>}
 */
export const JOIN_FLOW_PLANS = [
  JOIN_FLOW_PLAN_TEACHER,
  JOIN_FLOW_PLAN_WHOLE_SCHOOL,
];

// Payment Method
export const PAYMENT_METHOD_CREDIT_CARD = 'Credit Card';
export const PAYMENT_METHOD_DIRECT_DEPOSIT = 'Direct Deposit';
export const PAYMENT_METHOD_BLANK = 'No payment';
export const PAYMENT_METHOD_CREDIT_CARD_WORDING = PAYMENT_METHOD_CREDIT_CARD;

/**
 * @typedef {typeof PAYMENT_METHOD_CREDIT_CARD
 * | typeof PAYMENT_METHOD_DIRECT_DEPOSIT
 * | typeof PAYMENT_METHOD_BLANK
 * } PaymentMethods
 */

/**
 * @type {Array<PaymentMethods>}
 * */
export const PAYMENT_METHODS = [
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_DIRECT_DEPOSIT,
  PAYMENT_METHOD_BLANK,
];

// map with Stripe values
export const CREDIT_CARD_BRANDS = {
  visa: 'Visa',
  mastercard: 'MasterCard',
  americanexpress: 'American Express',
};

// Who is paying
export const WHOIS_PAYINGS = ['You as an individual teacher'];

// Currency
export const AU_CURRENCY = 'AUD';
export const US_CURRENCY = 'USD';

// Manual Invoices
export const MANUAL_INVOICE_TYPE_INVOICE = 'invoice';
export const MANUAL_INVOICE_TYPE_QUOTE = 'quote';
export const MANUAL_INVOICE_TYPES = [
  MANUAL_INVOICE_TYPE_INVOICE,
  MANUAL_INVOICE_TYPE_QUOTE,
];
export const MANUAL_INVOICE_STATUS_APPROVED = 'approved';
export const MANUAL_INVOICE_STATUS_CREATED = 'created';
export const MANUAL_INVOICE_STATUS_DELETED = 'deleted';
export const MANUAL_INVOICE_STATUS_DRAFT = 'draft';
export const MANUAL_INVOICE_STATUS_PAID = 'paid';
export const MANUAL_INVOICE_STATUS_VOID = 'void';
export const MANUAL_INVOICE_STATUSES = [
  MANUAL_INVOICE_STATUS_APPROVED,
  MANUAL_INVOICE_STATUS_CREATED,
  MANUAL_INVOICE_STATUS_DELETED,
  MANUAL_INVOICE_STATUS_DRAFT,
  MANUAL_INVOICE_STATUS_PAID,
  MANUAL_INVOICE_STATUS_VOID,
];
export const MANUAL_INVOICE_DEFAULT_CURRENCY = 'aud';
export const MANUAL_INVOICE_DEFAULT_FEE = 1500; // $15 in cents
export const MANUAL_INVOICE_DEFAULT_FEE_US = 0;
export const MANUAL_INVOICE_DAYS_TO_PAY = 60;
export const MANUAL_INVOICE_MAX_ADMIN_EMAILS = 5; // allowed admin emails on setup
export const MANUAL_INVOICE_BILLING_REASON_ADD_MEMBERSHIPS = 'add-memberships';
export const MANUAL_INVOICE_BILLING_REASON_NEW_PURCHASE = 'new-purchase';
export const MANUAL_INVOICE_BILLING_REASON_RENEWAL = 'renewal';
export const MANUAL_INVOICE_BILLING_REASON_TRIAL_CONVERSION =
  'trial-conversion';
export const MANUAL_INVOICE_BILLING_REASONS = [
  MANUAL_INVOICE_BILLING_REASON_ADD_MEMBERSHIPS,
  MANUAL_INVOICE_BILLING_REASON_NEW_PURCHASE,
  MANUAL_INVOICE_BILLING_REASON_RENEWAL,
  MANUAL_INVOICE_BILLING_REASON_TRIAL_CONVERSION,
];

export const BILLING_REASON_RENEWAL = 'renewal';
export const BILLING_REASON_ADD_MEMBERSHIPS = 'add-memberships';
export const BILLING_REASON_REMOVE_MEMBERSHIPS = 'remove-memberships';
export const BILLING_REASON_NEW_PURCHASE = 'new-purchase';
export const BILLING_REASON_TRIAL_CONVERSION = 'trial-conversion';

// Xero
export const XERO_ACCOUNT_CODE_NEW_PURCHASE = '200';
export const XERO_ACCOUNT_CODE_RENEWALS = '210';
export const XERO_ACCOUNT_CODE_PAID_ON_STRIPE = '215';
export const XERO_ACCOUNT_CODE_PROCESSING_FEE = '230';
export const XERO_INVOICE_FEE_DESCRIPTION =
  'Administration Fee for Payment by Invoice (EFT)';
export const XERO_INVOICE_FEE_DESCRIPTION_US =
  'Administration Fee for Payment by Direct Deposit';
export const XERO_INVOICE_MEMBERSHIP_DESCRIPTION =
  '12 month Inquisitive Teacher memberships';
export const XERO_INVOICE_STATUS_AUTHORISED = 'AUTHORISED';
export const XERO_INVOICE_STATUS_DELETED = 'DELETED';
export const XERO_INVOICE_STATUS_DRAFT = 'DRAFT';
export const XERO_INVOICE_STATUS_PAID = 'PAID';
export const XERO_INVOICE_STATUS_VOIDED = 'VOIDED';
// Xero OAuth
export const XERO_OAUTH_IDENTITY_URL = 'https://identity.xero.com';
export const XERO_OAUTH_REDIRCT_URI = 'https://inquisitive.com/xero';
export const XERO_OAUTH_SCOPES = [
  'openid',
  'profile',
  'email',
  'accounting.transactions',
  'offline_access',
  'accounting.contacts',
];

// User Positions
export const PRINCIPAL = 'Principal';
export const DEPUTY_OR_VICE_PRINCIPAL = 'Deputy or Vice Principal';
export const ASSISTANT_PRINCIPAL = 'Assistant Principal';
export const CURRICULUM_COORDINATOR = 'Curriculum Coordinator';
export const HEAD_OF_SUBJECT_OR_DEPARTMENT_CHAIR =
  'Head of Subject or Department Chair';
export const CLASSROOM_TEACHER = 'Classroom Teacher';
export const LIBRARIAN = 'Librarian';
export const OFFICE_ADMINISTRATIVE_ASSISTANT =
  'Office/Administrative Assistant';
export const INSTRUCTIONAL_COACH_LEADER = 'Instructional Coach/Leader';
export const TECHNOLOGY_COORDINATOR = 'Technology Coordinator';
export const TITLE_I_COORDINATOR = 'Title I Coordinator';
export const OTHER = 'Other';
export const CASUAL = 'Casual Teacher';
export const PRE_SERVICE = 'Pre-Service Teacher';
export const HOMESCHOOL = 'Homeschool Educator';

/**
 * @typedef {typeof PRINCIPAL
 * | typeof DEPUTY_OR_VICE_PRINCIPAL
 * | typeof ASSISTANT_PRINCIPAL
 * | typeof CURRICULUM_COORDINATOR
 * | typeof HEAD_OF_SUBJECT_OR_DEPARTMENT_CHAIR
 * | typeof CLASSROOM_TEACHER
 * | typeof LIBRARIAN
 * | typeof OFFICE_ADMINISTRATIVE_ASSISTANT
 * | typeof INSTRUCTIONAL_COACH_LEADER
 * | typeof TECHNOLOGY_COORDINATOR
 * | typeof TITLE_I_COORDINATOR
 * | typeof CASUAL
 * | typeof PRE_SERVICE
 * | typeof HOMESCHOOL
 * | typeof OTHER
 * } Position
 */

/**
 * @type {Array<Position>}
 */

export const POSITIONS = [
  ASSISTANT_PRINCIPAL,
  CASUAL,
  CLASSROOM_TEACHER,
  CURRICULUM_COORDINATOR,
  DEPUTY_OR_VICE_PRINCIPAL,
  HEAD_OF_SUBJECT_OR_DEPARTMENT_CHAIR,
  HOMESCHOOL,
  INSTRUCTIONAL_COACH_LEADER,
  LIBRARIAN,
  OFFICE_ADMINISTRATIVE_ASSISTANT,
  OTHER,
  PRE_SERVICE,
  PRINCIPAL,
  TECHNOLOGY_COORDINATOR,
  TITLE_I_COORDINATOR,
];

export const SEARCH_URL_PARAM_INPUT = 'input';
export const SEARCH_URL_PARAM_INDEX = 'index'; // type of content (i.e. lesson, unit etc...)
export const SEARCH_URL_PARAM_SUBJECTS = 'subjects';
export const SEARCH_URL_PARAM_YEARS = 'years';
export const SEARCH_URL_PARAM_FROM = 'from';
export const SEARCH_URL_PARAM_CATEGORY = 'category';

// URL params for tracking only
export const SEARCH_URL_PARAM_IS_SUGGESTION = 's';
export const SEARCH_URL_PARAM_TOTAL_RESULTS = 'r';

// On the various models / data, keys that are safe to display to the user
export const NONSENSITIVE_KEYS_WHITELIST = {
  USER: [
    'id',
    'email',
    'firstName',
    'lastName',
    'schoolId',
    'position',
    'title',
    'displayNameFormat',
    'emeji',
    'curriculumPreferences',
    'displayName',
    'accountStatus',
  ],
  ROLE: ['id', 'userId', 'role', 'createdAt', 'updatedAt'],
  SUBSCRIPTION: [
    'id',
    'plan',
    'status',
    'createdAt',
    'updatedAt',
    'referralCode',
  ],
  BOOKMARK: ['id', 'sort', 'pageType', 'pageId'],
};

export const CONTENT_TYPE_WITH_LINKS = [
  // content types with hardcoded links (non-expiring links)
  LINK,
  WEB_TOOL,
  INQUISITIVE_PAGE,
  INTERACTIVE,
];

// Displayname formats
export const FIRST_NAME = 'first-name';
export const FIRST_NAME_AND_INITIAL = 'first-name-and-initial';
export const TITLE_AND_SURNAME = 'title-and-surname';
export const TITLE_AND_INITIAL = 'title-and-initial';
export const INITIALS = 'initials';

/**
 * @typedef {typeof FIRST_NAME
 * | typeof FIRST_NAME_AND_INITIAL
 * | typeof TITLE_AND_SURNAME
 * | typeof TITLE_AND_INITIAL
 * | typeof INITIALS
 * } DisplayNameFormat
 */

/**
 * @type {Array<DisplayNameFormat>}
 * */
export const DISPLAY_NAME_FORMATS = [
  FIRST_NAME,
  FIRST_NAME_AND_INITIAL,
  TITLE_AND_SURNAME,
  TITLE_AND_INITIAL,
  INITIALS,
];

// User Support Information
export const SUPPORT_PHONE_NUMBER = '02 9969 1885';
export const SUPPORT_PHONE_NUMBER_US = '202-449-7200';
export const SUPPORT_INTERNATIONAL_NUMBER = '+61299691885';
export const SUPPORT_INTERNATIONAL_NUMBER_US = '+12024497200';
export const INQUISITIVE_LEGAL_NAME_ABBREVIATED = 'Inquisitive Pty Ltd';
export const INQUISITIVE_LEGAL_NAME_ABBREVIATED_US = 'Inquisitive HQ Inc';

export const INQUISITIVE_LEGAL_NAME = 'Inquisitive Proprietary limited';
export const INQUISITIVE_ABN = '53 613 805 422';
export const INQUISITIVE_US_EIN = '35 276 0931';
export const COPYRIGHT_YEARS = '2017 - 2018';

// Content feed types for teachers
export const MY_MESSAGES = 'Messages';
export const MY_CLASS_TODOS = 'Class To Dos';

export const CURRICULUM_WORD = 'curriculum';
export const CURRICULUM_VERSION_WORD = 'curriculumVersion';
export const ALL_CURRICULUMS = ALL_WORD;
export const ALL = 'ALL';
export const AU = 'AU';
export const NSW = 'NSW';
export const WA = 'WA';
export const NT = 'NT';
export const VIC = 'VIC';
export const ACT = 'ACT';
export const TAS = 'TAS';
export const QLD = 'QLD';
export const SA = 'SA';
export const NGSS = 'NGSS';
export const CCSS = 'CCSS';
export const VIC10 = 'VIC10';
export const VIC20 = 'VIC20';

/**
 * @typedef {typeof ALL
 * | typeof AU
 * | typeof NSW
 * | typeof VIC
 * | typeof WA
 * | typeof NGSS
 * | typeof CCSS
 * } CurriculumOption
 */

// IMPORTANT: Should this just be integrated into the All version?

/**
 * @type {CurriculumOption[]}
 */
export const VIC_CURRICULUM_OPTIONS = [VIC10, VIC20];

/**
 * @typedef {typeof VIC
 * | typeof VIC10
 * | typeof VIC20
 * } VicCurriculumOption
 */

/**
 * @type {CurriculumOption[]}
 */
export const CURRICULUM_OPTIONS = [ALL, AU, NSW, VIC, WA, NGSS, CCSS];

/**
 * @typedef {typeof AU | typeof NSW | typeof VIC | typeof WA} CurriculumEditOption
 */

/**
 * @typedef {typeof COUNTRY_CODE_US | typeof COUNTRY_CODE_AU} CountryCodes
 */
export const COUNTRY_CODE_US = 'us';
export const COUNTRY_CODE_AU = 'au';

export const COUNTRY_CODES = [COUNTRY_CODE_US, COUNTRY_CODE_AU];

/**
 * @type {CurriculumEditOption[]}
 */
export const CURRICULUM_OPTIONS_EDIT = [AU, NSW, VIC, WA];

export const CURRICULUM_OPTIONS_EDIT_US = [NGSS];

/**
 * @type {{[K in CurriculumOption]: string}}
 *  */
export const CURRICULUM_OPTIONS_LABELS = {
  ALL: 'All Curriculums',
  AU: 'Australian Curriculum',
  NSW: 'NSW Syllabus',
  VIC: 'Victorian Curriculum',
  WA: 'WA & AUS Curriculum',
};

/**
 * @type {{[K in CurriculumOption]: string}}
 *  */
export const CURRICULUM_OPTIONS_LABELS_US = {
  NGSS: 'NGSS',
};

// NOTE: don't use these labels in the FE directly, they need to be regionalised first
/**
 * @type {{ value: CurriculumOption, label: string }[]}
 *  */
export const ALL_CURRICULUM_OPTIONS_LABELS = [
  {
    value: AU,
    label: 'AUS Curriculum',
    labelShort: 'AUS',
  },
  {
    value: NSW,
    label: 'NSW Syllabus',
    labelShort: 'NSW',
  },
  {
    value: VIC,
    label: 'VIC Curriculum',
    labelShort: 'VIC',
  },
  {
    value: WA,
    label: 'WA & AUS Curriculum',
    labelShort: 'WA',
  },
  {
    value: NGSS,
    label: 'NGSS',
    labelShort: NGSS,
  },
];

export const AU_CURRICULUM_VERSION_V9 = 'V9.0';
export const AU_CURRICULUM_VERSION_V8 = 'V8.4';

export const VIC_CURRICULUM_VERSION_V1 = '1.0';
export const VIC_CURRICULUM_VERSION_V2 = '2.0';

/**
 * @typedef {typeof AU_CURRICULUM_VERSION_V9ALL
 * | typeof AU_CURRICULUM_VERSION_V8
 * } AUCurriculumVersions
 */

/**
 * @type {AUCurriculumVersions[]}
 */
export const AU_CURRICULUM_VERSIONS = [
  AU_CURRICULUM_VERSION_V9,
  AU_CURRICULUM_VERSION_V8,
];

/**
 * @typedef {typeof VIC_CURRICULUM_VERSION_V1
 * | typeof VIC_CURRICULUM_VERSION_V2
 * } VICCurriculumVersions
 */

/**
 * @type {VICCurriculumVersions[]}
 */
export const VIC_CURRICULUM_VERSIONS = [
  VIC_CURRICULUM_VERSION_V1,
  VIC_CURRICULUM_VERSION_V2,
];

/**
 * @typedef {typeof AU_CURRICULUM_VERSION_V9ALL
 * | typeof AU_CURRICULUM_VERSION_V8
 * | typeof VIC_CURRICULUM_VERSION_V1
 * | typeof VIC_CURRICULUM_VERSION_V2
 * } AUVICCurriculumVersions
 */

/**
 * @type {AUVICCurriculumVersions[]}
 */
export const AU_VIC_CURRICULUM_VERSIONS = [
  AU_CURRICULUM_VERSION_V9,
  AU_CURRICULUM_VERSION_V8,
  VIC_CURRICULUM_VERSION_V1,
  VIC_CURRICULUM_VERSION_V2,
];

// Content Document type
// NOTE: If you add a new document type, make sure to add it to the CONTENT_DOCUMENT_TYPES array
// NOTE: Names can be updated here for readability but not the values (they are used in existing data)
export const UNIT_RUBRIC_DOCUMENT = 'unit-rubric-doc';
export const AE_RUBRIC_DOCUMENT = 'ae-rubric-doc';
export const VICTORIAN_RUBRIC_DOCUMENT = 'victorian-rubric-doc';
export const ASSESSMENT_WITH_DIFFERENTIATION_DOCUMENT = 'assessment-doc';
export const BASIC_ASSESSMENT_DOCUMENT = 'basic-assessment-doc';
export const SELF_ASSESSMENT_DOCUMENT = 'self-assessment-doc';
export const SUMMATIVE_ASSESSMENT_DOCUMENT = 'summative-assessment-doc';
export const FORMATIVE_ASSESSMENT_DOCUMENT = 'formative-assessment-doc';
export const ANSWER_SHEET_DOCUMENT = 'answer-sheet-doc';
export const WORKSHEET_DOCUMENT = 'worksheet-doc';
export const LESSON_PLAN_DOCUMENT = 'lesson-plan-doc';
export const SUPPORT_DOCUMENT = 'support-doc';
export const EXTENSION_DOCUMENT = 'extension-doc';
export const VOCABULARY_EN_DOCUMENT = 'vocabulary-en-doc';
export const VOCABULARY_ES_DOCUMENT = 'vocabulary-es-doc';
export const SOCIAL_AND_EMOTIONAL_DOCUMENT = 'social-and-emotional-document';
export const ASSESSMENT_FOR_LEARNING_TOOL = 'assessment-for-learning-tool';
export const TEACHER_INFORMATION_DOCUMENT = 'teacher-information-doc';
export const LESSON_RUBRIC_DOCUMENT = 'lesson-rubric-doc';
export const CONTENT_DOCUMENT_TYPES = [
  UNIT_RUBRIC_DOCUMENT,
  ASSESSMENT_WITH_DIFFERENTIATION_DOCUMENT,
  BASIC_ASSESSMENT_DOCUMENT,
  VICTORIAN_RUBRIC_DOCUMENT,
  AE_RUBRIC_DOCUMENT,
  SELF_ASSESSMENT_DOCUMENT,
  SUMMATIVE_ASSESSMENT_DOCUMENT,
  FORMATIVE_ASSESSMENT_DOCUMENT,
  ANSWER_SHEET_DOCUMENT,
  WORKSHEET_DOCUMENT,
  LESSON_PLAN_DOCUMENT,
  SOCIAL_AND_EMOTIONAL_DOCUMENT,
  SUPPORT_DOCUMENT,
  EXTENSION_DOCUMENT,
  VOCABULARY_EN_DOCUMENT,
  VOCABULARY_ES_DOCUMENT,
  ASSESSMENT_FOR_LEARNING_TOOL,
  TEACHER_INFORMATION_DOCUMENT,
  LESSON_RUBRIC_DOCUMENT,
];

/**
 * @typedef {typeof UNIT_RUBRIC_DOCUMENT
 * | typeof ASSESSMENT_WITH_DIFFERENTIATION_DOCUMENT
 * | typeof BASIC_ASSESSMENT_DOCUMENT
 * | typeof SELF_ASSESSMENT_DOCUMENT
 * | typeof SUMMATIVE_ASSESSMENT_DOCUMENT
 * | typeof FORMATIVE_ASSESSMENT_DOCUMENT
 * | typeof ANSWER_SHEET_DOCUMENT
 * | typeof WORKSHEET_DOCUMENT
 * | typeof AE_RUBRIC_DOCUMENT
 * | typeof LESSON_PLAN_DOCUMENT
 * | typeof VICTORIAN_RUBRIC_DOCUMENT
 * | typeof SOCIAL_AND_EMOTIONAL_DOCUMENT
 * | typeof SUPPORT_DOCUMENT
 * | typeof EXTENSION_DOCUMENT
 * | typeof VOCABULARY_EN_DOCUMENT
 * | typeof VOCABULARY_ES_DOCUMENT
 * | typeof ASSESSMENT_FOR_LEARNING_TOOL
 * | typeof TEACHER_INFORMATION_DOCUMENT
 * | typeof LESSON_RUBRIC_DOCUMENT
 * } ContentDocumentType
 */

export const ABORIGINAL_TORRES = 'aboriginal-torres';
export const ASIA_AUSTRALIA = 'asia-australia';
export const SUSTAINABILITY = 'sustainability';

/**
 * @typedef {typeof ABORIGINAL_TORRES
 * | typeof ASIA_AUSTRALIA
 * | typeof SUSTAINABILITY
 * } CrossCurriculumPriorities
 */
/** @type {Array<CrossCurriculumPriorities>} */

export const CROSS_CURRICULUM_PRIORITIES_VALUES = [
  ABORIGINAL_TORRES,
  ASIA_AUSTRALIA,
  SUSTAINABILITY,
];

export const CROSS_CURRICULUM_PRIORITIES = [
  {
    label: 'Aboriginal and Torres Strait Islander Histories and Cultures',
    id: ABORIGINAL_TORRES,
  },
  {
    label: "Asia and Australia's Engagement with Asia",
    id: ASIA_AUSTRALIA,
  },
  {
    label: 'Sustainability',
    id: SUSTAINABILITY,
  },
];

export const CRITICAL_CREATIVE = 'critical-creative';
export const ETHICAL_UNDERSTANDING = 'ethical-understanding';
export const ICT = 'ict';
export const INTERCULTURAL = 'intercultural';
export const LITERACY = 'literacy';
export const NUMERACY = 'numeracy';
export const PERSONAL_AND_SOCIAL_RESPONSIBILITY =
  'personal-and-social-responsibility';

/**
 * @typedef {typeof CRITICAL_CREATIVE
 * | typeof ETHICAL_UNDERSTANDING
 * | typeof ICT
 * | typeof INTERCULTURAL
 * | typeof LITERACY
 * | typeof NUMERACY
 * | typeof PERSONAL_AND_SOCIAL_RESPONSIBILITY
 * } GeneralCapabilities
 */
/** @type {Array<GeneralCapabilities>} */

export const GENERAL_CAPABILITIES_VALUES = [
  CRITICAL_CREATIVE,
  ETHICAL_UNDERSTANDING,
  ICT,
  INTERCULTURAL,
  LITERACY,
  NUMERACY,
  PERSONAL_AND_SOCIAL_RESPONSIBILITY,
];
export const GENERAL_CAPABILITIES = [
  {
    label: 'Critical and Creative Thinking',
    id: CRITICAL_CREATIVE,
  },
  {
    label: 'Ethical Understanding',
    id: ETHICAL_UNDERSTANDING,
  },
  {
    label: 'Digital Literacy',
    id: ICT,
  },
  {
    label: 'Intercultural Understanding',
    id: INTERCULTURAL,
  },
  {
    label: 'Literacy',
    id: LITERACY,
  },
  {
    label: 'Numeracy',
    id: NUMERACY,
  },
  {
    label: 'Personal and Social Capability',
    id: PERSONAL_AND_SOCIAL_RESPONSIBILITY,
  },
];

// Worksheet
export const WORKSHEET_PAGES_MIN = 1;

export const JOI_VALIDATION_ERR_CODE = 500;

// image types
export const CONTENT_IMAGE = 'content';
// If update the following text please also update RESPONSIVE_SIZES
export const LANDSCAPE_IMAGE = 'landscape';
export const SQUARE_IMAGE = 'square';
export const OPENGRAPH_IMAGE = 'opengraph'; // Facebook
export const TWITTER_IMAGE = 'twitter';
export const SOCIAL_IMAGE = 'social'; // a default
export const BACKGROUND_IMAGE = 'background';

/**
 * @typedef {typeof LANDSCAPE_IMAGE
 * | typeof SQUARE_IMAGE
 * | typeof CONTENT_IMAGE
 * | typeof BACKGROUND_IMAGE
 * } ImageType
 */

/** @type {Array<ImageType>} */

export const IMAGE_TYPES = [
  LANDSCAPE_IMAGE,
  SQUARE_IMAGE,
  CONTENT_IMAGE,
  BACKGROUND_IMAGE,
];
export const SEO_IMAGE_TYPES = [OPENGRAPH_IMAGE, TWITTER_IMAGE, SOCIAL_IMAGE];

export const IMAGE_CLASSIFICATION_CARTOON = 'Cartoon';
export const IMAGE_CLASSIFICATION_DIAGRAM = 'Diagram';
export const IMAGE_CLASSIFICATION_GRAPH = 'Graph';
export const IMAGE_CLASSIFICATION_INFOGRAPHIC = 'Infographic';
export const IMAGE_CLASSIFICATION_ILLUSTRATION = 'Illustration';
export const IMAGE_CLASSIFICATION_MAP = 'Map';
export const IMAGE_CLASSIFICATION_PAINTING = 'Painting';
export const IMAGE_CLASSIFICATION_PHOTOGRAPH = 'Photograph';
export const IMAGE_CLASSIFICATION_POSTER = 'Poster';
export const IMAGE_CLASSIFICATION_SCREENSHOT = 'Screenshot';
export const IMAGE_CLASSIFICATION_TABLE = 'Table';

/**
 * @typedef {typeof IMAGE_CLASSIFICATION_CARTOON
 * | typeof IMAGE_CLASSIFICATION_DIAGRAM
 * | typeof IMAGE_CLASSIFICATION_GRAPH
 * | typeof IMAGE_CLASSIFICATION_INFOGRAPHIC
 * | typeof IMAGE_CLASSIFICATION_ILLUSTRATION
 * | typeof IMAGE_CLASSIFICATION_MAP
 * | typeof IMAGE_CLASSIFICATION_PAINTING
 * | typeof IMAGE_CLASSIFICATION_PHOTOGRAPH
 * | typeof IMAGE_CLASSIFICATION_POSTER
 * | typeof IMAGE_CLASSIFICATION_SCREENSHOT
 * | typeof IMAGE_CLASSIFICATION_TABLE
 * } ImageClassification
 */

/** @type {Array<ImageClassification>} */

export const IMAGE_CLASSIFICATIONS = [
  IMAGE_CLASSIFICATION_CARTOON,
  IMAGE_CLASSIFICATION_DIAGRAM,
  IMAGE_CLASSIFICATION_GRAPH,
  IMAGE_CLASSIFICATION_INFOGRAPHIC,
  IMAGE_CLASSIFICATION_ILLUSTRATION,
  IMAGE_CLASSIFICATION_MAP,
  IMAGE_CLASSIFICATION_PAINTING,
  IMAGE_CLASSIFICATION_PHOTOGRAPH,
  IMAGE_CLASSIFICATION_POSTER,
  IMAGE_CLASSIFICATION_SCREENSHOT,
  IMAGE_CLASSIFICATION_TABLE,
];

export const TEMPORARY_IMAGE_PREFIX = 'temporary-';
// image sizes
export const SQUARE_IMAGE_SIZE_XS = 229;
export const SQUARE_IMAGE_SIZE_S = 290;
export const SQUARE_IMAGE_SIZE_M = 408;
export const SQUARE_IMAGE_SIZE_L = 536;
export const SQUARE_IMAGE_SIZE_XL = 1072;
export const LANDSCAPE_IMAGE_SIZE_XS = [1080, 410];
export const LANDSCAPE_IMAGE_SIZE_S = [1366, 519];
export const LANDSCAPE_IMAGE_SIZE_M = [1920, 730];
export const LANDSCAPE_IMAGE_SIZE_L = [2560, 973];
export const LANDSCAPE_IMAGE_SIZE_XL = [5120, 1946];

// social media metadata image sizes
export const OPENGRAPH_IMAGE_SIZE = [1200, 630]; // Facebook https://developers.facebook.com/docs/sharing/best-practices/
export const TWITTER_IMAGE_SIZE = [1200, 600]; // Twitter https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary-card-with-large-image
export const SOCIAL_IMAGE_SIZE = [1200, 1200]; // Default social https://www.rakacreative.com/blog/social-media/social-media-image-sizes-always-up-to-date/

export const DEFAULT_PDF_PREVIEW = {
  w: SQUARE_IMAGE_SIZE_L,
  h: SQUARE_IMAGE_SIZE_L,
  page: 1,
};

export const RESPONSIVE_SIZES = {
  content: [
    [480, 480],
    [800, 800],
    [1200, 1200],
    [1920, 1920],
    [2560, 2560],
    [5120, 5120],
  ],
  landscape: [
    [480, 182],
    LANDSCAPE_IMAGE_SIZE_XS,
    TWITTER_IMAGE_SIZE,
    OPENGRAPH_IMAGE_SIZE,
    LANDSCAPE_IMAGE_SIZE_S,
    LANDSCAPE_IMAGE_SIZE_M,
    LANDSCAPE_IMAGE_SIZE_L,
    LANDSCAPE_IMAGE_SIZE_XL,
  ],
  square: [
    [SQUARE_IMAGE_SIZE_XS, SQUARE_IMAGE_SIZE_XS],
    [SQUARE_IMAGE_SIZE_S, SQUARE_IMAGE_SIZE_S],
    [SQUARE_IMAGE_SIZE_M, SQUARE_IMAGE_SIZE_M],
    [SQUARE_IMAGE_SIZE_L, SQUARE_IMAGE_SIZE_L],
    [SQUARE_IMAGE_SIZE_XL, SQUARE_IMAGE_SIZE_XL],
    SOCIAL_IMAGE_SIZE,
  ],
  opengraph: [[1200, 630]], // Facebook https://developers.facebook.com/docs/sharing/best-practices/
  twitter: [[1200, 600]], // Twitter https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary-card-with-large-image
  social: [[1200, 1200]], // Default social https://www.rakacreative.com/blog/social-media/social-media-image-sizes-always-up-to-date/
  background: [
    [480, 182],
    [2000, 1125],
    [2400, 1350],
    [2800, 1575],
    [4000, 2250],
    [8000, 4500],
  ],
};

export const JPG_QUALITIES = [40, 75];

// used for editing with the cropper.
// we don't need or want distinct sizings for cover and thumb because
// they're original covers and thumbs have the same source image
// and because they're always used at the same time, so providing
// the exact same URL for both lets the browser avoid unnecessary
// network requests
export const MAX_ORIGINAL = {
  quality: 25,
};

// ElasticSearch indexes
export const ES_BLOG_INDEX = 'blogs';
export const ES_STIMULUS_RESOURCE_INDEX = 'stimulus';
export const ES_LESSONS_INDEX = 'lessons';
export const ES_UNITS_INDEX = 'units';
export const ES_SUGGESTIONS_INDEX = 'suggestions';
export const ES_TOPICS_INDEX = 'topics';
export const ES_ASSESSMENTS_INDEX = 'assessments';
export const ES_SCOPEANDSEQ_INDEX = 'scopesequence';
export const ES_SUBJECTS_INDEX = 'subject';
export const ES_ALL_INDEX = '_all';
export const ES_NO_SUBJECTS = 'no-subjects';

/** @typedef {typeof ES_BLOG_INDEX
 * | typeof ES_STIMULUS_RESOURCE_INDEX
 * | typeof ES_LESSONS_INDEX
 * | typeof ES_UNITS_INDEX
 * | typeof ES_SUGGESTIONS_INDEX
 * | typeof ES_TOPICS_INDEX
 * | typeof ES_ASSESSMENTS_INDEX
 * | typeof ES_SCOPEANDSEQ_INDEX
 * | typeof ES_ALL_INDEX
 * | typeof ES_SUBJECTS_INDEX
 * } ElasticSearchIndex */

/** @type {ElasticSearchIndex} */

export const ES_ACTION_CREATE = 'es-create';
export const ES_ACTION_UPDATE = 'es-update';
export const ES_ACTION_DELETE = 'es-delete';
export const ES_ACTION_INDEX = 'es-index';
export const ES_ACTION_SYNC = 'es-sync';
export const ES_SUGGESTIONS_VOCABULARY = 'vocabulary';
export const ES_SUGGESTIONS_OUTCOME = 'outcome';
export const ES_SUGGESTIONS_BIG_IDEA = 'big_idea';
export const ES_SUGGESTIONS_TEXTUAL_CONCEPT = 'textual_concept';
export const ES_SUGGESTIONS_STRAND = 'strand';
export const ES_SUGGESTIONS_SUB_STRAND = 'sub_strand';

export const ES_SEARCH_PAGE_SIZE = 10;
export const ES_SEARCH_PAGES_SHOWN = 5;
export const ES_SEARCH_SUGGESTIONS_RETURN_SIZE = 8;
export const ES_SEARCH_HIGHLIGHT_TAG_NAME = 'match';

// View constants
export const HOMEPAGE_ID = 'homepage';
export const SCHOOL_INVITATION_PENDING = 'pending';
export const SCHOOL_INVITATION_ACCEPTED = 'accepted';
export const SCHOOL_INVITATION_CLOSED = 'closed';
export const SCHOOL_INVITATION_STATUS = [
  SCHOOL_INVITATION_PENDING,
  SCHOOL_INVITATION_ACCEPTED,
  SCHOOL_INVITATION_CLOSED,
];

// Errors
export const ASSESSMENT_ERROR_NOT_FOUND = 'error-assessment-not-found';
export const LESSON_ERROR_NOT_FOUND = 'error-lesson-not-found';
export const UNIT_ERROR_NOT_FOUND = 'error-unit-not-found';
export const UNIT_ERROR_IN_DRAFT = 'error-unit-in-draft';
export const TOPIC_ERROR_NOT_FOUND = 'error-topic-not-found';
export const LESSON_ACTIVITIES_ERROR_NOT_FOUND =
  'error-lesson-activities-not-found';
export const COMPONENT_TEMPLATE_ERROR_NOT_FOUND =
  'error-component-template-not-found';
export const LESSON_ACTIVITIES_ERROR_SECTION_NOT_FOUND =
  'error-lesson-activities-section-not-found';
export const ASSESSMENT_ACTIVITIES_ERROR_NOT_FOUND =
  'error-assessment-activities-not-found';
export const TEXTUAL_CONCEPT_ERROR_NOT_FOUND =
  'error-textual-concept-not-found';
export const BIG_IDEA_ERROR_NOT_FOUND = 'error-big-idea-not-found';
export const STRAND_ERROR_NOT_FOUND = 'error-strand-not-found';
export const SUB_STRAND_ERROR_NOT_FOUND = 'error-sub-strand-not-found';
export const ASSESSMENT_ACTIVITIES_ERROR_SECTION_NOT_FOUND =
  'error-assessment-activities-section-not-found';
export const SHARED_PLANNER_ERROR_NOT_FOUND = 'error-shared-planner-not-found';

// School invitation
export const SCHOOL_INVITATION_ERROR_ALREADY_ACCEPTED =
  'error-invitation-already-accepted';
export const SCHOOL_INVITATION_ERROR_FORBIDDEN = 'error-invitation-forbidden';
export const SCHOOL_INVITATION_ERROR_NOT_FOUND = 'error-invitation-not-found';
export const SCHOOL_INVITATION_ERROR_CLOSED = 'error-invitation-closed';
export const SCHOOL_INVITATION_ERROR_NO_USERS = 'error-invitation-no-users';
export const SCHOOL_INVITATION_ERROR_NOMINATE_ADMIN = 'error-nominate-admin';
export const SCHOOL_INVITATION_SAME_SCHOOL = 'same-school';
export const SCHOOL_INVITATION_DIFFERENT_SCHOOL = 'different-school';
export const SCHOOL_INVITATION_PERSONAL_ACCOUNT = 'personal-school';
/**
 * @typedef {typeof SCHOOL_INVITATION_SAME_SCHOOL
 * | typeof SCHOOL_INVITATION_DIFFERENT_SCHOOL
 * } SchoolInvitationType
 */
/** @type {Array<SchoolInvitationType>} */
export const SCHOOL_INVITATION_TYPE = [
  SCHOOL_INVITATION_SAME_SCHOOL,
  SCHOOL_INVITATION_DIFFERENT_SCHOOL,
  SCHOOL_INVITATION_PERSONAL_ACCOUNT,
];

// Activity Builder:
// Component templates
export const COMPONENT_TEMPLATE_TEXT_BOX_ID = 'text-box';
export const COMPONENT_TEMPLATE_STATEMENT_BOX_ID = 'statement-box';
export const COMPONENT_TEMPLATE_COLOURED_BOX_ID = 'coloured-box';
export const COMPONENT_TEMPLATE_INSTRUCTION_BOX_ID = 'instruction-box';
export const COMPONENT_TEMPLATE_TEXT_SPEECH_BUBBLE_ID = 'text-speech-bubble';
export const COMPONENT_TEMPLATE_CLASS_DISCUSSION_ID = 'class-discussion';
export const COMPONENT_TEMPLATE_VOCABULARY_TEXT_ID = 'vocabulary';
export const COMPONENT_TEMPLATE_IMAGE_ID = 'image';
export const COMPONENT_TEMPLATE_IMAGE_SPHERE_ID = 'image-sphere';
export const COMPONENT_TEMPLATE_SLIDESHOW_IMAGES_ID = 'slideshow';
export const COMPONENT_TEMPLATE_SIDE_BY_SIDE_IMAGES_ID = 'side-by-side-images';
export const COMPONENT_TEMPLATE_THREE_COLUMN_IMAGES_ID = 'three-columns-images';
export const COMPONENT_TEMPLATE_IMAGE_WITH_TEXT_ID = 'image-with-text';
export const COMPONENT_TEMPLATE_FOUR_IMAGE_GRID_ID = 'four-image-grid';
export const COMPONENT_TEMPLATE_FREE_TEXT_RESPONSE_ID = 'free-text-response';
export const COMPONENT_TEMPLATE_CLASS_DISCUSSION_ACTIVITY_ID =
  'class-discussion-activity';
export const COMPONENT_TEMPLATE_DRAWING_RESPONSE_ID = 'drawing-response';
export const COMPONENT_TEMPLATE_INTERACTIVE_RESPONSE_ID =
  'interactive-response';
export const COMPONENT_TEMPLATE_INTERACTIVE_RESPONSE_CATERGORISE_ID =
  'interactive-response-categorise';
export const COMPONENT_TEMPLATE_MULTIPLE_CHOICE_RESPONSE_ID =
  'multiple-choice-response';
export const COMPONENT_TEMPLATE_TABLE_RESPONSE_ID = 'table';
export const COMPONENT_TEMPLATE_TABLE_TEXT_ID = 'table-text';
export const COMPONENT_TEMPLATE_TRUE_FALSE_RESPONSE_ID = 'true-or-false';
export const COMPONENT_TEMPLATE_VIDEO_STIMULUS_ID = VIDEO;
export const COMPONENT_TEMPLATE_EBOOK_STIMULUS_ID = EBOOK_INTERACTIVE;
export const COMPONENT_TEMPLATE_EBOOK_PDF_STIMULUS_ID = EBOOK_PDF;
export const COMPONENT_TEMPLATE_STIMULUS_ID = INTERACTIVE;
export const COMPONENT_TEMPLATE_LINK_STIMULUS_ID = LINK;
export const COMPONENT_TEMPLATE_AUDIO_STIMULUS_ID = AUDIO;
export const COMPONENT_TEMPLATE_DOCUMENT_STIMULUS_ID = DOCUMENT;
export const COMPONENT_TEMPLATE_GUIDED_RESEARCH_STIMULUS_ID = GUIDED_RESEARCH;
export const COMPONENT_TEMPLATE_WEB_TOOL_STIMULUS_ID = WEB_TOOL;
export const COMPONENT_TEMPLATE_COLUMNS_2_ID = 'two-columns';
export const COMPONENT_TEMPLATE_COLUMNS_3_ID = 'three-columns';
export const COMPONENT_TEMPLATE_COLUMNS_4_ID = 'four-columns';
export const COMPONENT_TEMPLATE_COLUMNS_6_ID = 'six-columns';
export const COMPONENT_TEMPLATE_RIVE_ANIMATION_ID = 'rive-animation';
export const COMPONENT_TEMPLATE_GIF_ID = 'gif';
export const COMPONENT_TEMPLATE_IMAGE_STIMULUS_ID = 'image-stimulus';

/** @typedef {typeof COMPONENT_TEMPLATE_TEXT_BOX_ID
 * | typeof COMPONENT_TEMPLATE_STATEMENT_BOX_ID
 * | typeof COMPONENT_TEMPLATE_COLOURED_BOX_ID
 * | typeof COMPONENT_TEMPLATE_INSTRUCTION_BOX_ID
 * | typeof COMPONENT_TEMPLATE_TEXT_SPEECH_BUBBLE_ID
 * | typeof COMPONENT_TEMPLATE_CLASS_DISCUSSION_ID
 * | typeof COMPONENT_TEMPLATE_VOCABULARY_TEXT_ID
 * | typeof COMPONENT_TEMPLATE_IMAGE_ID
 * | typeof COMPONENT_TEMPLATE_SLIDESHOW_IMAGES_ID
 * | typeof COMPONENT_TEMPLATE_SIDE_BY_SIDE_IMAGES_ID
 * | typeof COMPONENT_TEMPLATE_THREE_COLUMN_IMAGES_ID
 * | typeof COMPONENT_TEMPLATE_IMAGE_WITH_TEXT_ID
 * | typeof COMPONENT_TEMPLATE_IMAGE_SPHERE_ID
 * | typeof COMPONENT_TEMPLATE_FOUR_IMAGE_GRID_ID
 * | typeof COMPONENT_TEMPLATE_FREE_TEXT_RESPONSE_ID
 * | typeof COMPONENT_TEMPLATE_CLASS_DISCUSSION_ACTIVITY_ID
 * | typeof COMPONENT_TEMPLATE_DRAWING_RESPONSE_ID
 * | typeof COMPONENT_TEMPLATE_INTERACTIVE_RESPONSE_ID
 * | typeof COMPONENT_TEMPLATE_INTERACTIVE_RESPONSE_CATERGORISE_ID
 * | typeof COMPONENT_TEMPLATE_MULTIPLE_CHOICE_RESPONSE_ID
 * | typeof COMPONENT_TEMPLATE_TABLE_RESPONSE_ID
 * | typeof COMPONENT_TEMPLATE_TABLE_TEXT_ID
 * | typeof COMPONENT_TEMPLATE_TRUE_FALSE_RESPONSE_ID
 * | typeof COMPONENT_TEMPLATE_VIDEO_STIMULUS_ID
 * | typeof COMPONENT_TEMPLATE_EBOOK_STIMULUS_ID
 * | typeof COMPONENT_TEMPLATE_EBOOK_PDF_STIMULUS_ID
 * | typeof COMPONENT_TEMPLATE_STIMULUS_ID
 * | typeof COMPONENT_TEMPLATE_LINK_STIMULUS_ID
 * | typeof COMPONENT_TEMPLATE_AUDIO_STIMULUS_ID
 * | typeof COMPONENT_TEMPLATE_DOCUMENT_STIMULUS_ID
 * | typeof COMPONENT_TEMPLATE_GUIDED_RESEARCH_STIMULUS_ID
 * | typeof COMPONENT_TEMPLATE_WEB_TOOL_STIMULUS_ID
 * | typeof COMPONENT_TEMPLATE_COLUMNS_2_ID
 * | typeof COMPONENT_TEMPLATE_COLUMNS_3_ID
 * | typeof COMPONENT_TEMPLATE_COLUMNS_4_ID
 * | typeof COMPONENT_TEMPLATE_COLUMNS_6_ID
 * | typeof COMPONENT_TEMPLATE_RIVE_ANIMATION_ID
 * | typeof COMPONENT_TEMPLATE_GIF_ID
 * | typeof COMPONENT_TEMPLATE_IMAGE_STIMULUS_ID
 * } ComponentTemplates */

/** @type {Array<ComponentTemplates>} */
export const COMPONENT_TEMPLATES = [
  COMPONENT_TEMPLATE_TEXT_BOX_ID,
  COMPONENT_TEMPLATE_STATEMENT_BOX_ID,
  COMPONENT_TEMPLATE_COLOURED_BOX_ID,
  COMPONENT_TEMPLATE_INSTRUCTION_BOX_ID,
  COMPONENT_TEMPLATE_TEXT_SPEECH_BUBBLE_ID,
  COMPONENT_TEMPLATE_CLASS_DISCUSSION_ID,
  COMPONENT_TEMPLATE_VOCABULARY_TEXT_ID,
  COMPONENT_TEMPLATE_IMAGE_ID,
  COMPONENT_TEMPLATE_IMAGE_SPHERE_ID,
  COMPONENT_TEMPLATE_SLIDESHOW_IMAGES_ID,
  COMPONENT_TEMPLATE_SIDE_BY_SIDE_IMAGES_ID,
  COMPONENT_TEMPLATE_THREE_COLUMN_IMAGES_ID,
  COMPONENT_TEMPLATE_IMAGE_WITH_TEXT_ID,
  COMPONENT_TEMPLATE_FOUR_IMAGE_GRID_ID,
  COMPONENT_TEMPLATE_FREE_TEXT_RESPONSE_ID,
  COMPONENT_TEMPLATE_CLASS_DISCUSSION_ACTIVITY_ID,
  COMPONENT_TEMPLATE_DRAWING_RESPONSE_ID,
  COMPONENT_TEMPLATE_INTERACTIVE_RESPONSE_ID,
  COMPONENT_TEMPLATE_INTERACTIVE_RESPONSE_CATERGORISE_ID,
  COMPONENT_TEMPLATE_TABLE_RESPONSE_ID,
  COMPONENT_TEMPLATE_TABLE_TEXT_ID,
  COMPONENT_TEMPLATE_MULTIPLE_CHOICE_RESPONSE_ID,
  COMPONENT_TEMPLATE_TRUE_FALSE_RESPONSE_ID,
  COMPONENT_TEMPLATE_VIDEO_STIMULUS_ID,
  COMPONENT_TEMPLATE_EBOOK_STIMULUS_ID,
  COMPONENT_TEMPLATE_EBOOK_PDF_STIMULUS_ID,
  COMPONENT_TEMPLATE_STIMULUS_ID,
  COMPONENT_TEMPLATE_LINK_STIMULUS_ID,
  COMPONENT_TEMPLATE_AUDIO_STIMULUS_ID,
  COMPONENT_TEMPLATE_DOCUMENT_STIMULUS_ID,
  COMPONENT_TEMPLATE_GUIDED_RESEARCH_STIMULUS_ID,
  COMPONENT_TEMPLATE_WEB_TOOL_STIMULUS_ID,
  COMPONENT_TEMPLATE_COLUMNS_2_ID,
  COMPONENT_TEMPLATE_COLUMNS_3_ID,
  COMPONENT_TEMPLATE_COLUMNS_4_ID,
  COMPONENT_TEMPLATE_COLUMNS_6_ID,
  COMPONENT_TEMPLATE_RIVE_ANIMATION_ID,
  COMPONENT_TEMPLATE_GIF_ID,
  COMPONENT_TEMPLATE_IMAGE_STIMULUS_ID,
];

// Special Section (Slide) Types
export const INTRODUCTION_SECTION_ID = 'introduction';
export const CONCLUSION_SECTION_ID = 'conclusion';

export const CONCLUSION_SLIDE_NUMBER = -1;
export const INTRODUCTION_SLIDE_NUMBER = 0;

// Draft Actions
export const DRAFT_ACTION_BUILD_LESSON = 'Built initial draft lesson';
export const DRAFT_ACTION_BUILD_ASSESSMENT = 'Built initial draft assessment';
export const DRAFT_ACTION_ADD_SECTION = 'Added activity';
export const DRAFT_ACTION_MOVE_SECTION = 'Moved activity';
export const DRAFT_ACTION_REMOVE_SECTION = 'Removed activity';
export const DRAFT_ACTION_UPDATE_SECTION = 'Updated activity';
export const DRAFT_ACTION_DUPLICATE_SECTION = 'Duplicated activity';
export const DRAFT_ACTION_DUPLICATE_SUB_SECTION = 'Duplicated sub-activity';
export const DRAFT_ACTION_DUPLICATE_SUB_SECTION_AS_SECTION =
  'Duplicated sub-activity as activity';
export const DRAFT_ACTION_DUPLICATE_SECTION_AS_SUB_SECTION =
  'Duplicated activity as sub-activity';
export const DRAFT_ACTION_UPDATE_PAGE_TYPE = 'Updated page type';
export const DRAFT_ACTION_ADD_COMPONENT = 'Added component';
export const DRAFT_ACTION_ADD_COMPONENT_IMAGE = 'Added new image';
export const DRAFT_ACTION_ADD_COMPONENT_GIF = 'Added new gif';
export const DRAFT_ACTION_IMAGE_ADDED_TO_STIMULUS_LIBRARY =
  'Converted to stimulus library image';
export const DRAFT_ACTION_UPDATE_COMPONENT = 'Updated component';
export const DRAFT_ACTION_REMOVE_COMPONENT = 'Removed component';
export const DRAFT_ACTION_UPDATE_COLUMNS = 'Updated columns';
export const DRAFT_ACTION_REMOVE_COLUMNS = 'Removed columns';
export const DRAFT_ACTION_APPLY_TEMPLATE = 'Applied template';
export const DRAFT_ACTION_UPDATE_INTRO_LANDSCAPE_IMAGE =
  'Updated intro landscape image';
export const DRAFT_ACTION_UPDATE_ACTIVITIES_FONT_SIZE =
  'Updated activities font size';
export const DRAFT_ACTION_UPDATE_LESSON_FONT_STYLE =
  'Updated lesson font style';
export const DRAFT_ACTION_SET_SUGGESTED_ANSWER = 'Set Suggested Answer';
export const DRAFT_ACTION_ADD_LEARNING_SEQUENCE_ITEM = 'Added teaching note';
export const DRAFT_ACTION_UPDATE_LEARNING_SEQUENCE_ITEM =
  'Updated teaching note';
export const DRAFT_ACTION_REMOVE_LEARNING_SEQUENCE_ITEM =
  'Removed teaching note';
export const DRAFT_ACTION_GENERATE_AI_TEACHING_NOTES =
  'Generated AI teaching notes ';
export const DRAFT_ACTION_RESTORE_FROM_DRAFT_HISTORY =
  'Restore from draft history';
export const DRAFT_ACTION_ADD_SECTION_BACKGROUND_IMAGE =
  'Added activity background image';
export const DRAFT_ACTION_CLEAR_SECTION_BACKGROUND_IMAGE =
  'Cleared activity background image';
export const DRAFT_ACTION_UPDATE_SECTION_BACKGROUND_STYLE =
  'Updated activity background style';
export const DRAFT_ACTION_ADD_DEFAULT_BACKGROUND_IMAGE =
  'Added default background image';
export const DRAFT_ACTION_ADD_ASSESSMENT_NAME = 'Set assessment name';
export const DRAFT_ACTION_ADD_FOCUS_QUESTION = 'Set focus question';
export const DRAFT_ACTION_ADD_PHENOMENA = 'Set phenomena';
export const DRAFT_ACTION_CLEAR_DEFAULT_BACKGROUND_IMAGE =
  'Cleared default background image';
export const DRAFT_ACTION_UPDATE_DEFAULT_BACKGROUND_STYLE =
  'Updated default background style';
export const DRAFT_ACTION_RESET_SECTION_BACKGROUND_STYLE =
  'Reset activity background style';
export const DRAFT_ACTION_COMPONENT_IMAGE_UPDATE = 'Updated image';
export const DRAFT_ACTION_COMPONENT_CLEAR_IMAGE = 'Cleared image';
export const DRAFT_ACTION_PATCH_ASSESSMENT_ACTIVITIES =
  'Patched assessment activities';
export const DRAFT_ACTION_PATCH_LESSON_ACTIVITIES = 'Patched lesson activities';
export const DRAFT_ACTION_PUBLISH_ASSESSMENT_ACTIVITIES =
  'Published assessment activities';
export const DRAFT_ACTION_PUBLISH_LESSON_ACTIVITIES =
  'Published lesson activities';

/** @typedef {typeof DRAFT_ACTION_BUILD_LESSON
 * | typeof DRAFT_ACTION_BUILD_ASSESSMENT
 * | typeof DRAFT_ACTION_ADD_SECTION
 * | typeof DRAFT_ACTION_MOVE_SECTION
 * | typeof DRAFT_ACTION_REMOVE_SECTION
 * | typeof DRAFT_ACTION_UPDATE_SECTION
 * | typeof DRAFT_ACTION_DUPLICATE_SECTION
 * | typeof DRAFT_ACTION_DUPLICATE_SUB_SECTION
 * | typeof DRAFT_ACTION_DUPLICATE_SUB_SECTION_AS_SECTION
 * | typeof DRAFT_ACTION_DUPLICATE_SECTION_AS_SUB_SECTION
 * | typeof DRAFT_ACTION_UPDATE_PAGE_TYPE
 * | typeof DRAFT_ACTION_ADD_COMPONENT
 * | typeof DRAFT_ACTION_ADD_COMPONENT_IMAGE
 * | typeof DRAFT_ACTION_ADD_COMPONENT_GIF
 * | typeof DRAFT_ACTION_UPDATE_COMPONENT
 * | typeof DRAFT_ACTION_REMOVE_COMPONENT
 * | typeof DRAFT_ACTION_UPDATE_COLUMNS
 * | typeof DRAFT_ACTION_REMOVE_COLUMNS
 * | typeof DRAFT_ACTION_APPLY_TEMPLATE
 * | typeof DRAFT_ACTION_UPDATE_INTRO_LANDSCAPE_IMAGE
 * | typeof DRAFT_ACTION_UPDATE_ACTIVITIES_FONT_SIZE
 * | typeof DRAFT_ACTION_UPDATE_LESSON_FONT_STYLE
 * | typeof DRAFT_ACTION_ADD_LEARNING_SEQUENCE_ITEM
 * | typeof DRAFT_ACTION_SET_SUGGESTED_ANSWER
 * | typeof DRAFT_ACTION_UPDATE_LEARNING_SEQUENCE_ITEM
 * | typeof DRAFT_ACTION_REMOVE_LEARNING_SEQUENCE_ITEM
 * | typeof DRAFT_ACTION_GENERATE_AI_TEACHING_NOTES
 * | typeof DRAFT_ACTION_RESTORE_FROM_DRAFT_HISTORY
 * | typeof DRAFT_ACTION_ADD_SECTION_BACKGROUND_IMAGE
 * | typeof DRAFT_ACTION_CLEAR_SECTION_BACKGROUND_IMAGE
 * | typeof DRAFT_ACTION_UPDATE_SECTION_BACKGROUND_STYLE
 * | typeof DRAFT_ACTION_ADD_DEFAULT_BACKGROUND_IMAGE
 * | typeof DRAFT_ACTION_ADD_FOCUS_QUESTION
 * | typeof DRAFT_ACTION_ADD_PHENOMENA
 * | typeof DRAFT_ACTION_CLEAR_DEFAULT_BACKGROUND_IMAGE
 * | typeof DRAFT_ACTION_UPDATE_DEFAULT_BACKGROUND_STYLE
 * | typeof DRAFT_ACTION_RESET_SECTION_BACKGROUND_STYLE
 * | typeof DRAFT_ACTION_COMPONENT_IMAGE_UPDATE
 * | typeof DRAFT_ACTION_COMPONENT_CLEAR_IMAGE
 * | typeof DRAFT_ACTION_IMAGE_ADDED_TO_STIMULUS_LIBRARY
 * | typeof DRAFT_ACTION_PATCH_ASSESSMENT_ACTIVITIES
 * | typeof DRAFT_ACTION_PATCH_LESSON_ACTIVITIES
 * | typeof DRAFT_ACTION_PUBLISH_ASSESSMENT_ACTIVITIES
 * | typeof DRAFT_ACTION_PUBLISH_LESSON_ACTIVITIES
 * | typeof DRAFT_ACTION_ADD_ASSESSMENT_NAME
 * } DraftAction
 */

/** @type {Array<DraftAction>} */
export const DRAFT_ACTIONS = [
  DRAFT_ACTION_BUILD_LESSON,
  DRAFT_ACTION_ADD_SECTION,
  DRAFT_ACTION_MOVE_SECTION,
  DRAFT_ACTION_REMOVE_SECTION,
  DRAFT_ACTION_UPDATE_SECTION,
  DRAFT_ACTION_DUPLICATE_SECTION,
  DRAFT_ACTION_DUPLICATE_SUB_SECTION,
  DRAFT_ACTION_DUPLICATE_SUB_SECTION_AS_SECTION,
  DRAFT_ACTION_DUPLICATE_SECTION_AS_SUB_SECTION,
  DRAFT_ACTION_UPDATE_PAGE_TYPE,
  DRAFT_ACTION_ADD_COMPONENT,
  DRAFT_ACTION_ADD_COMPONENT_IMAGE,
  DRAFT_ACTION_ADD_COMPONENT_GIF,
  DRAFT_ACTION_UPDATE_COMPONENT,
  DRAFT_ACTION_REMOVE_COMPONENT,
  DRAFT_ACTION_UPDATE_COLUMNS,
  DRAFT_ACTION_REMOVE_COLUMNS,
  DRAFT_ACTION_APPLY_TEMPLATE,
  DRAFT_ACTION_UPDATE_INTRO_LANDSCAPE_IMAGE,
  DRAFT_ACTION_UPDATE_ACTIVITIES_FONT_SIZE,
  DRAFT_ACTION_ADD_LEARNING_SEQUENCE_ITEM,
  DRAFT_ACTION_SET_SUGGESTED_ANSWER,
  DRAFT_ACTION_UPDATE_LEARNING_SEQUENCE_ITEM,
  DRAFT_ACTION_REMOVE_LEARNING_SEQUENCE_ITEM,
  DRAFT_ACTION_GENERATE_AI_TEACHING_NOTES,
  DRAFT_ACTION_RESTORE_FROM_DRAFT_HISTORY,
  DRAFT_ACTION_ADD_SECTION_BACKGROUND_IMAGE,
  DRAFT_ACTION_CLEAR_SECTION_BACKGROUND_IMAGE,
  DRAFT_ACTION_UPDATE_SECTION_BACKGROUND_STYLE,
  DRAFT_ACTION_ADD_DEFAULT_BACKGROUND_IMAGE,
  DRAFT_ACTION_ADD_FOCUS_QUESTION,
  DRAFT_ACTION_ADD_PHENOMENA,
  DRAFT_ACTION_CLEAR_DEFAULT_BACKGROUND_IMAGE,
  DRAFT_ACTION_UPDATE_DEFAULT_BACKGROUND_STYLE,
  DRAFT_ACTION_RESET_SECTION_BACKGROUND_STYLE,
  DRAFT_ACTION_COMPONENT_IMAGE_UPDATE,
  DRAFT_ACTION_COMPONENT_CLEAR_IMAGE,
  DRAFT_ACTION_IMAGE_ADDED_TO_STIMULUS_LIBRARY,
  DRAFT_ACTION_PATCH_ASSESSMENT_ACTIVITIES,
  DRAFT_ACTION_PATCH_LESSON_ACTIVITIES,
  DRAFT_ACTION_PUBLISH_ASSESSMENT_ACTIVITIES,
  DRAFT_ACTION_PUBLISH_LESSON_ACTIVITIES,
  DRAFT_ACTION_ADD_ASSESSMENT_NAME,
];

// These actions are key changes so we won't delete them
export const KEY_PUBLISH_DRAFT_ACTIONS = [
  DRAFT_ACTION_PATCH_ASSESSMENT_ACTIVITIES,
  DRAFT_ACTION_PATCH_LESSON_ACTIVITIES,
  DRAFT_ACTION_PUBLISH_ASSESSMENT_ACTIVITIES,
  DRAFT_ACTION_PUBLISH_LESSON_ACTIVITIES,
];

export const SUB_ACTIVITIES_LABEL_TYPE_NUMERICAL = 'numerical';
export const SUB_ACTIVITIES_LABEL_TYPE_ALPHABETICAL = 'alphabetical';
export const SUB_ACTIVITIES_LABEL_TYPE_NONE = 'none';

/**
 * @typedef {typeof SUB_ACTIVITIES_LABEL_TYPE_NUMERICAL
 * | typeof SUB_ACTIVITIES_LABEL_TYPE_ALPHABETICAL
 * | typeof SUB_ACTIVITIES_LABEL_TYPE_NONE
 * } SubActivitiesLabelType
 */
/** @type {Array<SubActivitiesLabelType>} */
export const SUB_ACTIVITIES_LABEL_TYPES = [
  SUB_ACTIVITIES_LABEL_TYPE_NUMERICAL,
  SUB_ACTIVITIES_LABEL_TYPE_ALPHABETICAL,
  SUB_ACTIVITIES_LABEL_TYPE_NONE,
];

export const ACTIVITIES_COMPONENT_TYPE_ACTIVITY = 'activity';
export const ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_RESPONSE = 'response';
export const ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_TRUE_OR_FALSE =
  'true-or-false';
export const ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_MULTIPLE_CHOICE =
  'multiple-choice';
export const ACTIVITIES_COMPONENT_TYPE_GUIDED_RESEARCH = 'guided-research';
export const ACTIVITIES_COMPONENT_TYPE_INFO_BOX = 'info-box';
export const ACTIVITIES_COMPONENT_TYPE_IMAGE = 'image';
export const ACTIVITIES_COMPONENT_TYPE_IMAGE_SPHERE = 'image-sphere';
export const ACTIVITIES_COMPONENT_TYPE_TABLE = 'table';
export const ACTIVITIES_COMPONENT_TYPE_VIDEO = 'video';
export const ACTIVITIES_COMPONENT_TYPE_INTERACTIVE = INTERACTIVE;
export const ACTIVITIES_COMPONENT_TYPE_EBOOK_INTERACTIVE = EBOOK_INTERACTIVE;
export const ACTIVITIES_COMPONENT_TYPE_AUDIO = 'audio';
export const ACTIVITIES_COMPONENT_TYPE_EBOOK = EBOOK_PDF;
export const ACTIVITIES_COMPONENT_TYPE_DOCUMENT = 'document';
export const ACTIVITIES_COMPONENT_TYPE_COLUMNS = 'columns';
export const ACTIVITIES_COMPONENT_TYPE_LINK = 'link';
export const ACTIVITIES_COMPONENT_TYPE_WEBTOOL = 'web-tool';
export const ACTIVITIES_COMPONENT_TYPE_SLIDESHOW = 'slideshow';
export const ACTIVITIES_COMPONENT_TYPE_VOCABULARY = 'vocabulary';
export const ACTIVITIES_COMPONENT_TEMPLATE = 'template';
export const ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_EQUATION_RESPONSE =
  'equation-response';
export const ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_SINGLE_ANSWER_RESPONSE =
  'single-answer-response';
export const ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_FILL_IN_BLANKS_RESPONSE =
  'fill-in-blanks-response';
export const ACTIVITIES_COMPONENT_TYPE_DRAW = 'draw';
export const ACTIVITIES_COMPONENT_TYPE_DISCUSSION_RESPONSE =
  'discussion-response';
export const ACTIVITIES_COMPONENT_TYPE_INTERACTIVE_RESPONSE =
  'interactive-response';
export const ACTIVITIES_COMPONENT_TYPE_INTERACTIVE_RESPONSE_CATEGORISE =
  'interactive-response-categorise';
export const ACTIVITIES_COMPONENT_TYPE_RIVE_ANIMATION = 'rive-animation';
export const ACTIVITIES_COMPONENT_TYPE_GIF = 'gif';

export const ACTIVITIES_COMPONENT_SUB_ACTIVITIES_TYPES = [
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_EQUATION_RESPONSE,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_RESPONSE,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_TRUE_OR_FALSE,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_MULTIPLE_CHOICE,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_SINGLE_ANSWER_RESPONSE,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_FILL_IN_BLANKS_RESPONSE,
  ACTIVITIES_COMPONENT_TYPE_DRAW,
  ACTIVITIES_COMPONENT_TYPE_TABLE,
  ACTIVITIES_COMPONENT_TYPE_DISCUSSION_RESPONSE,
  ACTIVITIES_COMPONENT_TYPE_INTERACTIVE_RESPONSE,
  ACTIVITIES_COMPONENT_TYPE_INTERACTIVE_RESPONSE_CATEGORISE,
];

/**
 * @typedef {typeof ACTIVITIES_COMPONENT_TYPE_EXPLANATION
 * | typeof ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_RESPONSE
 * | typeof ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_TRUE_OR_FALSE
 * | typeof ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_MULTIPLE_CHOICE
 * | typeof ACTIVITIES_COMPONENT_TYPE_GUIDED_RESEARCH
 * | typeof ACTIVITIES_COMPONENT_TYPE_INFO_BOX
 * | typeof ACTIVITIES_COMPONENT_TYPE_IMAGE
 * | typeof ACTIVITIES_COMPONENT_TYPE_IMAGE_SPHERE
 * | typeof ACTIVITIES_COMPONENT_TYPE_RIVE_ANIMATION
 * | typeof ACTIVITIES_COMPONENT_TYPE_GIF
 * | typeof ACTIVITIES_COMPONENT_TYPE_TABLE
 * | typeof ACTIVITIES_COMPONENT_TYPE_VIDEO
 * | typeof ACTIVITIES_COMPONENT_TYPE_INTERACTIVE
 * | typeof ACTIVITIES_COMPONENT_TYPE_EBOOK_INTERACTIVE
 * | typeof ACTIVITIES_COMPONENT_TYPE_AUDIO
 * | typeof ACTIVITIES_COMPONENT_TYPE_EBOOK
 * | typeof ACTIVITIES_COMPONENT_TYPE_DOCUMENT
 * | typeof ACTIVITIES_COMPONENT_TYPE_COLUMNS
 * | typeof ACTIVITIES_COMPONENT_TYPE_LINK
 * | typeof ACTIVITIES_COMPONENT_TYPE_WEBTOOL
 * | typeof ACTIVITIES_COMPONENT_TYPE_DRAW
 * | typeof ACTIVITIES_COMPONENT_TYPE_SLIDESHOW
 * | typeof ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_EQUATION_RESPONSE
 * | typeof ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_SINGLE_ANSWER_RESPONSE
 * | typeof ACTIVITIES_COMPONENT_TYPE_VOCABULARY
 * | typeof ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_FILL_IN_BLANKS_RESPONSE
 * | typeof ACTIVITIES_COMPONENT_TYPE_DISCUSSION_RESPONSE
 * | typeof ACTIVITIES_COMPONENT_TYPE_INTERACTIVE_RESPONSE
 * | typeof ACTIVITIES_COMPONENT_TYPE_INTERACTIVE_RESPONSE_CATEGORISE
 * } ActivitiesComponentType
 */
/** @type {Array<ActivitiesComponentType>} */
export const ACTIVITIES_COMPONENT_TYPES = [
  ACTIVITIES_COMPONENT_TYPE_ACTIVITY,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_RESPONSE,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_TRUE_OR_FALSE,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_MULTIPLE_CHOICE,
  ACTIVITIES_COMPONENT_TYPE_GUIDED_RESEARCH,
  ACTIVITIES_COMPONENT_TYPE_INFO_BOX,
  ACTIVITIES_COMPONENT_TYPE_IMAGE,
  ACTIVITIES_COMPONENT_TYPE_IMAGE_SPHERE,
  ACTIVITIES_COMPONENT_TYPE_RIVE_ANIMATION,
  ACTIVITIES_COMPONENT_TYPE_GIF,
  ACTIVITIES_COMPONENT_TYPE_TABLE,
  ACTIVITIES_COMPONENT_TYPE_VIDEO,
  ACTIVITIES_COMPONENT_TYPE_INTERACTIVE,
  ACTIVITIES_COMPONENT_TYPE_EBOOK_INTERACTIVE,
  ACTIVITIES_COMPONENT_TYPE_AUDIO,
  ACTIVITIES_COMPONENT_TYPE_EBOOK,
  ACTIVITIES_COMPONENT_TYPE_DOCUMENT,
  ACTIVITIES_COMPONENT_TYPE_COLUMNS,
  ACTIVITIES_COMPONENT_TYPE_LINK,
  ACTIVITIES_COMPONENT_TYPE_WEBTOOL,
  ACTIVITIES_COMPONENT_TYPE_DRAW,
  ACTIVITIES_COMPONENT_TYPE_SLIDESHOW,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_EQUATION_RESPONSE,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_SINGLE_ANSWER_RESPONSE,
  ACTIVITIES_COMPONENT_TYPE_VOCABULARY,
  ACTIVITIES_COMPONENT_SUB_ACTIVITY_TYPE_FILL_IN_BLANKS_RESPONSE,
  ACTIVITIES_COMPONENT_TYPE_DISCUSSION_RESPONSE,
  ACTIVITIES_COMPONENT_TYPE_INTERACTIVE_RESPONSE,
  ACTIVITIES_COMPONENT_TYPE_INTERACTIVE_RESPONSE_CATEGORISE,
];

// Suggested Answer
export const SUGGESTED_ANSWER_FIXED = 'fixed';
export const SUGGESTED_ANSWER_EXAMPLE = 'example';
export const SUGGESTED_ANSWER_VARY = 'vary';

export const SUGGESTED_ANSWERS_TYPES = [
  SUGGESTED_ANSWER_FIXED,
  SUGGESTED_ANSWER_EXAMPLE,
  SUGGESTED_ANSWER_VARY,
];

/**
 * @typedef {typeof SUGGESTED_ANSWER_FIXED
 * | typeof SUGGESTED_ANSWER_EXAMPLE
 * | typeof SUGGESTED_ANSWER_VARY
 * } SuggestedAnswerType
 */

//Activities Answer
export const ACTIVITIES_ANSWER_TEXT = 'text-answer';
export const ACTIVITIES_ANSWER_INTERACTIVE_RESPONSE_TYPE =
  'interactive-response-answer';
export const ACTIVITIES_ANSWER_MULTIPLE_CHOICE_TYPE = 'multiple-choice-answer';
export const ACTIVITIES_ANSWER_DRAW_TYPE = 'draw-answer';
export const ACTIVITIES_ANSWER_IMAGE_TYPE = 'image-answer';

/**
 * @typedef {typeof ACTIVITIES_ANSWER_TEXT
 * | typeof ACTIVITIES_ANSWER_INTERACTIVE_RESPONSE_TYPE
 * | typeof ACTIVITIES_ANSWER_MULTIPLE_CHOICE_TYPE
 * | typeof ACTIVITIES_ANSWER_DRAW_TYPE
 * | typeof ACTIVITIES_ANSWER_IMAGE_TYPE
 * } ActivitiesAnswerType
 */

/** @type {Array<ActivitiesAnswerType>} */
export const ACTIVITIES_ANSWER_TYPES = [
  ACTIVITIES_ANSWER_TEXT,
  ACTIVITIES_ANSWER_INTERACTIVE_RESPONSE_TYPE,
  ACTIVITIES_ANSWER_MULTIPLE_CHOICE_TYPE,
  ACTIVITIES_ANSWER_DRAW_TYPE,
  ACTIVITIES_ANSWER_IMAGE_TYPE,
];

export const FLEX_ALIGN_START = 'start';
export const FLEX_ALIGN_BOTTOM = 'end';
export const FLEX_ALIGN_MIDDLE = 'center';
export const FLEX_ALIGN_RIGHT = 'flex-end';
export const FLEX_SPACE_BETWEEN = 'space-between';
/**
 * @typedef {typeof FLEX_ALIGN_START
 * | typeof FLEX_ALIGN_BOTTOM
 * | typeof FLEX_ALIGN_MIDDLE
 * | typeof FLEX_ALIGN_RIGHT
 * | typeof FLEX_SPACE_BETWEEN
 * } ContentFlexAlignment
 */
/** @type {Array<ContentFlexAlignment>} */

export const ACTIVITIES_FLEX_ALIGNMENT = [
  FLEX_ALIGN_START,
  FLEX_ALIGN_BOTTOM,
  FLEX_ALIGN_MIDDLE,
  FLEX_SPACE_BETWEEN,
  FLEX_ALIGN_RIGHT,
];

export const ARROW_ALIGNMENT_LEFT = 'left';
export const ARROW_ALIGNMENT_RIGHT = 'right';
export const ARROW_ALIGNMENT_CENTRE = 'centre';
export const ARROW_ALIGNMENT_TOP = 'top';
export const ARROW_ALIGNMENT_BOTTOM = 'bottom';
/**
 * @typedef {typeof ARROW_ALIGNMENT_LEFT
 * | typeof ARROW_ALIGNMENT_RIGHT
 * | typeof ARROW_ALIGNMENT_CENTRE
 * | typeof ARROW_ALIGNMENT_TOP
 * | typeof ARROW_ALIGNMENT_BOTTOM
 * } ArrowAlignment
 */
/** @type {Array<ArrowAlignment>} */
export const ARROW_ALIGNMENTS = [
  ARROW_ALIGNMENT_LEFT,
  ARROW_ALIGNMENT_RIGHT,
  ARROW_ALIGNMENT_CENTRE,
  ARROW_ALIGNMENT_TOP,
  ARROW_ALIGNMENT_BOTTOM,
];

export const POSITION_LEFT = 'left';
export const POSITION_RIGHT = 'right';
export const POSITION_TOP = 'top';
export const POSITION_BOTTOM = 'bottom';
/**
 * @typedef {typeof POSITION_LEFT
 * | typeof POSITION_RIGHT
 * | typeof POSITION_TOP
 * | typeof POSITION_BOTTOM
 * } ActivityPosition
 */
/** @type {Array<ActivityPosition>} */
export const ACTIVITY_POSITIONS = [
  POSITION_LEFT,
  POSITION_RIGHT,
  POSITION_TOP,
  POSITION_BOTTOM,
];

export const BORDER_STYLE_NONE = 'none';
export const BORDER_STYLE_BOX = 'box';
export const BORDER_STYLE_SPEECH = 'speech';
export const BORDER_STYLE_THOUGHT = 'thought';
export const BORDER_STYLE_NOTE = 'note';
export const BORDER_STYLE_PIN = 'pin';
export const BORDER_STYLE_PAPER = 'paper';
export const BORDER_STYLE_INSTRUCTION = 'instruction';
export const BORDER_STYLE_STATEMENT = 'statement';
/**
 * @typedef {typeof BORDER_STYLE_NONE
 * | typeof BORDER_STYLE_BOX
 * | typeof BORDER_STYLE_SPEECH
 * | typeof BORDER_STYLE_THOUGHT
 * | typeof BORDER_STYLE_NOTE
 * | typeof BORDER_STYLE_PIN
 * | typeof BORDER_STYLE_PAPER
 * | typeof BORDER_STYLE_INSTRUCTION
 * | typeof BORDER_STYLE_STATEMENT
 * } BorderStyle
 */
/** @type {Array<BorderStyle>} */
export const BORDER_STYLES = [
  BORDER_STYLE_NONE,
  BORDER_STYLE_BOX,
  BORDER_STYLE_SPEECH,
  BORDER_STYLE_THOUGHT,
  BORDER_STYLE_NOTE,
  BORDER_STYLE_PIN,
  BORDER_STYLE_PAPER,
  BORDER_STYLE_INSTRUCTION,
  BORDER_STYLE_STATEMENT,
];

export const ACTIVITIES_BASE_HEIGHT_SMALL = 48;
export const ACTIVITIES_BASE_HEIGHT_LARGE = 72;

export const ACTIVITIES_OPTION_SIZE_SMALL = 'S';
export const ACTIVITIES_OPTION_SIZE_MEDIUM = 'M';
export const ACTIVITIES_OPTION_SIZE_LARGE = 'L';
/**
 * @typedef {typeof ACTIVITIES_OPTION_SIZES
 * | typeof ACTIVITIES_OPTION_SIZE_SMALL
 * | typeof ACTIVITIES_OPTION_SIZE_MEDIUM
 * | typeof ACTIVITIES_OPTION_SIZE_LARGE
 * } InteractiveContentOptionSize
 */
/** @type {Array<InteractiveContentOptionSize>} */
export const ACTIVITIES_OPTION_SIZES = [
  ACTIVITIES_OPTION_SIZE_SMALL,
  ACTIVITIES_OPTION_SIZE_MEDIUM,
  ACTIVITIES_OPTION_SIZE_LARGE,
];

export const COMPONENT_LAYOUT_VERTICAL = 'vertical';
export const COMPONENT_LAYOUT_HORIZONTAL = 'horizontal';
export const COMPONENT_LAYOUT_GRID = 'grid';
export const COMPONENT_LAYOUT_LIST = 'list';
/**
 * @typedef {typeof COMPONENT_LAYOUTS
 * | typeof COMPONENT_LAYOUT_GRID
 * | typeof COMPONENT_LAYOUT_LIST
 * | typeof COMPONENT_LAYOUT_VERTICAL
 * | typeof COMPONENT_LAYOUT_HORIZONTAL
 * } InquisitiveComponentLayout
 */
/** @type {Array<InquisitiveComponentLayout>} */
export const COMPONENT_LAYOUTS = [
  COMPONENT_LAYOUT_GRID,
  COMPONENT_LAYOUT_LIST,
  COMPONENT_LAYOUT_VERTICAL,
  COMPONENT_LAYOUT_HORIZONTAL,
];

export const NUMBER_OPERATION_PLUS = 'plus';
export const NUMBER_OPERATION_MINUS = 'minus';
export const NUMBER_OPERATION_MULTIPLY = 'multiply';
/**
 * @typedef {typeof NUMBER_OPERATIONS
 * | typeof NUMBER_OPERATION_PLUS
 * | typeof NUMBER_OPERATION_MINUS
 * | typeof NUMBER_OPERATION_MULTIPLY
 * } NumberOperation
 */
/** @type {Array<NumberOperation>} */
export const NUMBER_OPERATIONS = [
  NUMBER_OPERATION_PLUS,
  NUMBER_OPERATION_MINUS,
  NUMBER_OPERATION_MULTIPLY,
];

export const SINGLE_ANSWER_RESPONSE_VARIANT_TEXT = 'text';
export const SINGLE_ANSWER_RESPONSE_VARIANT_NUMERIC = 'numeric';
/**
 * @typedef {typeof SINGLE_ANSWER_RESPONSE_VARIANTS
 * | typeof SINGLE_ANSWER_RESPONSE_VARIANT_TEXT
 * | typeof SINGLE_ANSWER_RESPONSE_VARIANT_NUMERIC
 * } SingleAnswerResponseVariant
 */
/** @type {Array<SingleAnswerResponseVariant>} */
export const SINGLE_ANSWER_RESPONSE_VARIANTS = [
  SINGLE_ANSWER_RESPONSE_VARIANT_TEXT,
  SINGLE_ANSWER_RESPONSE_VARIANT_NUMERIC,
];

export const ACTIVITIES_RESPONSE_VARIANT_TEXT = 'text';
export const ACTIVITIES_RESPONSE_VARIANT_IMAGE = 'image';
export const ACTIVITIES_RESPONSE_VARIANT_TEXT_IMAGE = 'text-image';
export const ACTIVITIES_RESPONSE_VARIANT_DRAW = 'draw';
export const ACTIVITIES_RESPONSE_VARIANT_UPLOAD = 'upload';
/**
 * @typedef {typeof ACTIVITIES_RESPONSE_VARIANTS
 * | typeof ACTIVITIES_RESPONSE_VARIANT_TEXT
 * | typeof ACTIVITIES_RESPONSE_VARIANT_IMAGE
 * | typeof ACTIVITIES_RESPONSE_VARIANT_TEXT_IMAGE
 * | typeof ACTIVITIES_RESPONSE_VARIANT_DRAW
 * | typeof ACTIVITIES_RESPONSE_VARIANT_UPLOAD
 * } InquisitiveActivitiesResponseVariant
 */
/** @type {Array<InquisitiveActivitiesResponseVariant>} */
export const ACTIVITIES_RESPONSE_VARIANTS = [
  ACTIVITIES_RESPONSE_VARIANT_TEXT,
  ACTIVITIES_RESPONSE_VARIANT_IMAGE,
  ACTIVITIES_RESPONSE_VARIANT_TEXT_IMAGE,
  ACTIVITIES_RESPONSE_VARIANT_DRAW,
  ACTIVITIES_RESPONSE_VARIANT_UPLOAD,
];

export const ACTIVITIES_ORIENTATION_LANDSCAPE = 'landscape';
export const ACTIVITIES_ORIENTATION_PORTRAIT = 'portrait';
/**
 * @typedef {typeof ACTIVITIES_ORIENTATION_LANDSCAPE
 * | typeof ACTIVITIES_ORIENTATION_PORTRAIT
 * } ActivitiesOrientation
 */
/** @type {Array<ActivitiesOrientation>} */
export const ACTIVITIES_ORIENTATIONS = [
  ACTIVITIES_ORIENTATION_LANDSCAPE,
  ACTIVITIES_ORIENTATION_PORTRAIT,
];

export const ACTIVITIES_ANSWER_MODE_FREE = 'free';
export const ACTIVITIES_ANSWER_MODE_FIXED = 'fixed';
/**
 * @typedef {typeof ACTIVITIES_ANSWER_MODE_FREE
 * | typeof ACTIVITIES_ANSWER_MODE_FIXED
 * } ActivitiesAnswerMode
 */
/** @type {Array<ActivitiesAnswerMode>} */
export const ACTIVITIES_ANSWER_MODES = [
  ACTIVITIES_ANSWER_MODE_FREE,
  ACTIVITIES_ANSWER_MODE_FIXED,
];

export const STIMULUS_RESOURCE_ERROR_ATTACHED =
  'error-stimulus-resource-still-attached';

export const STIMULUS_RESOURCE_ERROR_NOT_FOUND =
  'error-stimulus-resource-not-found';
export const STIMULUS_RESOURCE_ERROR_WRONG_REORDER =
  'error-stimulus-resource-wrong-reorder';

// Account
export const ACCOUNT_RECENTLY_RENEWED_IN_HOURS = 24;
export const ACCOUNT_EXPIRING_REMINDER_IN_DAYS = 15; // number of days out from account expiry to send email reminders
export const ACCOUNT_DIRECT_DEPOSIT_REMINDER_IN_DAYS = 14; // number of days out from invoice due date to send email reminders
export const ACCOUNT_DIRECT_DEPOSIT_DAYS_OVERDUE = 7; // number of days over due when accounts are expired
export const ACCOUNT_STRIPE_REMEWAL_QUANTITY_SYNC_DAYS = 15; // number of days before subscription expiry to sync renewal quantity with Stripe
export const ACCOUNT_PERSONAL = 'personal-account';
export const ACCOUNT_SCHOOL = 'school-account';
/**
 * @typedef {typeof ACCOUNT_PERSONAL | typeof ACCOUNT_SCHOOL} AccountType
 */
/** @type {Array<AccountType>} */
export const ACCOUNT_TYPES = [ACCOUNT_PERSONAL, ACCOUNT_SCHOOL];

export const ACCOUNT_STATUS_DELETED = 'deleted';
export const ACCOUNT_STATUS_ACTIVE = 'active';
export const ACCOUNT_STATUS_EMPTY = null;
export const ACCOUNT_STATUS_INACTIVE = 'inactive';
export const ACCOUNT_STATUS_PROCESSING = 'processing';

/**
 * @typedef {typeof ACCOUNT_STATUS_ACTIVE
 * | typeof ACCOUNT_STATUS_INACTIVE
 * | typeof ACCOUNT_STATUS_PROCESSING
 * | typeof ACCOUNT_STATUS_DELETED
 * | null
 * } AccountStatus
 */
/** @type {Array<AccountStatus>} */
export const ACCOUNT_STATUS = [
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_STATUS_INACTIVE,
  ACCOUNT_STATUS_PROCESSING,
  ACCOUNT_STATUS_DELETED,
  null,
];

// Account events
export const ACCOUNT_EVENT_CREDIT_CARD_RENEWAL = 'credit-card-renewal';
export const ACCOUNT_EVENT_DIRECT_DEPOSIT_RENEWAL = 'direct-deposit-renewal';
export const ACCOUNT_EVENT_SUBSCRIPTION_DEACTIVATED =
  'subscription-deactivated';
export const ACCOUNT_EVENT_UPGRADE_TO_SCHOOL_ACCOUNT =
  'upgrade-to-school-account';
export const ACCOUNT_EVENT_USER_REMOVED_FROM_SCHOOL =
  'removed-from-school-account';
export const ACCOUNT_EVENT_USER_LEAVE_SCHOOL = 'left-school-account';
export const ACCOUNT_EVENT_SCHOOL_ACCOUNT_TEACHER_EXPIRED =
  'school-account-teacher-expired';
export const ACCOUNT_EVENT_USER_INITIATED_JOIN_FLOW = 'initiated-join-flow';
export const ACCOUNT_EVENT_USER_JOINED = 'user-joined';
export const ACCOUNT_EVENT_USER_SSO_JOINED = 'user-sso-joined';
export const ACCOUNT_EVENT_USER_ROLE_CHANGED = 'user-role-changed';
export const ACCOUNT_EVENT_FIRST_PERSONAL_USER_JOIN =
  'first-personal-user-join';
export const ACCOUNT_EVENT_FIRST_SCHOOL_USER_JOIN = 'first-school-user-join';
export const ACCOUNT_EVENT_ADD_PERSONAL_USER_TO_SCHOOL_ACCOUNT =
  'add-personal-user-to-school-account';
export const ACCOUNT_EVENT_MOVE_SCHOOL_ACCOUNT = 'move-users-to-school-account';
export const ACCOUNT_EVENT_USER_REQUEST_TO_JOIN_SCHOOL_ACCOUNT =
  'user-request-to-join-school-account';
export const ACCOUNT_EVENT_WHOLE_SCHOOL_DISCOUNT_APPLIED =
  'whole-school-discount-applied';
export const ACCOUNT_EVENT_WHOLE_SCHOOL_DISCOUNT_REMOVED =
  'whole-school-discount-removed';
export const ACCOUNT_EVENT_SSO_CREATED_USER = 'sso-created-user'; // deprecated event - kept for historical purposes
export const ACCOUNT_EVENT_SSO_LINKED_TO_EXISTING_USER =
  'sso-linked-to-existing-user';
export const ACCOUNT_EVENT_SSO_USER_EMAIL_UPDATED = 'sso-user-email-updated';
export const ACCOUNT_EVENT_USER_CHANGE_SCHOOL = 'user-change-school';
export const ACCOUNT_EVENT_SUBSCRIPTION_RECREATED = 'subscription-recreated';
export const ACCOUNT_EVENT_INVITED_TO_SCHOOL_ACCOUNT =
  'invited-to-school-account';
export const ACCOUNT_EVENT_FREE_ADMIN_USER_JOINED = 'free-admin-user-joined';
export const ACCOUNT_EVENT_USER_CHANGE_PASSWORD = 'user-change-password';
export const ACCOUNT_EVENT_USER_CHANGE_EMAIL = 'user-change-email';
export const ACCOUNT_EVENT_SALESFORCE_USER_CHANGE_EMAIL =
  'salesforce-user-change-email';
export const ACCOUNT_EVENT_SALESFORCE_ADD_ADMIN_USER =
  'salesforce-add-admin-user';
export const ACCOUNT_EVENT_USER_REQUEST_PASSWORD_RESET =
  'user-request-password-reset';
export const ACCOUNT_EVENT_USER_UPDATE_CURRICULUM_PREFERENCE =
  'user-update-curriculum-preference';
export const ACCOUNT_EVENT_TRIAL_CONVERTED = 'trial-converted';
export const ACCOUNT_EVENT_USER_UPDATE_YEARS_TEACHING =
  'user-update-years-teaching';
export const ACCOUNT_EVENT_SUBSCRIPTION_AUTO_RENEWAL_CHANGED =
  'subscription-auto-renewal-changed';
export const ACCOUNT_EVENT_SUBSCRIPTION_PAYMENT_METHOD_CHANGED =
  'subscription-payment-method-changed';
export const ACCOUNT_EVENT_CREDIT_CARD_PAYMENT_FAILED =
  'credit-card-payment-failed';
export const ACCOUNT_EVENT_SUBSCRIPTION_ON_HOLD = 'subscription-on-hold';
export const ACCOUNT_EVENT_SCHOOL_ACCOUNT_TEACHER_ON_HOLD =
  'school-account-teacher-on-hold';
export const ACCOUNT_EVENT_SUBSCRIPTION_REACTIVATED =
  'subscription-reactivated';
export const ACCOUNT_EVENT_SUBSCRIPTION_PURCHASE = 'subscription-purchase';
export const ACCOUNT_EVENT_SUBSCRIPTION_TRIAL = 'subscription-trial';
export const ACCOUNT_EVENT_SUBSCRIPTION_RENEWAL_QUANTITY_CHANGED =
  'subscription-renewal-quantity-changed';
export const ACCOUNT_EVENT_USER_CONFIRM_RENEWAL = 'user-confirm-renewal';
export const ACCOUNT_EVENT_PRICE_PLAN_CHANGED = 'price-plan-changed';
export const ACCOUNT_EVENT_ADD_FED_GRANT_PROMO_CODE =
  'add-fed-grant-promo-code';
export const ACCOUNT_EVENT_GOOGLE_CLASSROOM_STUDENT_IMPORT =
  'google-classroom-student-import';
export const ACCOUNT_EVENT_CREDIT_GIVEN = 'credit-given';

/**
 * @typedef {typeof ACCOUNT_EVENT_CREDIT_CARD_RENEWAL
 * | typeof ACCOUNT_EVENT_DIRECT_DEPOSIT_RENEWAL
 * | typeof ACCOUNT_EVENT_SUBSCRIPTION_DEACTIVATED
 * | typeof ACCOUNT_EVENT_UPGRADE_TO_SCHOOL_ACCOUNT
 * | typeof ACCOUNT_EVENT_USER_REMOVED_FROM_SCHOOL
 * | typeof ACCOUNT_EVENT_USER_LEAVE_SCHOOL
 * | typeof ACCOUNT_EVENT_SCHOOL_ACCOUNT_TEACHER_EXPIRED
 * | typeof ACCOUNT_EVENT_USER_INITIATED_JOIN_FLOW
 * | typeof ACCOUNT_EVENT_USER_JOINED
 * | typeof ACCOUNT_EVENT_USER_SSO_JOINED
 * | typeof ACCOUNT_EVENT_USER_ROLE_CHANGED
 * | typeof ACCOUNT_EVENT_FIRST_PERSONAL_USER_JOIN
 * | typeof ACCOUNT_EVENT_FIRST_SCHOOL_USER_JOIN
 * | typeof ACCOUNT_EVENT_ADD_PERSONAL_USER_TO_SCHOOL_ACCOUNT
 * | typeof ACCOUNT_EVENT_USER_REQUEST_TO_JOIN_SCHOOL_ACCOUNT
 * | typeof ACCOUNT_EVENT_WHOLE_SCHOOL_DISCOUNT_APPLIED
 * | typeof ACCOUNT_EVENT_WHOLE_SCHOOL_DISCOUNT_REMOVED
 * | typeof ACCOUNT_EVENT_SSO_CREATED_USER
 * | typeof ACCOUNT_EVENT_SSO_LINKED_TO_EXISTING_USER
 * | typeof ACCOUNT_EVENT_SSO_USER_EMAIL_UPDATED
 * | typeof ACCOUNT_EVENT_USER_CHANGE_SCHOOL
 * | typeof ACCOUNT_EVENT_SUBSCRIPTION_RECREATED
 * | typeof ACCOUNT_EVENT_INVITED_TO_SCHOOL_ACCOUNT
 * | typeof ACCOUNT_EVENT_FREE_ADMIN_USER_JOINED
 * | typeof ACCOUNT_EVENT_USER_CHANGE_PASSWORD
 * | typeof ACCOUNT_EVENT_USER_CHANGE_EMAIL
 * | typeof ACCOUNT_EVENT_USER_REQUEST_PASSWORD_RESET
 * | typeof ACCOUNT_EVENT_USER_UPDATE_CURRICULUM_PREFERENCE
 * | typeof ACCOUNT_EVENT_USER_UPDATE_YEARS_TEACHING
 * | typeof ACCOUNT_EVENT_SUBSCRIPTION_AUTO_RENEWAL_CHANGED
 * | typeof ACCOUNT_EVENT_SUBSCRIPTION_PAYMENT_METHOD_CHANGED
 * | typeof ACCOUNT_EVENT_CREDIT_CARD_PAYMENT_FAILED
 * | typeof ACCOUNT_EVENT_SUBSCRIPTION_ON_HOLD
 * | typeof ACCOUNT_EVENT_SCHOOL_ACCOUNT_TEACHER_ON_HOLD
 * | typeof ACCOUNT_EVENT_SUBSCRIPTION_REACTIVATED
 * | typeof ACCOUNT_EVENT_SUBSCRIPTION_PURCHASE
 * | typeof ACCOUNT_EVENT_SUBSCRIPTION_RENEWAL_QUANTITY_CHANGED
 * | typeof ACCOUNT_EVENT_USER_CONFIRM_RENEWAL
 * | typeof ACCOUNT_EVENT_PRICE_PLAN_CHANGED
 * | typeof ACCOUNT_EVENT_SALESFORCE_USER_CHANGE_EMAIL
 * | typeof ACCOUNT_EVENT_SALESFORCE_ADD_ADMIN_USER
 * | typeof ACCOUNT_EVENT_SUBSCRIPTION_TRIAL
 * | typeof ACCOUNT_EVENT_TRIAL_CONVERTED
 * | typeof ACCOUNT_EVENT_MOVE_SCHOOL_ACCOUNT
 * | typeof ACCOUNT_EVENT_ADD_FED_GRANT_PROMO_CODE
 * | typeof ACCOUNT_EVENT_GOOGLE_CLASSROOM_STUDENT_IMPORT
 * | typeof ACCOUNT_EVENT_CREDIT_GIVEN
 * } AccountEventTypes
 */
/** @type {Array<AccountStatus>} */
export const ACCOUNT_EVENT_TYPES = [
  ACCOUNT_EVENT_MOVE_SCHOOL_ACCOUNT,
  ACCOUNT_EVENT_CREDIT_CARD_RENEWAL,
  ACCOUNT_EVENT_DIRECT_DEPOSIT_RENEWAL,
  ACCOUNT_EVENT_SUBSCRIPTION_DEACTIVATED,
  ACCOUNT_EVENT_UPGRADE_TO_SCHOOL_ACCOUNT,
  ACCOUNT_EVENT_USER_REMOVED_FROM_SCHOOL,
  ACCOUNT_EVENT_USER_LEAVE_SCHOOL,
  ACCOUNT_EVENT_SCHOOL_ACCOUNT_TEACHER_EXPIRED,
  ACCOUNT_EVENT_USER_INITIATED_JOIN_FLOW,
  ACCOUNT_EVENT_USER_JOINED,
  ACCOUNT_EVENT_USER_SSO_JOINED,
  ACCOUNT_EVENT_USER_ROLE_CHANGED,
  ACCOUNT_EVENT_FIRST_PERSONAL_USER_JOIN,
  ACCOUNT_EVENT_FIRST_SCHOOL_USER_JOIN,
  ACCOUNT_EVENT_ADD_PERSONAL_USER_TO_SCHOOL_ACCOUNT,
  ACCOUNT_EVENT_USER_REQUEST_TO_JOIN_SCHOOL_ACCOUNT,
  ACCOUNT_EVENT_WHOLE_SCHOOL_DISCOUNT_APPLIED,
  ACCOUNT_EVENT_WHOLE_SCHOOL_DISCOUNT_REMOVED,
  ACCOUNT_EVENT_SSO_CREATED_USER,
  ACCOUNT_EVENT_SSO_LINKED_TO_EXISTING_USER,
  ACCOUNT_EVENT_SSO_USER_EMAIL_UPDATED,
  ACCOUNT_EVENT_USER_CHANGE_SCHOOL,
  ACCOUNT_EVENT_SUBSCRIPTION_RECREATED,
  ACCOUNT_EVENT_INVITED_TO_SCHOOL_ACCOUNT,
  ACCOUNT_EVENT_FREE_ADMIN_USER_JOINED,
  ACCOUNT_EVENT_USER_CHANGE_PASSWORD,
  ACCOUNT_EVENT_USER_CHANGE_EMAIL,
  ACCOUNT_EVENT_USER_REQUEST_PASSWORD_RESET,
  ACCOUNT_EVENT_USER_UPDATE_CURRICULUM_PREFERENCE,
  ACCOUNT_EVENT_USER_UPDATE_YEARS_TEACHING,
  ACCOUNT_EVENT_SUBSCRIPTION_AUTO_RENEWAL_CHANGED,
  ACCOUNT_EVENT_SUBSCRIPTION_PAYMENT_METHOD_CHANGED,
  ACCOUNT_EVENT_CREDIT_CARD_PAYMENT_FAILED,
  ACCOUNT_EVENT_SUBSCRIPTION_ON_HOLD,
  ACCOUNT_EVENT_SCHOOL_ACCOUNT_TEACHER_ON_HOLD,
  ACCOUNT_EVENT_SUBSCRIPTION_REACTIVATED,
  ACCOUNT_EVENT_SUBSCRIPTION_PURCHASE,
  ACCOUNT_EVENT_SUBSCRIPTION_RENEWAL_QUANTITY_CHANGED,
  ACCOUNT_EVENT_USER_CONFIRM_RENEWAL,
  ACCOUNT_EVENT_PRICE_PLAN_CHANGED,
  ACCOUNT_EVENT_SALESFORCE_USER_CHANGE_EMAIL,
  ACCOUNT_EVENT_SALESFORCE_ADD_ADMIN_USER,
  ACCOUNT_EVENT_SUBSCRIPTION_TRIAL,
  ACCOUNT_EVENT_TRIAL_CONVERTED,
  ACCOUNT_EVENT_ADD_FED_GRANT_PROMO_CODE,
  ACCOUNT_EVENT_GOOGLE_CLASSROOM_STUDENT_IMPORT,
  ACCOUNT_EVENT_CREDIT_GIVEN,
];

// Sessions Management
// The max number of concurrent session(s) allowed per login credential
export const MAX_CONCURRENT_SESSIONS = 2;

// Time before a session is considered stale and should be refreshed
export const SESSION_IS_STALE_IN_SECONDS_PROD = 2 * 60 * 60; // 2 hours
export const SESSION_IS_STALE_IN_SECONDS_DEV = 20 * 60; // 20 minutes
// Session update threshold
export const SESSION_UPDATE_THRESHOLD_IN_SECONDS = 30; // 30 seconds

// Time between stale session checks when idle - frontend
// this needs to be higher than the stale session timeout as the polling will stop the session from becoming stale
export const STALE_SESSION_POLL_INTERVAL_IN_MILLISECONDS_DEV =
  (SESSION_IS_STALE_IN_SECONDS_DEV + 2 * 60) * 1000; // stale session timeout + 2 minutes

export const STALE_SESSION_POLL_INTERVAL_IN_MILLISECONDS_PROD =
  (SESSION_IS_STALE_IN_SECONDS_PROD + 2 * 60) * 1000; // stale session timeout + 2 minutes

// List of Graphql query/mutation names that trigger stale session error
export const STALE_SESSION_GRAPHQL_NAMES = [
  'AddStudentsToClass',
  'BuyUser',
  'CancelSubscription',
  'CancelUserEmailUpdate',
  'ConfirmRenewal',
  'DeleteSchoolInvitation',
  'DoCreateSchool',
  'DynamicPricingAccounts',
  'DynamicPricingBuyMembership',
  'GetClassStudents',
  'GetGoogleClassroomList',
  'GetSchoolPeople',
  'ImportStudentsFromGoogleClassroom',
  'InviteSchoolAdmin',
  'InviteSchoolTeacher',
  'LeaveSchoolAccount',
  'PurchaseSubscriptions',
  'RequestToJoinSchool',
  'RequestUserEmailUpdate',
  'RemoveStudentsFromClass',
  'RemoveTeacherFromSchool',
  'ResendSchoolInvitation',
  'ResendSchoolTeacherInvitation',
  'ResendUserEmailUpdate',
  'UpdatePaymentDetails',
  'UpdateRenewal',
  'UpdateSchoolInvitation',
  'UpdateSchoolTeacherRole',
  'UpdateStudentInformation',
  'UpdateUserAutoRenewal',
  'UpdateUserDisplayName',
  'UpdateUserEmoji',
  'UpdateUserName',
  'UpdateUserPassword',
  'UpdateUserPosition',
  'UpdateUserSchool',
  'UpgradeFreeTrialToSchoolAccount',
  'UserBilling',
  'UserSchoolAccessRequest',
];
export const STALE_SESSION_LAMBDA_NAMES = ['printStudentCards'];
export const STALE_SESSION_STUDENT_GRAPHQL_NAMES = [
  'GetClassToDoDetail',
  'GetStudentAssessment',
  'GetStudentLesson',
  'GetStudentPublishedAssessmentActivitiesByVersion',
  'GetStudentPublishedLessonActivitiesByVersion',
  'UpdateStudentInformation',
  'UpsertStudentActivityAnswer',
];

// HTTP STATUS CODE
export const HTTP_200_OK = 200;
export const HTTP_201_CREATED = 201;
export const HTTP_204_NO_CONTENT = 204;
export const HTTP_400_BAD_REQUEST = 400;
export const HTTP_403_FORBIDDEN = 403;
export const HTTP_404_NOT_FOUND = 404;

// Apollo Validation Error Codes
export const ERR_GRAPHQL_CONSTRAINT_VALIDATION =
  'ERR_GRAPHQL_CONSTRAINT_VALIDATION';

//string max lengths
export const TEXT_MAX_LENGTH_SHORT = 140;
export const TEXT_MAX_LENGTH_MEDIUM = 255;

// Slack
export const SLACK_BLOCK_ACTIONS = 'block_actions';
export const SLACK_VIEW_SUBMISSION = 'view_submission';
export const SLACK_DIALOG_SUBMISSION = 'dialog_submission';
export const SLACK_DIALOG_SUGGESTION = 'dialog_suggestion';
export const SLACK_ACTION_SENDBACK = 'action-send-back';
export const SLACK_ACTION_SWITCH_TO_DD = 'action-switch-to-dd';
export const SLACK_ACTION_SEND_ADD_TEACHER_EFT_INVOICE_EMAIL =
  'action-send-add-teacher-eft-invoice-email';
export const SLACK_ACTION_SEND_RENEWAL_EFT_INVOICE_EMAIL =
  'action-send-renewal-eft-invoice-email';
export const SLACK_ACTION_CHANGE_SCHOOL_RENEWAL_EMAILS =
  'action-change-school-renewal-emails';
export const SLACK_ACTION_CHANGE_SCHOOL_ADD_TEACHER_EMAILS =
  'action-change-school-add-teacher-emails';
export const SLACK_ACTION_CHANGE_FORGOT_PASSWORD_PERMISSION =
  'action-change-forgot-password-permission';
export const SLACK_ACTION_CHANGE_AUTO_RENEWAL = 'action-change-auto-renewal';
export const SLACK_ACTION_CHANGE_FEATURE_FLAG = 'action-change-feature-flag';
export const SLACK_ACTION_ADD_USER_ROLE = 'action-add-user-role';
export const SLACK_ACTION_REMOVE_USER_ROLE = 'action-remove-user-role';
export const SLACK_ACTION_MOVE_SCHOOL_PERSONAL = 'action-move-school-personal';
export const SLACK_ACTION_CONVERT_SCHOOL_ACCOUNT =
  'action-convert-school-account';

export const SLACK_ACTION_CONFIRM_RENEWAL = 'action-confirm-renewal';
export const SLACK_ACTION_REMOVE_WHOLE_SCHOOL_DISCOUNT =
  'action-remove-whole-school-discount';
export const SLACK_AUTO_RENEWAL_ON = 'auto-renewal-on';
export const SLACK_AUTO_RENEWAL_OFF = 'auto-renewal-off';
export const SLACK_SCHOOL_ADD_TEACHER_EMAILS_ON =
  'school-add-teacher-emails-on';
export const SLACK_SCHOOL_ADD_TEACHER_EMAILS_OFF =
  'school-add-teacher-emails-off';
export const SLACK_SCHOOL_RENEWAL_EMAILS_ON = 'school-renewal-emails-on';
export const SLACK_SCHOOL_RENEWAL_EMAILS_OFF = 'school-renewal-emails-off';
export const SLACK_FORGOT_PASSWORD_PERMISSION_ON =
  'forgot-password-permission-on';
export const SLACK_FORGOT_PASSWORD_PERMISSION_OFF =
  'forgot-password-permission-off';
export const SLACK_USERID_BLOCK_ID = 'user-id';
export const SLACK_ACTION_DYNAMIC_MENU_SCHOOL_LIST = 'school-list';
export const SLACK_DIALOG_SUGGESTION_SCHOOL_LIST = 'school-list';
export const SLACK_ACTION_LEAVE_SCHOOL_ACCOUNT = 'leave-school-account';
export const SLACK_ACTION_ADD_PO_NUMBER = 'add-po-number';

export const SLACK_ACTION_CHANGE_RENEWAL_COUPON =
  'action-change-renewal-coupon';
export const SLACK_NEXT_RENEWAL_COUPON_OFF = 'slack-renewal-coupon-off';
// Prereder.io
export const PRERENDER_IO_API = 'https://api.prerender.io/recache';

// Docx images
export const DOCX_FOOTER_GRADIENT_URL =
  'https://public.inquisitive.com/docx/footer-gradient.png';
export const DOCX_FOOTER_GRADIENT_TALL_URL =
  'https://public.inquisitive.com/docx/footer-gradient-tall.png';
export const DOCX_DIFFERENTIATION_IMAGE_URL =
  'https://public.inquisitive.com/activitykeypng.png';

// Email images
export const INQUISITIVE_LOGO_URL =
  'https://public.inquisitive.com/email/inquisitive-logo-darker.png';
export const INQUISITIVE_LOGO_BLACK_URL =
  'https://public.inquisitive.com/email/inquisitive-logo-black.svg';

//Students Cards Images
export const GOOGLE_SSO = 'https://public.inquisitive.com/Google.png';
export const CENET_SSO = 'https://public.inquisitive.com/CENEt.png';
export const NSW_DOE_SSO = 'https://public.inquisitive.com/NSW-Gov.png';

// Roles
export const PARENT = 'parent';
export const STUDENT = 'student';
export const TEACHER = 'teacher';
export const ADMIN = 'admin';
export const CREATOR = 'creator';
export const INQUISITOR = 'inquisitor';
export const BILLING = 'billing';
export const GOOGLE_COMPLIANCE_CREATOR = 'google-compliance-creator';
export const ACTIVITY_BUILDER_ROLE = 'activity-builder';
export const ACTIVITY_PUBLISHER_ROLE = 'activity-publisher';
export const ACTIVITY_EDITOR_ROLE = 'activity-editor';

/**
 * @typedef {typeof PARENT
 * | typeof STUDENT
 * | typeof TEACHER
 * | typeof ADMIN
 * | typeof CREATOR
 * | typeof INQUISITOR
 * | typeof BILLING
 * | typeof GOOGLE_COMPLIANCE_CREATOR
 * | typeof ACTIVITY_BUILDER_ROLE
 * | typeof ACTIVITY_PUBLISHER_ROLE
 * | typeof ACTIVITY_EDITOR_ROLE
 * } Role
 */

/**
 * @type {Role[]}
 */
export const ROLES = [
  PARENT,
  STUDENT,
  TEACHER,
  ADMIN,
  CREATOR,
  INQUISITOR,
  BILLING,
  GOOGLE_COMPLIANCE_CREATOR,
  ACTIVITY_BUILDER_ROLE,
  ACTIVITY_PUBLISHER_ROLE,
  ACTIVITY_EDITOR_ROLE,
];

export const ADMIN_DASHBOARD_ROLES = [
  CREATOR,
  ACTIVITY_BUILDER_ROLE,
  ACTIVITY_PUBLISHER_ROLE,
  ACTIVITY_EDITOR_ROLE,
];

export const SUBSCRIPTION_STATUS = [
  TRIALING,
  ACTIVE,
  CANCELED,
  PAST_DUE,
  UNPAID,
];

export const EMAIL_UPDATE_OPEN = 'open';
export const EMAIL_UPDATE_CANCELLED = 'cancelled';
export const EMAIL_UPDATE_ACCEPTED = 'accepted';

export const EMAIL_UPDATE_STATUS = [
  EMAIL_UPDATE_OPEN,
  EMAIL_UPDATE_CANCELLED,
  EMAIL_UPDATE_ACCEPTED,
];

export const EMAIL_REJECTED_TYPE_NON_EDUCATIONAL = 'non-educational';

export const EMAIL_REJECTED_TYPES = [EMAIL_REJECTED_TYPE_NON_EDUCATIONAL];

export const EMAIL_REJECTED_SOURCE_JOIN = 'join';
export const EMAIL_REJECTED_SOURCE_ALLOCATE = 'allocate';
export const EMAIL_REJECTED_SOURCE_SHARE_CLASS = 'share-class';
export const EMAIL_REJECTED_SOURCE_TRIAL = 'trial';
export const EMAIL_REJECTED_SOURCE_SALESFORCE = 'salesforce';

/**
 * @typedef {typeof EMAIL_REJECTED_SOURCE_JOIN
 * | typeof EMAIL_REJECTED_SOURCE_ALLOCATE
 * | typeof EMAIL_REJECTED_SOURCE_SHARE_CLASS
 * | typeof EMAIL_REJECTED_SOURCE_TRIAL
 * | typeof EMAIL_REJECTED_SOURCE_SALESFORCE
 * } EmailRejectedSource
 */

/**
 * @type {EmailRejectedSource[]}
 */

export const EMAIL_REJECTED_SOURCES = [
  EMAIL_REJECTED_SOURCE_JOIN,
  EMAIL_REJECTED_SOURCE_ALLOCATE,
  EMAIL_REJECTED_SOURCE_SHARE_CLASS,
  EMAIL_REJECTED_SOURCE_TRIAL,
  EMAIL_REJECTED_SOURCE_SALESFORCE,
];

export const STRIPE_USER_BASE_URL = 'https://dashboard.stripe.com/customers/';

export const STIMULUS_RESOURCE_DEFAULT_GRADIENT_COLOR = '#585858';

//schools
export const SCHOOL_TYPE_PRIMARY = 'Primary';
export const SCHOOL_TYPE_ELEMENTARY_PRIMARY = 'Elementary/Primary';
export const SCHOOL_TYPE_MIDDLE_SCHOOL = 'Middle School';
export const SCHOOL_TYPE_HIGH_SCHOOL = 'High School';
export const SCHOOL_TYPE_SECONDARY = 'Secondary';
export const SCHOOL_TYPE_COMBINED = 'Combined';
export const SCHOOL_TYPE_SPECIAL = 'Special';
export const SCHOOL_TYPE_TEST = 'Test';
export const SCHOOL_TYPE_UNKNOWN = 'Unknown';
export const SCHOOL_TYPE_CASUAL = 'Casual School';
export const SCHOOL_TYPE_HOMESCHOOL = 'Homeschool';
export const SCHOOL_TYPE_DISTRICT = 'District';

export const SCHOOL_TYPES = [
  SCHOOL_TYPE_PRIMARY,
  SCHOOL_TYPE_ELEMENTARY_PRIMARY,
  SCHOOL_TYPE_SECONDARY,
  SCHOOL_TYPE_MIDDLE_SCHOOL,
  SCHOOL_TYPE_HIGH_SCHOOL,
  SCHOOL_TYPE_COMBINED,
  SCHOOL_TYPE_SPECIAL,
  SCHOOL_TYPE_TEST,
  SCHOOL_TYPE_UNKNOWN,
  SCHOOL_TYPE_CASUAL,
  SCHOOL_TYPE_HOMESCHOOL,
  SCHOOL_TYPE_DISTRICT,
];

export const TYPE_OF_SCHOOL_UNKNOWN = 'Unknown';

export const SCHOOL_SECTOR_GOVERNMENT = 'Gov';
export const SCHOOL_SECTOR_NON_GOVERNMENT = 'Non-Gov';
export const SCHOOL_SECTOR_TEST = 'Test';
export const SCHOOL_SECTOR_NA = 'N/A';
export const SCHOOL_SECTOR_UNKNOWN = 'Unknown';

export const SCHOOL_SECTORS = [
  SCHOOL_SECTOR_GOVERNMENT,
  SCHOOL_SECTOR_NON_GOVERNMENT,
  SCHOOL_SECTOR_TEST,
  SCHOOL_SECTOR_NA,
  SCHOOL_SECTOR_UNKNOWN,
];

// class to do access
export const CLASS_TO_DO_AUTH_QUERY_CLASS_TO_DO_DETAILS = 'getClassToDoDetail';
export const CLASS_TO_DO_AUTH_QUERY_ASSESSMENT = 'getAssessmentById';
export const CLASS_TO_DO_AUTH_QUERY_LESSON = 'getLessonById';
export const CLASS_TO_DO_AUTH_QUERY_PUBLISHED_ASSESSMENT =
  'getPublishedAssessmentActivitiesByVersion';
export const CLASS_TO_DO_AUTH_QUERY_PUBLISHED_LESSON =
  'getPublishedLessonActivitiesByVersion';
export const CLASS_TO_DO_AUTH_QUERIES = [
  CLASS_TO_DO_AUTH_QUERY_ASSESSMENT,
  CLASS_TO_DO_AUTH_QUERY_LESSON,
  CLASS_TO_DO_AUTH_QUERY_PUBLISHED_ASSESSMENT,
  CLASS_TO_DO_AUTH_QUERY_PUBLISHED_LESSON,
];

// Class sizes
export const CLASS_SIZE_FIELD = 'classSize';
export const CLASS_SIZE_DEFAULT = 30;

export const IGNORED_STATUS_CODES = [200, 201, 400, 401, 403, 419];
export const APOLLO_ERROR_FORBIDDEN = 'FORBIDDEN';
export const APOLLO_ERROR_UNAUTHENTICATED = 'UNAUTHENTICATED';
export const APOLLO_ERROR_NOT_FOUND = 'NOT FOUND';
export const APOLLO_INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const APOLLO_WARNING_BAD_USER_INPUT = 'BAD USER INPUT ERROR WARNING';
export const ERROR_MESSAGE_USER_MISSING_IN_CONTEXT =
  'User is missing in GraphQLContext';
export const ERROR_MESSAGE_STUDENT_MISSING_IN_CONTEXT =
  'Student is missing in GraphQLContext';
export const ERROR_MESSAGE_INVALID_CLASS_TO_DO_ID =
  'Wrong Class Id. Please double check the Class Id in the URL is correct.';

export const ERROR_MESSAGE_PLANNER_NOT_FOUND = "The Planner isn't found";
export const ERROR_MESSAGE_CANNOT_BE_ADDED_TO_PLANNER =
  'Item cannot be added to the planner';
export const ERROR_MESSAGE_CLASS_NOT_FOUND = 'Class not found';
export const ERROR_MESSAGE_CLASS_NOT_BELONGS_TO_USER =
  'Class does not belong to user';
export const ERROR_MESSAGE_CLASS_TO_DO_TOKEN = 'invalid class to do auth token';
export const ERROR_MESSAGE_CLASS_TO_DO_PASSCODE =
  'Passcode is incorrect, try again.';
export const ERROR_MESSAGE_FAILED_AUTHENTICATION =
  'Failed class to do authentication!!';
export const ERROR_MESSAGE_ALREADY_LOGGED_IN =
  'An account is already logged in from this computer, please sign out to continue';
export const ERROR_MESSAGE_ALREADY_IN_PLANNER = 'already in planner';
export const ERROR_MESSAGE_CLASS_ARCHIVED = 'Your class has been archived';
export const ERROR_MESSAGE_REMOVED_FROM_CLASS =
  'You have been removed from a class';
export const ERROR_MESSAGE_SESSION_IS_STALE = 'Session is stale.';
export const ERROR_MESSAGE_STUDENT_SESSION_IS_STALE =
  'Student session is stale.';

export const ERROR_MESSAGE_EMAIL_UPDATE_INVALID_REQUEST =
  'Invalid email update request';
export const ERROR_MESSAGE_EMAIL_UPDATE_CLOSED_REQUEST =
  'The email update request is closed';
export const ERROR_MESSAGE_EMAIL_UPDATE_OCCUPIED =
  'The email address is not availiable ';
export const ERROR_MESSAGE_PASSWORD_INCORRECT = 'Password is incorrect';
export const ERROR_MESSAGE_EMAIL_UPDATE_RESEND_FAILED =
  'Unable to send the email request';
export const ERROR_MESSAGE_EMAIL_UNAVAILABLE = 'Email is not available';
export const ERROR_MESSAGE_EMAIL_INVALID = 'Email is invalid';
export const ERROR_MESSAGE_PLANNER_EXISTED = 'Planner already existed';
export const ERROR_MESSAGE_PLANNER_DELETED = 'Planner has been deleted';
export const ERROR_MESSAGE_PLANNER_UNIT_DELETED =
  'Planner Unit has been deleted';
export const ERROR_MESSAGE_PLANNER_ITEM_DELETED =
  'Planner Item (lesson / assessment) has been deleted';

export const ERROR_MESSAGE_SIGNIN_PROCESSING =
  'Your account is being processed. This can take up to 48 hours. Any questions please contact us.';
export const ERROR_MESSAGE_SIGNIN_INCORRECT = 'Email or password is incorrect.';
export const ERROR_MESSAGE_SIGNIN_INCORRECT_STUDENT =
  'Sign in code is incorrect.';
export const ERROR_MESSAGE_SIGNIN_INCORRECT_SSO =
  'Click the NSW DoE button below to sign in.';
export const ERROR_MESSAGE_SCHOOL_ADMIN =
  'Only School Admin user has access to this function.';

export const ERROR_MESSAGE_INSUFFICIENT_SEATS =
  'Insufficient seats in subscription';
export const ERROR_MESSAGE_NOT_ENOUGH_TEACHER_INVITED =
  'At least one teacher needs to be invited to the school';

export const ERROR_MESSAGE_OUTCOME_VERSION_FILTER =
  'Curriculum versions filters should be specified only for AU, WA and VIC curriculums';

export const GRAPHQL_REQUEST_TRACE_ID = 'x-inq-fe-trace-id';

// https://prerender.io/documentation/faq#user-agent
export const PRERENDER_USER_AGENT =
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/61.0.3159.5 Safari/537.36 Prerender (+https://github.com/prerender/prerender)';

export const LOGGER_PREFIX = ' --INQ-- ';

// Authorisation
export const AUTH_SCHOOL_ADMIN_PERSONAL_INCLUSION =
  'auth-school-admin-personal-inclusion';
export const AUTHENTICATED = 'authenticated';
/**
 * @typedef {typeof PARENT
 * | typeof STUDENT
 * | typeof TEACHER
 * | typeof ADMIN
 * | typeof CREATOR
 * | typeof INQUISITOR
 * | typeof BILLING
 * | typeof AUTH_SCHOOL_ADMIN_PERSONAL_INCLUSION
 * | typeof AUTHENTICATED
 * | typeof GOOGLE_COMPLIANCE_CREATOR
 * } AuthorisationRole
 */

export const OUTCOME_CATEGORY_SKILLS = 'Skills';
export const OUTCOME_CATEGORY_KNOWLEDGE = 'Knowledge';
export const OUTCOME_CATEGORY_PERFORMANCE_EXPECTATIONS =
  'PerformanceExpectations';

export const OUTCOME_CATEGORIES = [
  OUTCOME_CATEGORY_SKILLS,
  OUTCOME_CATEGORY_KNOWLEDGE,
  OUTCOME_CATEGORY_PERFORMANCE_EXPECTATIONS,
];

/**
 * @typedef {typeof OUTCOME_CATEGORY_SKILLS
 * | typeof OUTCOME_CATEGORY_KNOWLEDGE
 * | OUTCOME_CATEGORY_PERFORMANCE_EXPECTATIONS
 * } OutcomeCategory
 */

export const PERFORMANCE_EXPECTATION = 'PE';
export const PERFORMANCE_EXPECTATION_DCI = 'DCI';
export const PERFORMANCE_EXPECTATION_SEP = 'SEP';
export const PERFORMANCE_EXPECTATION_CCC = 'CCC';
export const PERFORMANCE_EXPECTATION_ENG = 'ENG';
export const PERFORMANCE_EXPECTATION_NOS = 'NOS';

export const PERFORMANCE_EXPECTATIONS_TYPE = [
  PERFORMANCE_EXPECTATION_DCI,
  PERFORMANCE_EXPECTATION_SEP,
  PERFORMANCE_EXPECTATION_CCC,
  PERFORMANCE_EXPECTATION_ENG,
  PERFORMANCE_EXPECTATION_NOS,
];

/**
 * @typedef {typeof PERFORMANCE_EXPECTATION_DCI
 * | typeof   PERFORMANCE_EXPECTATION_SEP
 * | typeof  PERFORMANCE_EXPECTATION_CCC
 * | typeof  PERFORMANCE_EXPECTATION_ENG
 * | typeof  PERFORMANCE_EXPECTATION_NOS
 * } PerformanceExpectationType
 */

export const CONTENT_ACTION_ADD = 'content-action-add';
export const CONTENT_ACTION_DELETE = 'content-action-delete';

export const CHROME_UA =
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103 Safari/537.36O';
// GraphQL
export const GRAPHQL_MAX_QUERY_DEPTH = 5;

// SSO
export const SSO_SIGNIN_PROVIDER_GROUP_ID = 'signin';
export const SSO_OAUTH2_AUTH_METHOD = 'client_secret_post';
export const SSO_CENET_PROVIDER_ID = 'cenet';
export const SSO_DET_NSW_PROVIDER_ID = 'detnsw';
export const SSO_GOOGLE_PROVIDER_ID = 'google';
//export const SSO_MICROSOFT_PROVIDER_ID = 'microsoft';
export const SSO_GOOGLE_CLASSROOM_PROVIDER_ID = 'google-classroom';
export const SSO_CAPTURE_TOKENS_PROVIDER_IDS = [
  SSO_GOOGLE_CLASSROOM_PROVIDER_ID,
  //SSO_MICROSOFT_PROVIDER_ID,
];

/**
 * @typedef {typeof SSO_CENET_PROVIDER_ID
 * | typeof SSO_DET_NSW_PROVIDER_ID
 * | typeof SSO_GOOGLE_PROVIDER_ID
 * } SignInSsoProvider
 */
export const SIGN_IN_SSO_PROVIDERS = [
  SSO_CENET_PROVIDER_ID,
  SSO_DET_NSW_PROVIDER_ID,
  SSO_GOOGLE_PROVIDER_ID,
];

/**
 * @typedef {typeof SSO_CENET_PROVIDER_ID
 * | typeof SSO_DET_NSW_PROVIDER_ID
 * | typeof SSO_GOOGLE_PROVIDER_ID
 * } JoinFlowSsoProvider
 */
export const JOIN_FLOW_SSO_PROVIDERS = [
  SSO_CENET_PROVIDER_ID,
  SSO_DET_NSW_PROVIDER_ID,
  SSO_GOOGLE_PROVIDER_ID,
];

// SSO Error Codes
export const SSO_ERROR_CODE_NOT_AUTHORISE = 'sso error code not authorise';
export const SSO_ERROR_CODE_SESSION_EXPIRED = 'sso error code session expired';
export const SSO_ERROR_CODE_STUDENT_NOT_AUTHORISE =
  'sso error code student not authorise';
export const SSO_ERROR_CODE_ACCOUNT_STILL_PROCESSING =
  'sso error code account is still processing';
export const SSO_ERROR_CODE_INSUFFICIENT_GOOGLE_CLASSROOM_PERMISSION =
  'sso error code insufficient google classroom permission';

/**
 * @typedef {typeof SSO_ERROR_CODE_NOT_AUTHORISE
 * | typeof SSO_ERROR_CODE_SESSION_EXPIRED
 * | typeof SSO_ERROR_CODE_STUDENT_NOT_AUTHORISE
 * | typeof SSO_ERROR_CODE_ACCOUNT_STILL_PROCESSING
 * | typeof SSO_ERROR_CODE_INSUFFICIENT_GOOGLE_CLASSROOM_PERMISSION
 * } SsoErrorCode
 */

/** @type {Array<SsoErrorCode>} */
export const SSO_ERROR_CODES = [
  SSO_ERROR_CODE_NOT_AUTHORISE,
  SSO_ERROR_CODE_SESSION_EXPIRED,
  SSO_ERROR_CODE_STUDENT_NOT_AUTHORISE,
  SSO_ERROR_CODE_ACCOUNT_STILL_PROCESSING,
  SSO_ERROR_CODE_INSUFFICIENT_GOOGLE_CLASSROOM_PERMISSION,
];

export const NSW_EDUCATION_EMAIL_DOMAIN = 'education.nsw.gov.au';
// SSO DET NSW
export const SSO_DET_NSW_EMAIL_DOMAIN = 'det.nsw.edu.au';
export const SSO_DET_NSW_SCHOOL_EMAIL = 'school@det.nsw.edu.au';
// SSO DET NSW roles
export const SSO_DET_NSW_ROLE_TEACHER = 'SCHOOL.TEACHER';
export const SSO_DET_NSW_ROLE_PRINCIPLE = 'SCHOOL.PRINCIPAL';
export const SSO_DET_NSW_ROLE_DEBUTY_PRINCIPLE_HS =
  'SCHOOL.PRINCIPAL.DEPUTY.HS';
export const SSO_DET_NSW_ROLE_DEBUTY_PRINCIPLE_PS =
  'SCHOOL.PRINCIPAL.DEPUTY.PS';
export const SSO_DET_NSW_ROLE_CASUAL_TEACHER = 'SCHOOL.CASUALTEACHER';
export const SSO_DET_NSW_ROLE_CASUAL_NON_TEACHER = 'SCHOOL.CASUALNONTEACHER';
export const SSO_DET_NSW_ROLE_CORPORATE = 'SCHOOL.CORPORATE';
export const SSO_DET_NSW_ROLE_SCHOOL_PRACTICE = 'SCHOOL.PRACTICE';
export const SSO_DET_NSW_ROLE_SCHOOL_STUDENT = 'SCHOOL.STUDENT';
export const SSO_DET_NSW_ROLE_SCHOOL_ADMINOFFICER = 'SCHOOL.ADMINOFFICER';
// SSO DET NSW defaults
export const SSO_DET_NSW_DEFAULT_SCHOOL_ID = 10485;
export const SSO_DET_NSW_DEFAULT_SCHOOL_ID_DEV = 1;
export const SSO_DET_NSW_FORGOTTEN_PASSWORD_MESSAGE =
  'Click the blue button below to sign in using your NSW DoE account. There is no need to reset your password.';

// Overlay
export const CONTENT_RELEASE_OVERLAY_NOTIFICATION_EXPIRE_TIME_RANGE_IN_WEEKS = 6;
export const CONTENT_RELEASE_OVERLAY_NOTIFICATION_EXPIRE_TIME_RANGE = `${CONTENT_RELEASE_OVERLAY_NOTIFICATION_EXPIRE_TIME_RANGE_IN_WEEKS} weeks`;
export const CONTENT_RELEASE_OVERLAY_NOTIFICATION_MAX_RECORDS = 6;
export const CONTENT_RELEASE_OVERLAY_NOTIFICATION_MAX_DISPLAY = 1;

// Planner Item Type
export const PLANNER_ITEM_ASSESSMENT = 'assessment';
export const PLANNER_ITEM_LESSON = 'lesson';
export const PLANNER_ITEM_UNIT = 'unit';

/**
 * @typedef {typeof PLANNER_ITEM_ASSESSMENT
 * | typeof PLANNER_ITEM_LESSON
 * } PlannerItemType
 */

/**
 * @type {Array<PlannerItemType>}
 */
export const PLANNER_ITEM_TYPES = [
  PLANNER_ITEM_ASSESSMENT,
  PLANNER_ITEM_LESSON,
];

// Completion Status
export const COMPLETION_STATUS_NOT_STARTED = 'NotStarted';
export const COMPLETION_STATUS_SKIPPED = 'Skipped';
export const COMPLETION_STATUS_COMPLETED = 'Completed';

/**
 * @typedef {typeof COMPLETION_STATUS_NOT_STARTED
 * | typeof COMPLETION_STATUS_SKIPPED
 * | typeof COMPLETION_STATUS_COMPLETED
 * } ContentCompletionStatus
 */

/** @type {Array<ContentCompletionStatus>} */
export const CONTENT_COMPLETION_STATUS = [
  COMPLETION_STATUS_NOT_STARTED,
  COMPLETION_STATUS_SKIPPED,
  COMPLETION_STATUS_COMPLETED,
];

/* Subscription Name or Prefix */
export const SUBSCRIPTION_USER_PLANNER_UPDATE = 'USER_PLANNER_UPDATE';
export const SUBSCRIPTION_USER_PLANNER_DELETE = 'USER_PLANNER_DELETE';

/* Subscription Heart Beat */
export const SUBSCRIPTION_HEART_BEAT = 'HEART_BEAT';

export const WEBSOCKET_HEART_BERT_PING = 'PING';
export const WEBSOCKET_HEART_BERT_PONG = 'PONG';

/* Websocket  DynamoDB records TTL (seconds) */
export const WEBSOCKET_DB_RECORDS_TTL_PROD = 7200;
export const WEBSOCKET_DB_RECORDS_TTL_NON_PROD = 7200;

/* Permission Override */
export const PERMISSION_OVERRIDE_SSO_USER_FORGOT_PASSWORD =
  'sso-user-forgot-password';

/**
 * @typedef {typeof PERMISSION_OVERRIDE_SSO_USER_FORGOT_PASSWORD
 * } PermissionOverrideType
 */
/** @type {Array<PermissionOverrideType>} */
export const PERMISSION_OVERRIDE_TYPES = [
  PERMISSION_OVERRIDE_SSO_USER_FORGOT_PASSWORD,
];

export const FRESH_CLASS = 5;
export const DEFAULT_CLASS_COLOUR = '#722ED1';
export const DEFAULT_CLASS_YEARS = ['-1', '0', '1', '2', '3', '4', '5', '6'];
export const DEFAULT_CLASS_YEARS_US = ['-1', '0', '1', '2', '3', '4', '5'];
export const DEFAULT_CLASS_CURRICULUMS = CURRICULUM_OPTIONS_EDIT;
export const DEFAULT_CLASS_CURRICULUMS_US = CURRICULUM_OPTIONS_EDIT_US;

/** English Content Area */
export const ENGLISH_CONTENT_AREA_PHONOLOGICAL_AWARENESS =
  'phonological-awareness';
export const ENGLISH_CONTENT_AREA_PHONICS = 'phonics';
export const ENGLISH_CONTENT_AREA_ORAL_LANGUAGE = 'oral-language';
export const ENGLISH_CONTENT_AREA_LANGUAGE_COMPREHENSION =
  'language-comprehension';
export const ENGLISH_CONTENT_AREA_CREATING_TEXTS = 'creating-texts';

/**
 * @typedef {typeof ENGLISH_CONTENT_AREA_PHONOLOGICAL_AWARENESS
 * | typeof ENGLISH_CONTENT_AREA_PHONICS
 * | typeof ENGLISH_CONTENT_AREA_ORAL_LANGUAGE
 * | typeof ENGLISH_CONTENT_AREA_LANGUAGE_COMPREHENSION
 * | typeof ENGLISH_CONTENT_AREA_CREATING_TEXTS
 * } EnglishContentArea
 */

export const ENGLISH_CONTENT_AREAS = [
  ENGLISH_CONTENT_AREA_PHONOLOGICAL_AWARENESS,
  ENGLISH_CONTENT_AREA_PHONICS,
  ENGLISH_CONTENT_AREA_ORAL_LANGUAGE,
  ENGLISH_CONTENT_AREA_LANGUAGE_COMPREHENSION,
  ENGLISH_CONTENT_AREA_CREATING_TEXTS,
];

/** Vocabulary notes */
export const MATHS_VOCAB_NOTE =
  'Quality instruction encourages students to move beyond the use of everyday language to more precise, formal mathematical vocabulary. It is vital that students connect new vocabulary to their conceptual understanding of a topic. Teachers facilitate this by providing instruction on words, at a time when students will have a need to use them. (Hattie, Fisher & Frey, 2017). In the Inquisitive Maths lessons, mathematical vocabulary that requires teacher instruction and guidance on how to teach it is found in the Suggested Teacher Talk section of the Teaching and Learning Sequence.';
export const ENGLISH_VOCAB_NOTE =
  'Quality instruction includes teaching vocabulary within the context of the lesson. See teaching notes for vocabulary definitions and teaching advice.';

/**
 * @typedef {typeof LESSON
 * | typeof ASSESSMENT
 * | typeof LESSON_ACTIVITIES
 * | typeof ASSESSMENT_ACTIVITIES
 * } StimulusResourceAttachmentType
 */

/**
 * @type {Array<StimulusResourceAttachmentType>}
 */
export const STIMULUS_RESOURCE_ATTACHMENT_TYPE = [
  ASSESSMENT,
  LESSON,
  ASSESSMENT_ACTIVITIES,
  LESSON_ACTIVITIES,
];
export const STIMULUS_RESOURCES_SEARCH_PAGE_SIZE = 12;

/**
 * @typedef {typeof ASSESSMENT_ACTIVITIES
 * | typeof LESSON_ACTIVITIES
 * } StimulusResourceActivitiesAttachmentType
 */

/**
 * @type {Array<StimulusResourceActivitiesAttachmentType>}
 */

export const STIMULUS_RESOURCE_ACTIVITIES_ATTACHMENT_TYPES = [
  ASSESSMENT_ACTIVITIES,
  LESSON_ACTIVITIES,
];

// Marketing Numbers AU
export const NUMBER_OF_SCHOOLS_AU = '5,000';
export const NUMBER_OF_TEACHERS = '50,000';
export const NUMBER_HOURS_SAVED_AU = '80';
export const SCHOOLS_TRUSTED_BY_PERCENTAGE_AU = '65%';

// DocRaptor API
export const DOC_RAPTOR_API_URL = 'https://api.docraptor.com/docs';

// Open AI models
/**
 * @typedef { typeof GPT_4_OMNI
 * | typeof GPT_4_OMNI_MINI
 * | typeof GPT_O1
 * } GPTModelType
 */

export const GPT_4_OMNI = 'gpt-4o';
export const GPT_4_OMNI_MINI = 'gpt-4o-mini';
// update to o1 when it becomes available
export const GPT_O1 = 'o1-preview';
export const GPT_MODELS = [GPT_4_OMNI, GPT_4_OMNI_MINI, GPT_O1];

/**
 * @typedef { typeof AI_DICTIONARY_YEAR_RANGE_K_2
 * | typeof AI_DICTIONARY_YEAR_RANGE_3_6
 * } AIDictionaryYearRange
 */
export const AI_DICTIONARY_YEAR_RANGE_K_2 = 'K-2';
export const AI_DICTIONARY_YEAR_RANGE_3_6 = '3-6';
export const AI_DICTIONARY_YEAR_RANGE = [
  AI_DICTIONARY_YEAR_RANGE_K_2,
  AI_DICTIONARY_YEAR_RANGE_3_6,
];

export const AI_CHAT_SCENARIO_DICTIONARY = 'dictionary';
export const AI_CHAT_SCENARIO_ASSISTANT = 'assistant';
export const AI_CHAT_SCENARIO_DICTIONARY_TRANSLATION = 'dictionary-translation';

export const NANO_ID_LENGTH = 12;
export const NANO_ID_ALPHABET =
  '0123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';

export const EXPORT_FORMAT_PDF = 'pdf';
export const EXPORT_FORMAT_DOCX = 'docx';

export const DOCX_PDF_CONVERSION_DOC_TYPE_UNIT_PROGRAM = 'unit-program';
export const DOCX_PDF_CONVERSION_DOC_TYPE_SCOPE_AND_SEQUENCE =
  'scope-and-sequence';
export const DOCX_PDF_CONVERSION_DOC_TYPE_ALL_TERM_SUMMARY = 'all-term-summary';
export const DOCX_PDF_CONVERSION_DOC_TYPE_TERM_SUMMARY = 'term-summary';

export const LANGUAGE_CODE_EN_US = 'en-US';
export const LANGUAGE_CODE_ES_US = 'es-US';
export const LANGUAGE_CODE_ZH_CN = 'zh-CN';
export const LANGUAGE_CODE_HI_IN = 'hi-IN';
export const LANGUAGE_CODE_HT_HT = 'ht-HT';
export const LANGUAGE_CODE_AR_EG = 'ar-EG';
export const LANGUAGE_CODE_PA_IN = 'pa-IN';
export const LANGUAGE_CODE_AR_SA = 'ar-SA';
export const LANGUAGE_CODE_BN_IN = 'bn-IN';
export const LANGUAGE_CODE_FR_FR = 'fr-FR';
export const LANGUAGE_CODE_IT_IT = 'it-IT';
export const LANGUAGE_CODE_PT_PT = 'pt-PT';
export const LANGUAGE_CODE_RU_RU = 'ru-RU';
export const LANGUAGE_CODE_SR_RS = 'sr-RS';
export const LANGUAGE_CODE_UR_PK = 'ur-PK';
export const LANGUAGE_CODE_EN_AU = 'en-AU';

export const LANGUAGE_CODES = [
  LANGUAGE_CODE_EN_US,
  LANGUAGE_CODE_ES_US,
  LANGUAGE_CODE_ZH_CN,
  LANGUAGE_CODE_HI_IN,
  LANGUAGE_CODE_HT_HT,
  LANGUAGE_CODE_AR_EG,
  LANGUAGE_CODE_PA_IN,
  LANGUAGE_CODE_AR_SA,
  LANGUAGE_CODE_BN_IN,
  LANGUAGE_CODE_FR_FR,
  LANGUAGE_CODE_IT_IT,
  LANGUAGE_CODE_PT_PT,
  LANGUAGE_CODE_RU_RU,
  LANGUAGE_CODE_SR_RS,
  LANGUAGE_CODE_UR_PK,
  LANGUAGE_CODE_EN_AU,
];
/**
 * @typedef { typeof LANGUAGE_CODE_ES_US
 * | typeof LANGUAGE_CODE_EN_US
 * | typeof LANGUAGE_CODE_ZH_CN
 * | typeof LANGUAGE_CODE_HI_IN
 * | typeof LANGUAGE_CODE_HT_HT
 * | typeof LANGUAGE_CODE_AR_EG
 * | typeof LANGUAGE_CODE_PA_IN
 * | typeof LANGUAGE_CODE_AR_SA
 * | typeof LANGUAGE_CODE_BN_IN
 * | typeof LANGUAGE_CODE_FR_FR
 * | typeof LANGUAGE_CODE_IT_IT
 * | typeof LANGUAGE_CODE_PT_PT
 * | typeof LANGUAGE_CODE_RU_RU
 * | typeof LANGUAGE_CODE_SR_RS
 * | typeof LANGUAGE_CODE_UR_PK
 * | typeof LANGUAGE_CODE_EN_AU
 * } LanguageCodes
 */

export const BULK_ACTION_ADD_STUDENTS = 'add-students';
/**
 * @typedef { typeof BULK_ACTION_ADD_STUDENTS
 * } BulkActionName
 */
export const BULK_ACTION_NAMES = [BULK_ACTION_ADD_STUDENTS];

// websocket constants
export const WS_EVENT_PLANNER_UPDATE = 'planner-update';
export const WS_EVENT_PLANNER_DELETE = 'planner-delete';
export const WS_EVENT_STUDENT_ANSWER_UPDATE = 'student-answer-update';
export const WS_EVENT_CLASS_TO_DO_STUDENT_UPDATE = 'class-to-do-student-update';
export const WS_PROTOCOL_GRAPHQL = 'graphql-transport-ws';

// test constants
export const BAD_USER_INPUT = 'BAD_USER_INPUT';

// Launch Darkly Feature Flags
export const FEATURE_FLAG_TEACHER_ASSISTANT_BETA = 'teacher-assistant-beta';
export const FEATURE_FLAG_ACTIVITY_PRESENT_STUDENTS =
  'activity-present-students';
export const FEATURE_FLAG_MULTI_LANGUAGE = 'multi-language';

/**
 * @typedef { typeof FEATURE_FLAG_TEACHER_ASSISTANT_BETA
 * | typeof FEATURE_FLAG_ACTIVITY_PRESENT_STUDENTS
 * | typeof FEATURE_FLAG_MULTI_LANGUAGE
 * } FeatureFlag
 */

/** @type {Array<FeatureFlag>} */
export const FEATURE_FLAGS = [
  FEATURE_FLAG_TEACHER_ASSISTANT_BETA,
  FEATURE_FLAG_ACTIVITY_PRESENT_STUDENTS,
  FEATURE_FLAG_MULTI_LANGUAGE,
];

// Scheduled Jobs
export const SCHEDULED_JOB_CLASS_METRIC_STUDENT_COUNTS =
  'class-metric-student-counts';
export const SCHEDULED_JOB_CLASS_METRIC_STUDENT_RESPONSES =
  'class-metric-student-responses';

/**
 * @typedef {typeof SCHEDULED_JOB_CLASS_METRIC_STUDENT_COUNTS
 * | typeof SCHEDULED_JOB_CLASS_METRIC_STUDENT_RESPONSES
 * } ScheduleJobName
 */

/** @type {Array<ScheduleJobName>} */
export const SCHEDULED_JOB_NAMES = [
  SCHEDULED_JOB_CLASS_METRIC_STUDENT_COUNTS,
  SCHEDULED_JOB_CLASS_METRIC_STUDENT_RESPONSES,
];
